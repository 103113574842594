import React from "react";
import { termsItems } from "../../../static/constants";
import './Terms.css'
const Terms = () => {
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-terms-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10 py-16 px-2 md:px-4">
        <span className="text-white text-[36px] font-semibold text-center">
          Terms and Conditions for <span className="gradient-text">MoneyWin</span>
        </span>
        <div className="w-full min-h-[389px] max-w-[900px] bg-privacy p-8 md:p-12 text-white md:text-[18px] text-[16px] leading-8 text-justify">
          { termsItems.map((item, index) => (
            <div key={index} className="text-justify flex flex-col my-2">
              <span className="font-semibold text-[#FCDB9C]">{item.title}</span>
              <span className="text-[13px] mx-4">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terms;

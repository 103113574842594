import { Card, Table, DatePicker, Select, message as floatingMessage  } from "antd";
import React from "react";
import { useEffect , useState} from "react";
import { getBettingHistory, getUserInfo } from "../../../services/moneywin/moneywin-api";
import moment from 'moment';
import './userpanel.css';
import { formatDate, formatAsMoney } from '../../../services/util/utilities';
import { useSelector } from 'react-redux';

const BettingHistory = () => {
    const [bettingHistory, setBettingHistory] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [provider, setProvider] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
      const fetchData = async () => {
        const response = await getUserInfo(token);
        if (response.user_id) {
          const bettingData = await getBettingHistory(response.user_id);
          if (bettingData && bettingData.length > 0) {
            setBettingHistory(bettingData);
          }
        }else if(response.timed_out || response.request_failed){
          floatingMessage.error({ content: "Unable to retrieve betting history. Please check your internet connection or try again later." , key: 'custom_message' });
        }
        setLoading(false);
      };
    
      fetchData();
    
    }, [dateRange, provider, token]);

    const handleDateRangeChange = (dates) => {
      setLoading(true);
      if (dates && dates.length > 0) {
        setDateRange(dates); // Set the dates array directly
      } else {
        setDateRange([]);
      }
    };
    
    
    const filteredBettingHistory = bettingHistory
    .filter((item) => {
      if (dateRange.length === 0) {
        if (provider && provider !== 'ALL GAME PROVIDERS') {
          return item.provider === provider;
        }
        return true;
      }
      const itemDate = moment(item.date, "YYYY-MM-DD");

      const startDate = dateRange[0].format("YYYY-MM-DD");
      const endDate = dateRange[1].format("YYYY-MM-DD");

      const isDateInRange = itemDate.isBetween(startDate, endDate, 'day', '[]');

      if (provider && provider !== 'ALL GAME PROVIDERS') {
        return isDateInRange && item.provider === provider;
      }
      

      return isDateInRange;
    });

    const columns = [
        {
          title: "Provider",
          dataIndex: "provider",
          key: "provider",
        },
        {
          title: "Game",
          dataIndex: "game_id",
          key: "game_id",
        },
        {
          title: "Date & Time",
          dataIndex: "dateTime",
          align: 'center',
          width: 250,
          key: "dateTime", 
          render: (text, record) => {
            const combinedDateTime = `${record.date} ${record.time}`;
            return <span>{formatDate(combinedDateTime)}</span>;
          },
        },
        {
          title: "Bet Amount",
          dataIndex: "bet_amount",
          key: "bet_amount",
          render: (text) => (<span>{formatAsMoney(text)}</span>)
        },
        {
          title: "Payout",
          dataIndex: "payout",
          key: "payout",
          render: (text, record) => {
            const numericValue = parseFloat(text.replace(/[^0-9.-]/g, ''));
            const isNegative = numericValue < record.bet_amount;
            return (
              <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                {formatAsMoney(text)}
              </span>
            );
          }
        },
        {
          title: "Running Balance",
          dataIndex: "balance",
          key: "balance",
          render: (text) => (<span>{formatAsMoney(text)}</span>)
        },
        {
          title: "Remarks",
          dataIndex: "remarks",
          key: "remarks",
          render: (remarks) => (remarks !== null ? remarks : "-"),
        },
    ];
    return (
      <div className="bg-primary h-full w-full p-3 lg:p-5">
        <Card
          title={
          <div 
            className="flex flex-col lg:flex-row lg:justify-between w-full lg:items-center"
          >
            <div 
              className="flex flex-col lg:flex-row gap-2 lg:items-center"
            >
              <DatePicker.RangePicker
                className="w-full lg:min-w-[200px] h-[40px] my-5"
                format="YYYY-MM-DD"
                placeholder={['Date From', 'Date To']}
                onChange={handleDateRangeChange}
              />
              <Select
                size="large"
                defaultValue="ALL GAME PROVIDERS"
                className="w-full md:w-[250px] lg:py-0 pb-3 items-center justify-center text-center"
                options={[
                  { value: 'ALL GAME PROVIDERS', label: 'ALL GAME PROVIDERS' },
                  { value: 'JILI', label: 'JILI' },
                  // { value: 'CHERRY GAMING', label: 'CHERRY GAMING' },
                  // { value: 'EVO', label: 'EVO' },
                  { value: 'SA GAMING', label: 'SA GAMING' },
                  { value: 'SIMPLE PLAY', label: 'SIMPLE PLAY' },
                ]}
                onChange={(selectedValue) => {
                  setLoading(true);
                  setProvider(selectedValue);
                }}
              />
            </div>
          </div> 
          }
        >
          <Table
            scroll={{
              x: 1400,
              y: '100%',
            }}
            bordered
            dataSource={filteredBettingHistory}
            columns={columns}
            loading={loading}
            rowKey="key"
            className={`min-h-[230px]`}
          />
        </Card>
      </div>
    );
}

export default BettingHistory;

import React from "react";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-terms-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10 py-16 px-2 md:px-4">
        <span className="text-white text-[36px] font-semibold">
          Privacy Policy
        </span>
        <div className="w-full min-h-[389px] max-w-[1152px] bg-privacy p-8 md:p-12 text-white md:text-[18px] text-[16px] leading-8 text-justify">
          {/* NEW - xlx */}
          Protecting our players' privacy and ensuring the security of their data are of utmost importance to us at MoneyWin. We maintain a strict commitment to safeguarding personal information and will never disclose it to third parties. However, please note that we retain the right to share necessary player information with basic payment service providers and financial institutions through our website, exclusively for the purpose of facilitating payment transactions.
          <br></br>
          <br></br>
          To ensure the confidentiality and integrity of the information provided by our players, we employ a robust safety gateway utilizing Data Encryption Standard SSL 128 bit for secure data transmission. Additionally, all player data is stored in a confidential environment that is highly resistant to external intrusion. We enforce stringent restrictions and comprehensive monitoring measures on both internal and external data access.
          <br></br>
          <br></br>
          It is important to emphasize that our privacy policy is designed to prevent any disclosure of player information to third parties. Any unauthorized distribution, modification, copying, reissuing, use of MoneyWin's content, or replication of our server without the explicit written consent of MoneyWin is strictly prohibited.
          <br></br>
          <br></br>
          By implementing these measures and maintaining a steadfast commitment to privacy, we aim to provide our players with a secure and trustworthy gaming experience.
          {/* xlx */}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import { Form, Input, Modal, Button, message as floatingMessage } from "antd";
import React, { useState } from "react";
import "./userpanel.css";
import { getUserInfo, updatePassword } from "../../../services/moneywin/moneywin-api";
import { showTimedMessage, showTimedMessageWithAction } from "../../../services/util/utilities";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const Security = () => {
  const [form] = Form.useForm();
  const [message, messageHolder] = Modal.useModal();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const handleSumbitClick = async (old_password, new_password, confirm_password) => {
    
    try {
      setLoading(true);

      const response = await getUserInfo(token);
      if (response.user_id) {
          // Call the updatePassword function to update the password on the server
        const update = await updatePassword(response.user_id, old_password, new_password);

        if(update){
          if(update.success){
            showTimedMessage(message, "Success!", "Password updated succesfully.", "Close", 5, true);
            form.resetFields();
          }else if(update.old_password){
            showTimedMessage(message, "Failed", "The password entered was incorrect.", "Close", 5, false);
          }else if(update.count_error){
            showTimedMessage(message, "Failed", update.count_error, "Close", 5, false);
          }else{
            showTimedMessage(message, "Failed", "Failed to update password. Please try again later.", "Close", 5, false);
          }
        }
      }else if(response.timed_out || response.request_failed){
        floatingMessage.error({ content: "Network error. Please check your internet connection or try again later." , key: 'custom_message' });
      }else{
        showTimedMessageWithAction(message, "Session Expired", "Your account has been logged in to another device. To continue, please log in again.", "Login", 3, true, "warning" , 
          async () => {
            dispatch(logout());
          });  
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Hide loading spinner after form submission or error
    }
  }

  const onFinish = (values) => {
    const { oldPass, newPass, confirmPass } = values;
    handleSumbitClick(oldPass, newPass, confirmPass);
  };

  const onFinishFailed = () => {};

  return (
    <div className="w-full h-full bg-primary relative ">
      <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[5]"></div>
      <div className="flex flex-col md:px-10 gap-10 items-center justify-center pt-8 pb-10">
        <div className="flex h-full flex-col md:pb-18 pb-2 text-center items-center justify-center gap-5 z-[10]">
          {messageHolder}
          <span className="text-2xl text-white font-medium">
            Security Settings
          </span>
          {/* <span className="text-[#ABABAB]">
            Protect your account and data with our security settings. Adjust
            them to
            <br /> your preferences to stay safe online.
          </span> */}
        </div>
        <div className="w-full h-full bg-white grid px-12 py-16 grid-cols-1 md:grid-cols-2 relative max-w-[1199px] rounded-md security-card z-[10] gap-5">
          <div className="flex flex-col gap-5">
            <h1 className="text-[22px] text-gray-800">Update Password</h1>
            <span className="text-[16px] text-gray-800 leading-7">
              Ensure your account is using a long, random password to
              stay secure.
            </span>
          </div>
          <Form
            form={form}
            className="w-full"
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="oldPass"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Old Password
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter your current password.",
                },
              ]}
            >
              <Input.Password
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter your current password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="newPass"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  New Password
                </h1>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter your new password.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password must be at least 8 characters long.")
                    );
                  },
                }),
              ]}
              hasFeedback
              
            >
              <Input.Password
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Enter new password"
              />
            </Form.Item>

            <Form.Item
              name="confirmPass"
              label={
                <h1 className="font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]">
                  Confirm Password
                </h1>
              }
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please re-enter your new password for confirmation.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPass") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The passwords entered do not match."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                style={{
                  padding: "10px",
                  fontSize: "18px",
                  borderRadius: "3px",
                }}
                placeholder="Re-enter your new password"
              />
            </Form.Item>
            <div className="w-full flex justify-end ">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-[45px] cursor-pointer text-[18px] font-semibold bg-[#364671] text-white border-0 rounded-lg md:max-w-[188px] "
                loading={loading} // Use the loading prop to show/hide the spinner
              >
                Update
              </Button>
            </div>
          </Form>
        </div>
        {/* <div className="w-full h-full bg-white grid px-12 py-16 grid-cols-1 md:grid-cols-2 relative max-w-[1199px] rounded-md security-card z-[10] gap-5">
          <div className="flex flex-col gap-5">
            <h1 className="text-[22px] text-gray-800">
              Two Factor Authentication
            </h1>
            <span className="text-[16px] text-gray-800 leading-7">
              Add additional security to your account using <br />
              two factor authentication.
            </span>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-5">
              <h1 className="text-[22px] text-gray-800">
                You have not enabled two factor authentication.
              </h1>
              <span className="text-[16px] text-gray-800 leading-7">
                When two factor authentication is enabled, you will be prompted
                for a secure, random token during authentication. You may
                retrieve this token from your phone's Google Authenticator
                application.
              </span>
            </div>
            <div className="w-full flex justify-end ">
              <button
                type="primary"
                htmltype="submit"
                className="w-full h-[45px] text-[18px] font-semibold bg-[#364671] text-white border-0 rounded-lg md:max-w-[188px] "
              >
                Enable
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full h-full bg-white grid px-12 py-16 grid-cols-1 md:grid-cols-2 relative max-w-[1199px] rounded-md security-card z-[10] gap-5">
          <div className="flex flex-col gap-5">
            <h1 className="text-[22px] text-gray-800">Browser Sessions</h1>
            <span className="text-[16px] text-gray-800 leading-7">
              Manage and log out your active sessions on other browsers and
              devices.
            </span>
          </div>
          <div className="flex flex-col gap-8">
            <span className="text-[16px] text-gray-800 leading-7">
              If necessary, you may log out of all of your other browser
              sessions across all of your devices. Some of your recent sessions
              are listed below; however, this list may not be exhaustive. If you
              feel your account has been compromised, you should also update
              your password.
            </span>
            <div className="w-full flex justify-end ">
              <button
                type="primary"
                htmltype="submit"
                className="w-full h-[45px] text-[13px] md:text-[18px] font-semibold bg-[#364671] text-white border-0 rounded-lg md:max-w-[357px] "
              >
                Log out other browser sessions
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full h-full bg-white grid px-12 py-16 grid-cols-1 md:grid-cols-2 relative max-w-[1199px] rounded-md security-card z-[10] gap-5">
          <div className="flex flex-col gap-5">
            <h1 className="text-[22px] text-gray-800">Delete Account</h1>
            <span className="text-[16px] text-gray-800 leading-7">
              Permanently delete your account.
            </span>
          </div>
          <div className="flex flex-col gap-8">
            <span className="text-[16px] text-gray-800 leading-7">
              Once your account is deleted, all of its resources and data will
              be permanently deleted. Before deleting your account, please
              download any data or information that you wish to retain.
            </span>
            <div className="w-full flex justify-end ">
              <button
                type="primary"
                htmltype="submit"
                className="w-full h-[45px] text-[18px] font-semibold bg-[#D75252] text-white border-0 rounded-lg md:max-w-[233px] "
              >
                Delete Account
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Security;
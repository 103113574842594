import { Avatar, Form, Input, Spin, Popover, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect} from "react";
import profile from "../../../assets/resources/icons/empty-profile.png";
import { getProfileInfo, getUserInfo, updateProfileInfo, updateProfilePicture } from "../../../services/moneywin/moneywin-api";
import { useSelector } from 'react-redux';
import './userpanel.css';

const Profile = () => {
  const [profileVisible, setProfileVisible] = useState(false);
  const [profilePicture, setProfilePicture] = useState(profile);
  const [form] = Form.useForm();
  const [profileInfo, setProfileInfo] = useState({});
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newMiddleName, setNewMiddleName] = useState('');
  const [updateStatus, setUpdateStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitVisible, setSubmitVisible] = useState(false);
  const [refresh, setRefresh] = useState (false);
  const [pageLoading, setPageLoading] = useState(true);
  const [newProfile, setNewProfile] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      const profile = await getProfileInfo(token)
      if (profile) {
        setProfileInfo(profile);

        form.setFieldsValue({
          givenName: profile.first_name, 
          familyName: profile.last_name,
          middleName: profile.middle_name,
          email: profile.email,
        });

        setNewFirstName(profile.first_name)
        setNewLastName(profile.last_name)

        setPageLoading(false);

        if (profile.profile_image) {
          const dataURL = `data:image/webp;base64,${profile.profile_image}`;
          setProfilePicture(dataURL);
        } 

        
      }
      setProfileVisible(true); 
    }

    fetchData();

  }, [token, form, refresh]);

  const saveProfile = async () => {

    setProfileLoading(true);

    const response = await getUserInfo(token);

    if (response && response.user_id) {
      const updateProfile = await updateProfilePicture(response.user_id, profilePicture);
      
      if(updateProfile.success){
        message.success({ content: "Profile updated successfully!", key: 'custom_message' });
        setNewProfile(false);
      }else{
        message.error({ content: "Failed to upload new profile.", key: 'custom_message' });
      }
    }else if(response.timed_out || response.request_failed){
      message.error({ content: "Failed to upload new profile. Please check your internet connection or try again later." , key: 'custom_message' });
    }

    setProfileLoading(false);
  }

  const handleUpdateProfile = async (file) => {
    if (file) {
      try {
        // Convert the uploaded image to 500x500 PNG
        const resizedProfilePicture = await resizeImage(file);

        // Update the profile picture in the state
        setProfilePicture(resizedProfilePicture);
        setNewProfile(true);
        // Upload the resized image
        
      } catch (error) {
        console.error(error);
      }
    }
  };

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            const image = new Image();
            image.src = e.target.result;

            image.onload = async () => {
                const canvas = document.createElement('canvas');
                const targetSize = 250;
                let newWidth, newHeight, xOffset, yOffset;

                if (image.width > image.height) {
                    newHeight = targetSize;
                    newWidth = (image.width / image.height) * targetSize;
                    xOffset = (newWidth - targetSize) / 2;
                    yOffset = 0;
                } else {
                    newWidth = targetSize;
                    newHeight = (image.height / image.width) * targetSize;
                    xOffset = 0;
                    yOffset = (newHeight - targetSize) / 2;
                }

                canvas.width = targetSize;
                canvas.height = targetSize;

                const context = canvas.getContext('2d');
                context.drawImage(
                    image,
                    -xOffset,
                    -yOffset,
                    newWidth,
                    newHeight
                );

                // Convert the canvas to a PNG data URL
                const resizedProfilePicture = canvas.toDataURL('image/png');

                resolve(resizedProfilePicture);
            };
        };

        reader.onerror = (error) => reject(error);

        reader.readAsDataURL(file);
      });
  };


  const handleFormValuesChange = () => {
    setUpdateStatus(""); 
    setSubmitVisible(true);
  };

  const onFinish = async () => {
    setUpdateLoading(true);

    try{
      const response = await getUserInfo(token);

      if (response && response.user_id) {
        const update = await updateProfileInfo(response.user_id, newFirstName, newMiddleName, newLastName, profileInfo.email);
        if(update.success){
          setUpdateStatus("success");
          setSubmitVisible(false);
    
          if(refresh){
            setRefresh(false)
          }else{
            setRefresh(true);
          }
          
        }else if(update.error){
          setErrorMessage(update.error)
          setUpdateStatus("error");
        }else if(update.no_changes){
          setUpdateStatus(null);
        }
      }else if(response.timed_out || response.request_failed){
        setErrorMessage('Please check your internet connection or try again later.')
        setUpdateStatus("error");
      }
    } catch (error) {
      setErrorMessage('There was a problem updating profile information. Please try again later.')
      setUpdateStatus("error");
    }finally{
      setUpdateLoading(false);
    }
  };

  const onFinishFailed = () => {};

  return (
    <div className={`w-full h-full bg-primary relative`}>
      <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[5]"></div>
      <div className="flex flex-col gap-10 items-center justify-center pt-8 pb-10">
        {/* <div className="flex h-full flex-col md:pb-20 pb-2 text-center items-center justify-center gap-5 z-[10]"> */}
        <div className="flex h-full flex-col md:pb-20 pb-0 text-center items-center justify-center gap-5 z-[10]">
          <span className="text-2xl text-white font-medium">
            Profile Information
          </span>
          <span className="text-[#ABABAB]">
            Update your account's profile information
            <br /> and email address.
          </span>
        </div>
        {/* <div className="bg-transparent md:bg-white rounded-[30px] border-0 text-white max-w-[1022px] relative md:p-10 md:h-full w-[90%] z-[10]"> */}
        <div className="bg-transparent md:bg-white rounded-[30px] border-0 text-white max-w-[1022px] relative md:p-10 md:h-[400px]] w-[90%] z-[10]">
          <div className="flex flex-col items-center gap-4 w-full md:pt-16 h-full">
            <div className="w-full flex  items-center justify-center md:absolute md:top-[-75px]">
              <Popover
                content="Tap to change profile picture."
                trigger="hover"
              >
                <label htmlFor="pictureUpload" className="relative">
                  <input
                    className="profile"
                    id="pictureUpload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleUpdateProfile(e.target.files[0])}
                  />
                  <div className={`avatar-container`}>
                    <Avatar size={170} src={profilePicture} className={`${!profilePicture && 'border-2 border-solid border-[#EAB30F]'}`} />
                  </div>
                </label>
              </Popover>
            </div>
            <div className="flex w-full flex-col gap-4 items-center justify-center" >
              <span className={`md:text-black text-white text-xl font-semibold ${
                profileVisible ? "transition-opacity duration-300 opacity-100" : "opacity-0"
              }`}>
                {(!profileInfo.first_name && !profileInfo.last_name) ? 'User' : `${profileInfo.first_name || ''} ${profileInfo.last_name || ''}`}
              </span>
              {newProfile ? (
                <button
                  type="primary"
                  htmltype="submit"
                  onClick={saveProfile}
                  className={`w-full h-[60px] cursor-pointer text-[20px] font-medium ${
                    profileLoading ? 'bg-buttonYellow text-white' : 'bg-buttonYellow hover:bg-yellow-500 text-white'
                  } border-0 rounded-lg md:max-w-[305px] ${
                    profileLoading ? 'loading' : ''
                  }`}
                  disabled={profileLoading}
                >
                  {profileLoading ? (
                    <span>
                      <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2, marginRight: 10 }} spin />}
                      />
                      Uploading...
                    </span>
                  ) : (
                    'Save Profile Picture'
                  )}
                </button>
              ) : null}
              
            </div>
            {pageLoading ? 
            (
              <div className={`flex justify-center items-center min-h-[300px] h-[auto] fade-in show`}>
                <Spin size="large" /> {/* Use "large" size */}
              </div>  
            ) : (
              <Form
                form={form}
                className="w-full"
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onValuesChange={handleFormValuesChange}
              >
                <div className="flex flex-col md:flex-row py-10 md:gap-10 w-full">
                  <div className="w-full">
                    <Form.Item
                      className="login-input"
                      label={
                        <span className="label-style md:text-[#6D6D6D]">
                          First Name
                        </span>
                      }
                      name="givenName"
                    >
                      <Input
                        size="large"
                        onChange={(e) => {
                          setNewFirstName(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "60px",
                          padding: "10px",
                          fontSize: "20px",
                          borderRadius: "12px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="login-input"
                      label={
                        <span className="label-style md:text-[#6D6D6D]">
                          Last Name
                        </span>
                      }
                      name="familyName"
                    >
                      <Input
                        size="large"
                        onChange={(e) => {
                          setNewLastName(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "60px",
                          padding: "10px",
                          fontSize: "20px",
                          borderRadius: "12px",
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full">
                    <Form.Item
                      className="login-input "
                      label={
                        <span className="label-style md:text-[#6D6D6D]">
                          Middle Name
                        </span>
                      }
                      name="middleName"
                    >
                      <Input
                        size="large"
                        placeholder="Optional"
                        onChange={(e) => {
                          setNewMiddleName(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "60px",
                          padding: "10px",
                          fontSize: "20px",
                          borderRadius: "12px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="login-input "
                      label={
                        <span className="label-style md:text-[#6D6D6D]">
                          Email
                        </span>
                      }
                      name="email"
                    >
                      <Input
                        id="inputEmail"
                        size="large"
                        onChange={(e) => {
                          profileInfo.email = e.target.value;
                        }}
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "60px",
                          padding: "10px",
                          fontSize: "20px",
                          borderRadius: "12px",
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>

                {updateStatus ? (
                  <div className="w-full flex items-center justify-center mb-4">
                    {updateStatus === "success" && (
                      <div style={{ color: "#5cb85c" }}>Profile updated successfully!</div>
                    )}
                    {updateStatus === "error" && (
                      <div style={{ color: "red" }}>Failed to update profile. {errorMessage}</div>
                    )}
                  </div>
                ) : null}
                

                {submitVisible && (
                  <div className="w-full flex items-center justify-center">
                    <button
                      type="primary"
                      htmltype="submit"
                      className={`w-full h-[60px] cursor-pointer text-[20px] font-medium bg-[#364671] hover:bg-blue-400 text-white border-0 rounded-lg md:max-w-[285px] duration-150`}
                      disabled={updateLoading}
                    >
                      {updateLoading ? (
                        <span>
                          <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2, marginRight: 10 }} spin />}
                          />
                          Saving Changes...
                        </span>
                      ) : (
                        'Update'
                      )}
                    </button>
                  </div>
                )}
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
import { LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import { Form, Avatar, Divider, Drawer, Image, Spin, Layout, Menu, Typography, Modal, Button, Input, Select, QRCode, Tooltip, message, Popover, Skeleton} from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { login, logout, checkAuth } from "../../store/slices/auth-slice";
import { useMediaQuery } from 'react-responsive';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import {
  AddBalanceIcon,
  RechargeIcon,
} from "../../assets/icons/icons";
// import coinLogo from '../../assets/resources/images/coin-logo.png'
import coinLogo from '../../assets/resources/icons/mwIcons/mw-cash-small.webp';
import SignUp from '../Unauthenticated/Login/SignUp';
import MobileVerification from '../Unauthenticated/Login/MobileVerification';
import forgotpasswordicon from '../../assets/resources/icons/forgotpasswordicon.png'
// import casino from '../../assets/resources/icons/casino.png';
// import sports from '../../assets/resources/icons/sports.png';
import home from '../../assets/resources/icons/mwIcons/MONEYWIN ICONS-HOME.png';
// import casino1 from '../../assets/resources/icons/casino1.png';
// import sports1 from '../../assets/resources/icons/sports1.png';
import registernow from '../../assets/resources/icons/registernow.png';
import logoutIcon from '../../assets/resources/icons/mwIcons/logout.webp';
import recharge from '../../assets/resources/icons/mwIcons/recharge.webp';
import qrphLogo from "../../assets/resources/icons/qrph.png";
import email from "../../assets/resources/icons/email.png";
import footerImg from "../../assets/resources/images/footer-bg.png";
import mwLogo from "../../assets/resources/logos/mw-logo-signup.png";
import footerImgTwo from "../../assets/resources/images/footer-bg-two.png";
import { backOffice ,games, userPanel, mobileNavItems} from "../../static/constants";
import MONEYWINLOGO1 from "../../assets/resources/logos/MONEYWINLOGO-01.png";
import "./index.css";
import { Footer } from "antd/es/layout/layout";
import { getUserInfo, getUserBalance, logoutUser, getChipsInfo, getProfileInfo, authenticateUser, agentSettings} from "../../services/moneywin/moneywin-api";
import AllGames from "../Unauthenticated/Landing/games/AllGames";
import { createTopupQR, topupWebPayment, webPaymentChannels } from "../../services/paycools/paycools-api";
import { formatAsMoney, randomLogoutMessage, randomWelcomeMessage, showTimedMessageWithAction } from "../../services/util/utilities";
import Home from "../Unauthenticated/Landing/Home";
const { Sider, Header, Content } = Layout;

const LayoutWithRoute = ({ children }) => {
  
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [landingLoading, setLandingLoading] = useState(false);
  const [layoutLoading, setLayoutLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isSiderLoading, setIsSiderLoading] = useState(true);
  const [refreshWallet, setRefreshWallet] = useState(false);

  const [isRegModalVisible, setIsRegModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const [messageHolderVisible, setMessageHolderVisible] = useState(false);
 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [providerToShow, setProviderToShow] = useState(null);
  const [messageHolder, setMessageHolder] = Modal.useModal();
  
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const desktopBreakpoint = 1280;
  const isDesktop = window.innerWidth >= desktopBreakpoint;

  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentToken = useSelector((state) => state.auth.token);
  

  useEffect(() => {
    const checkDisclaimer = localStorage.getItem("isDisclaimerOpen");
    if(!checkDisclaimer){
      localStorage.setItem("isDisclaimerOpen", true);
      setIsDisclaimerOpen(true);
    } else {
      setIsDisclaimerOpen(false);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem('isDisclaimerOpen', false);
  };
  
  useEffect(() => {
    if ( isMobile ) {
      setTimeout(() => {
        setLandingLoading(true);
      }, 200); // Adjust the delay time as needed
    }
  }, );

  useEffect(() => {
    if(isMobile){
      setTimeout(() => {
        setIsLoaded(true);
      }, 2000); // Adjust the delay time as needed
    } else {
      setTimeout(() => {
        setIsLoaded(true);
      }, 0); // Adjust the delay time as needed
    }
  }, );
  
  useEffect(() => {
    if ( isMobile ) {
      setTimeout(() => {
        setLayoutLoading(true);
      }, 2200); // Adjust the delay time as needed
    } else {
      setTimeout(() => {
        setLayoutLoading(true);
      }, 0); // Adjust the delay time as needed
    }
  }, );

  const handleRegModalOpen = useCallback(() => {
    if(!loginLoading){
      setIsRegModalVisible(true);
    }
  }, [loginLoading]); 

  const navigateTo = useCallback((path) => {
    scrollToTop();
    location.pathname!== path && navigate(path);
  }, [navigate, location.pathname]); 

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refParam = queryParams.get("ref");

    if (refParam !== "null" && refParam !== null) {
      setReferralCode(refParam);
    }

    if((location.pathname !== "/" && location.pathname !== "/signup") || isAuthenticated){
      setReferralCode(null);
    }

  }, [location, isAuthenticated]);
  
  useEffect(() => {
    if(!isAuthenticated && location.pathname === "/"){
      if (referralCode) {
        if (isMobile) {
          navigateTo("/signup?ref=" + referralCode);
        } else {
          handleRegModalOpen();
        }
      }

    }
  }, [isAuthenticated ,location, referralCode]);

  useEffect(() => {
    if(isMobile && isRegModalVisible){
      handleRegModalClose();
      navigateTo("/signup");
    }
  }, [isMobile, isRegModalVisible]);

  const [balance, setBalance] = useState(null);
  const [bonusBalance, setBonusBalance] = useState("0.00");
  const [chipsBalance, setChipsBalance] = useState("0.00");
  const [promoBalance, setPromoBalance] = useState("0.00");
  const [moneywinCashBalance, setMoneywinCashBalance] = useState("0.00");

  useEffect(() => {
    if(isAuthenticated){
      if(balance){
        setIsSiderLoading(false);
      }else{
        setIsSiderLoading(true);
      }
    }

    if(!isAuthenticated){
      setIsSiderLoading(false);
    }
  }, [balance, isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {  
        const getBalance = await getUserBalance(currentUser.username, 'PHP')
        if(getBalance){
          const amount = getBalance?.amount || 0.00;
          const bonus = getBalance?.bonus || 0.00;
          setBalance(formatAsMoney(amount));
          setRefreshWallet(false);

          if(currentUser?.roleId === 5){
            setBonusBalance(formatAsMoney(bonus));
          }
        }
      }
    }
    fetchData();
  }, [isAuthenticated, currentUser, navigate, refreshWallet]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {  
        if(currentUser?.roleId > 1 && currentUser?.roleId < 5){

          const chipsInfo = await getChipsInfo(currentUser?.userId)
          if(chipsInfo && currentUser?.roleId === 2){

            setChipsBalance(formatAsMoney(chipsInfo.remaining_chips));
            setPromoBalance(formatAsMoney(chipsInfo.remaining_promo))
            setMoneywinCashBalance(formatAsMoney(chipsInfo.total_mw_cash))

          }else if(chipsInfo){

            setChipsBalance(formatAsMoney(chipsInfo.remaining_chips));
            setPromoBalance(formatAsMoney(chipsInfo.remaining_promo))

            if(currentUser.userId){
              const data = await agentSettings('get-top', currentUser.userId)
              if(data && data.success){
                if(currentUser?.roleId === 3){
                  setCashinVisible(data.agent_settings.can_super_agents_accept_cashin)
                  setCashoutVisible(data.agent_settings.can_super_agents_accept_cashout)

                  if(data.agent_settings.can_super_agents_accept_cashin){
                    setPaymentChannelVisible(true);
                  }

                }else{
                  setCashinVisible(data.agent_settings.can_agents_accept_cashin)
                  setCashoutVisible(data.agent_settings.can_agents_accept_cashout)

                  if(data.agent_settings.can_agents_accept_cashin){
                    setPaymentChannelVisible(true);
                  }
                }
              }
            }
          }
        }
      }
    }
    
    fetchData();
  }, [isAuthenticated, currentUser, navigate]);

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {  
        const profile = await getProfileInfo(currentToken)
        if (profile) {
          if (profile.profile_image) {
            const dataURL = `data:image/png;base64,${profile.profile_image}`;
            setProfilePicture(dataURL);
          }
          if(profile.first_name){
            setFname(profile.first_name);
            setLname(profile.last_name);
          } 
        }else{
          setProfilePicture(null);
        }
      }
    }
    
    fetchData();
    
  }, [currentToken, isAuthenticated]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserInfo(currentToken);
      
      if (user.error) {
        setBalance(null);
        dispatch(logout());
        setSelectedItem(null);
      } else {
        const authenticatedPayload = {
          isAuthenticated: true,
          currentUser: {
            username: user.username,
            userId: user.user_id,
            roleId: user.role_id
          },
        };

        dispatch(checkAuth(authenticatedPayload));
      }
    };
  
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, currentToken, dispatch, navigateTo, navigate]);

  useEffect(() => {

    const inGameVerification = async () => {
      if (isAuthenticated) {
        const user = await getUserInfo(currentToken);
        
        if (user.error) {
          showTimedMessageWithAction(messageHolder, "Session Expired", "Your account has been logged in to another device. To continue, please log in again.", "Login", 3, true, "warning" , 
          async () => {
            setBalance(null);
            dispatch(logout());
          });  
        } 
      }
    };

    if(location.pathname !== "/"){
      setSiderHome(false);
      
      // if (!isAuthenticated && location.pathname.startsWith("/backoffice")) {
      //   setBalance(null);
      //   dispatch(logout());
      //   return;
      // }

      // if(location.pathname.startsWith("/backoffice") && currentUser?.roleId > 4){
      //   setSelectedItem(null);
      //   navigateTo("/");
      //   return;
      // }

      // if(location.pathname === "/game" && currentUser?.roleId < 5){
      //   setSelectedItem("Dashboard");
      //   navigateTo("/backoffice");
      //   return;
      // }

      // if(location.pathname === "/reset-password" && isAuthenticated){
      //   if(currentUser?.roleId < 5){
      //     setSelectedItem("Dashboard");
      //     navigateTo("/backoffice");
      //   }else{
      //     setSelectedItem(null);
      //     navigateTo("/");
      //   }
      //   return;
      // }

      if(isAuthenticated && location.pathname === "/game"){
        const interval = setInterval(inGameVerification, 5000);
    
        return () => {
          clearInterval(interval);
        };
      }

      switch(location.pathname){
        case "/backoffice":
          setSelectedItem("Dashboard");
          break;
        case "/backoffice/teammanagement":
          setSelectedItem("Team Management");
          break;
        case "/backoffice/teambetting":
           setSelectedItem("Team Betting");
          break;
        case "/backoffice/commission-report":
          setSelectedItem("Commission Report");
          break;
        case "/backoffice/transaction-records":
          setSelectedItem("Transaction Records");
          break;
        case "/backoffice/cashin-request":
          setSelectedItem("Cash-In Request");
          break;
        case "/backoffice/cashout-request":
          setSelectedItem("Cash-Out Request");
          break;
        case "/userpanel/betting-history":
          setSelectedItem("Bet History");
          break;
        case "/userpanel/cashin":
          setSelectedItem("Cash-In");
          break;
        case "/userpanel/cashout":
          setSelectedItem("Cash-Out");
          break;
        case "/userpanel/profile":
          setSelectedItem("Profile");
          break;
        case "/userpanel/mw-cash":
          setSelectedItem("WinCash");
          break;
        case "/userpanel/payment-channel":
          setSelectedItem("Payment Channel");
          break;
        case "/userpanel/security":
          setSelectedItem("Security");
          break;
        case "/userpanel/settings":
          setSelectedItem("Settings");
          break;
        default:
          setSelectedItem(null);
      }
    }else{

      if(location.pathname === "/" && currentUser?.roleId < 5){
        navigateTo("/backoffice");
        return;
      }

      if (location.pathname === "/" && !['Slot', 'Casino', 'Fishing', 'Poker', 'Table'].includes(selectedCategory)) {
        setSelectedItem(null);
        setSiderHome(true);
      }
    }
  }, [currentToken, dispatch, isAuthenticated, currentUser,location.pathname, selectedCategory, navigateTo, messageHolder]);

  const setSelectedItem = (item) => {
    setSelectedCategory(item);
  }
  
  //Close drawer function
  const closeDrawer = () => {
    setVisible(false);
  };

  const [siderHome, setSiderHome] = useState(false);

  const menuClick = async (selectedItem) => {
    checkAuthentication();
    switch (selectedItem.key) {
      case "Dashboard":
        navigateTo("/backoffice");
        break;
      case "Team Management":
        navigateTo("/backoffice/teammanagement");
        break;
      case "Team Betting":
        navigateTo("/backoffice/teambetting");
        break;
      case "Dividends":
        console.log("Dividends selected");
        break;
      case "Commission Report":
        navigateTo("/backoffice/commission-report");
        break;
      case "Transaction Records":
        navigateTo("/backoffice/transaction-records");
        break;
      case "Cash-In Request":
        navigateTo("/backoffice/cashin-request");
        break;
      case "Cash-Out Request":
        navigateTo("/backoffice/cashout-request");
        break;
      case "Bet History":
        navigateTo("/userpanel/betting-history");
        break;
      case "Cash-In":
        navigateTo("/userpanel/cashin");
        break;
      case "Cash-Out":
        navigateTo("/userpanel/cashout");
        break;
      case "Profile":
        navigateTo("/userpanel/profile");
        break;
      case "WinCash":
        navigateTo("/userpanel/mw-cash");
        break;
      case "Payment Channel":
        navigateTo("/userpanel/payment-channel");
        break;
      case "Security":
        navigateTo("/userpanel/security");
        break;
      case "Settings":
        navigateTo("/userpanel/settings");
        break;
      case "Slot":
      case "Casino":
      case "Fishing":
      case "Poker":
      case "Table":
        setSelectedItem(selectedItem.key);
        scrollToTop();
        if(visible){
          closeDrawer();
          location.pathname !== "/" && navigateTo("/");
        }
        break;
      default:
        // Handle other cases
        break;
    }

  };

  const checkAuthentication = () => {
    const unauthItems = ['Slot', 'Casino', 'Fishing', 'Poker', 'Table'];
    setSiderHome(false);
    if (!isAuthenticated && !unauthItems.includes(selectedCategory)) {
      setBalance(null);
      dispatch(logout());
    }
  };

  const [loggingOut, setLoggingOut] = useState(false);

  const Logout = async () => {
    try {
      setLoggingOut(true);

      if(currentUser?.userId){
        await logoutUser(currentUser.userId);
      }

      if (currentUser?.roleId === 5) {
        message.info({ content: `${randomLogoutMessage("player")}`, key: 'custom_message' });
      }else{
        message.info({ content: `${randomLogoutMessage("user")}`, key: 'custom_message' }); // Display an error message
      }
      dispatch(logout());
      setBalance(null);
      
    } catch (error) {
      console.error(error);
    } finally{
      setLoggingOut(false);
    }
    handleNavBarClear();
  };

  const [navHome, setNavHome] = useState(false);
  const [navBackOffice, setNavBackOffice] = useState(false);
  const [navUserPanel, setNavUserPanel] = useState(false);
  // const [navProfile, setNavProfile] = useState(false);
  const [navContact, setNavContact] = useState(false);
  
  useEffect(() => {
    if(location.pathname.startsWith('/backoffice')){
      setNavBackOffice(true);
    } 
    if (location.pathname.startsWith('/userpanel')){
      setNavUserPanel(true);
    } 
    if (location.pathname === '/'){
      setNavHome(true);
      setNavContact(false);
      setNavUserPanel(false);
    } 
    if (location.pathname === '/contact'){
      setNavContact(true);
    }
  },[location.pathname]);

  const handleNavBarClear = () => {
    setProviderToShow(null);
    setSelectedItem(null);
    setNavHome(false);
    setSiderHome(false);
    setNavBackOffice(false);
    setNavUserPanel(false);
    setNavContact(false);
  };

  const popoverContent = (
    <div className="flex flex-col justify-center items-center gap-3 py-1 px-2">
      {isAuthenticated && <span className="text-[16px] w-[150px] text-center text-ellipsis overflow-hidden">{currentUser?.username}</span>}
      <button 
        className="w-[150px] gradient-button border-none rounded-md p-2 outlined-text cursor-pointer"
        onClick={() => {
          navigateTo("/userpanel/profile");
          setSelectedCategory('Profile'); 
          setSiderHome(false);
          setSelectedItem(null);
          selectedProvider(null);
          setNavHome(false);
          setNavBackOffice(false);
          setNavUserPanel(true);
          setNavContact(false);
        }}
      >
        Profile
      </button>
      <button 
        className="w-[150px] gradient-button border-none rounded-md p-2 outlined-text cursor-pointer"
        onClick={Logout}
        disabled={loggingOut}
      >
        {loggingOut ? <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} /> : 'Logout'}
      </button>
    </div>
  ) 
  const AuthItems = () => {
    return (
      <div className="md:flex flex-row hidden gap-16 justify-between items-center w-full py-6 text-white">
        <div 
          className="flex justify-center items-center z-[1200]" 
          onClick={() => {
            if (currentUser?.roleId < 5) {
              location.pathname !== "/backoffice" && navigateTo("/backoffice");
              setProviderToShow(null);
              setSelectedItem(null);
              setNavHome(true);
              setSiderHome(false);
              setNavBackOffice(true);
              setNavUserPanel(false);
              setNavContact(false);
            } else {
              location.pathname !== "/" && navigateTo("/");
              setProviderToShow(null);
              setSelectedItem(null);
              setNavHome(true);
              setSiderHome(true);
              setNavBackOffice(false);
              setNavUserPanel(false);
              setNavContact(false);
            }
          }}
        >
          <Image
            style={{
              // width: "41px",
              width: "80px",
              objectFit: "cover",
            }}
            className={`cursor-pointer ${isDesktop ? 'flex' : !collapsed ? 'hidden' : 'flex'}`}
            // className={`cursor-pointer ${!collapsed ? 'hidden' : 'block'}`}
            // className={`cursor-pointer block`}
            preview={false}
            // src={coinlogo}
            src={MONEYWINLOGO1}
           
          />
        </div>
        

        { isSiderLoading ? (
          <div className={`md:flex flex-row hidden gap-6 justify-end h-full mt-8 items-center w-full py-6 ${collapsed ? '' : 'fixed right-0 px-7'} text-white`}>
            <Skeleton.Button active={true} size={44} className="lighter-skeleton" />
            <Skeleton.Button active={true} size={44} className="lighter-skeleton" />
            <Skeleton.Button active={true} size={44} className="lighter-skeleton" />
            <Skeleton.Button active={true} size={44} className="lighter-skeleton" />
            <Skeleton.Avatar active={true} size={44} shape={'circle'} className="lighter-skeleton" />
          </div>
        ) : (
          <div className={`md:flex flex-row hidden gap-14 justify-end items-center w-full py-6 ${collapsed ? '' : 'fixed right-0 px-7'} text-white`}>
            {currentUser && (currentUser?.roleId === 5) && (
              <span
                className="cursor-pointer font-regular text-[16px] hover:text-yellow-500"
                onClick={() => {
                  const pathToNavigate = currentUser?.roleId < 5 && location.pathname === "/backoffice" ? '/backoffice' : '/';
                  navigateTo(pathToNavigate);
                  setProviderToShow(null);
                  setSelectedItem(null);
                  setNavHome(true);
                  setSiderHome(true);
                  setNavBackOffice(false);
                  setNavUserPanel(false);
                  setNavContact(false);
                }}
                style={{ color: navHome && '#EAB30F'}}
              >
                Home
              </span>
            )}
            {currentUser && (currentUser?.roleId < 5) && (
              <span 
                className="cursor-pointer font-regular text-[16px] hover:text-yellow-500 min-w-[90px]"
                  onClick={() => {
                    navigateTo("/backoffice");
                    setNavBackOffice(true);
                    setNavHome(false);
                    setNavUserPanel(false);
                    setNavContact(false);
                }}
                style={{ color: navBackOffice && '#EAB30F'}}
              >
                Back Office
              </span>
            )}

            <span
              className="cursor-pointer font-regular text-[16px] hover:text-yellow-500 min-w-[90px]"
              onClick={() => {
                scrollToTop();
                const path = currentUser?.roleId === 5 ? "/userpanel/betting-history" : "/userpanel/profile";
                navigateTo(path);
                setNavBackOffice(false);
                setNavHome(false);
                setNavUserPanel(true);
                setNavContact(false);
              }}
              style={{ color: navUserPanel && '#EAB30F'}}
            >
              User Panel
            </span>

            <span 
              className={`hover:text-yellow-500 flex-none cursor-pointer font-regular text-[16px] ${location.pathname === "/contact" ? '' : null}`}
              onClick={() => {
                navigateTo("/contact");
                setNavBackOffice(false);
                setNavHome(false);
                setNavUserPanel(false);
                setNavContact(true);
              }}
              style={{ color: navContact && '#EAB30F'}}
            >
              Contact Us
            </span>
            
            <Popover content={popoverContent} trigger="click" placement="topRight">
              <Avatar
                className={`${profilePicture ? 'bg-[#EAB30F]' : 'bg-primary border border-solid border-[#EAB30F] text-[#EAB30F]'} text-black block`}
                size={42}
                src={profilePicture ? profilePicture : undefined}
              >
                <span className="text-[#EAB30F]">{fname?.slice(1) && fname?.charAt(0).toUpperCase()}{lname?.slice(1) && lname?.charAt(0).toUpperCase()}</span>
              </Avatar>
            </Popover>
          </div>
        )}
      </div>
    );
  };

  const [prevUsername, setPrevUsername] = useState("");
  const [prevPassword, setPrevPassword] = useState("");

  const UnAuthItems = () => {
    const [username, setUsername] = useState(prevUsername);
    const [password, setPassword] = useState(prevPassword);
    
    const handleLogin = async () => {
      setPrevUsername(username);
      setPrevPassword(password);

      if(isRegModalVisible){
        setIsRegModalVisible(false);
      }
      if(!username && !password){
        return;
      }

      setLoginLoading(true);

      message.loading({ content: 'Logging in...', key: 'custom_message' }); // Display a loading message
      
      try {
        const getUserInfo = await authenticateUser(username, password, "PHP");

        if (getUserInfo.error === 0) {

          
          if (getUserInfo?.role_id === 5) {
            message.success({ content: `${randomWelcomeMessage("player")}`, key: 'custom_message' });
          }else{
            message.success({ content: `${randomWelcomeMessage("user")}`, key: 'custom_message' }); // Display an error message
          }

          const loginPayload = {
            isAuthenticated: true,
            token: getUserInfo.token,
            currentUser: {
              username: getUserInfo.username,
              userId: getUserInfo.user_id,
              roleId: getUserInfo.role_id
            },
          };
        
          dispatch(login(loginPayload));

          if (currentUser?.roleId < 5) {
            location.pathname !== "/backoffice" && navigateTo("/backoffice");
          } else {
            location.pathname !== "/" && navigateTo("/");
          }

        } else if (getUserInfo.error === 1) {
          message.error({ content: `${getUserInfo.message}`, key: 'custom_message' }); // Display an error message
        }else if(getUserInfo.timed_out || getUserInfo.request_failed){
          message.error({ content: "Please check your internet connection or try again later." , key: 'custom_message' });
        }else{
          message.error({ content: "An error occurred. Please try again later.", key: 'custom_message' }); // Display an error message
        }
      } catch (error) {
          message.error({ content: "An error occurred. Please try again later.", key: 'custom_message' }); // Display an error message
      }

      setLoginLoading(false);
    };

    return (
      <div className={`hidden md:flex gap-3 ${isDesktop ? 'justify-between' : 'justify-end'} w-full items-center z-10 text-white`}>
        <div
          className={`text-[20px] font-medium ${isDesktop ? 'flex' : 'hidden' } items-center cursor-pointer gap-2`}
          onClick={() => {
            if (currentUser?.roleId < 5) {
              navigateTo("/backoffice");
            } else {
              navigateTo("/");
            }
          }}
        >
          <Image
            style={{
              // width: "41px",
              width: "80px",
              objectFit: "cover",
            }}
            preview={false}
            // src={coinlogo}
            src={MONEYWINLOGO1}
          />
          {/* <span className="text-white">
            Money<span className="text-textYellow italic">Win</span>
          </span> */}
        </div>
        {location.pathname !== "/signup" ? 
          
          <Form
            ref={null}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={null}
            onFinishFailed={null}
            autoComplete="off"
          >
            <div className="flex gap-3 items-center">
              <Input 
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                name="userName"
                placeholder="Username"
                style={{ width: 150, height: 40 }}
                autoComplete="username" 
              />
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                name="passWord"
                placeholder="Password"
                style={{ width: 150, height: 40 }}
                autoComplete="current-password" 
              />
              <Tooltip title="Forgot Password?">
                <Image src={forgotpasswordicon} preview={false} className="cursor-pointer" onClick={() =>{ if(location.pathname !== "/recover"){ navigateTo('/recover') }}} />
              </Tooltip>
              
              <button
                className="bg-transparent border border-[#FCDB9C] text-[#FCDB9C] w-[100px] h-[40px] font-semibold rounded-lg flex items-center justify-center cursor-pointer hover:scale-[1.10] transition-all ease-in duration-150"
                disabled={loginLoading}
                onClick={() => {
                  handleLogin(); setNavHome(true); setNavBackOffice(true);
                }}
              >
              {loginLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                  />
                  ) : (
                    "Sign In"
                  )}
              </button>
              <button
              className="bg-[#FFCE29] shadowed glowing bg-gradient-to-t from-[#92680C] to-[#FCDB9C] text-white border-0 w-[100px] h-[40px] font-semibold rounded-lg flex items-center justify-center cursor-pointer hover:scale-[1.10] transition-all ease-in duration-150"
              onClick={() => {
                handleRegModalOpen()
              }}
              >
                <span className="outlined-text text-[14px]">Register</span>
              </button>
           </div>
          </Form>
        : <button
            className="bg-transparent border border-[#FCDB9C] text-[#FCDB9C] w-[100px] h-[40px] font-semibold rounded-lg flex items-center justify-center cursor-pointer hover:scale-[1.10] transition-all ease-in duration-150"
            disabled={loginLoading}
            onClick={() => {
              navigateTo("/");
            }}
          >
            Sign In
          </button>}
      </div>
    );
  };

  const handleRechargeClick = () => {
    navigateTo("/userpanel/cashin");
    setNavHome(false);
    setNavBackOffice(false);
    setNavUserPanel(true);
    setNavContact(false);
  }

  const toggleRegModal = (toggle) => {
    if(toggle){
      handleRegModalOpen();
    }else{
      handleRegModalClose();
    }
  };

  const navigateToSignUp = () => {
    <UnAuthItems />

    if (isAuthenticated) {
      setBalance(null);
      dispatch(logout());
    }

    isMobile ? navigateTo("/login") : location.path !== "/" && navigateTo("/");
  };

  const handleRegModalClose = () => {
    setIsRegModalVisible(false);
  }

  const [isVericationModalVisible, setVerificationModalVisible] = useState(false);

  const toggleVerificationModal = (toggle) => {
    if(toggle){
      handleVerificationModalOpen();
    }else{
      handleVerificationModalClose();
    }
  };

  const handleVerificationModalClose = () => {
    setVerificationModalVisible(false);
  }

  const handleVerificationModalOpen = () => {
    setVerificationModalVisible(true);
  }
  
  const [topupModalVisible, setTopupModalVisible] = useState(false);
  const [topupLoading, setTopupLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [paymentChannelError, setPaymentChannelError] = useState('');
  const [accountName, setAccountName] = useState('');
  const [amount, setAmount] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [paymentLink, setPaymentLink] = useState("");
  const [qrCodeData, setQrCodeData] = useState(null);
  const [linkModalVisible, setLinkModalVisible] = useState(false);

  const fetchWebPaymentChannels = async () => {
    try {
      const response = await webPaymentChannels(); 
      if (response.code === 1000 && response.data) {
        const formattedOptions = response.data.map((option) => ({
          value: option.channelCode,
          label: option.channelName,
        }));

         formattedOptions.push({
            value: "qr_ph",
            label: "Pay via QR",
          });

        setPaymentOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleTopUpClick = () => {
    setTopupModalVisible(true);
    fetchWebPaymentChannels();
  }

  const handleTopupConfirm = async () => {
    setTopupLoading(true);

    if (paymentChannel === null) {
      setPaymentChannelError('Please select a payment channel');
      setTopupLoading(false);
      return;
    } else {
      setPaymentChannelError('');
    }

    if (!accountName && paymentChannel !== "qr_ph") {
      setAccountNameError('Please enter account name');
      setTopupLoading(false);
      return;
    } else {
      setAccountNameError('');
    }

    if (!accountNumber && paymentChannel !== "qr_ph") {
      setAccountNumberError('Please enter account number');
      setTopupLoading(false);
      return;
    } else {
      setAccountNumberError('');
    }

    if (!amount) {
      setAmountError('Please enter valid amount');
      setTopupLoading(false);
      return;
    } else if (isNaN(amount)) {
      setAmountError('Invalid Amount');
      setTopupLoading(false);
      return;
    } else {
      setAmountError('');
    }

    const userInfo = await getUserInfo(localStorage.getItem("token"));
    if (userInfo && userInfo.user_id) {
      try {

        if(paymentChannel === "qr_ph"){
          const qrCode = await createTopupQR(userInfo.user_id, "MoneyWin Agent", amount)
          if (qrCode.message === "success") {
            setPaymentLink(qrCode.data.qrLink);
            setQrCodeData(qrCode.data.qrcodeContent);
          }

        }else{
          const webPayment = await topupWebPayment(
            userInfo.user_id,
            amount,
            accountName,
            userInfo.email,
            accountNumber,
            paymentChannel
          );
  
          if (webPayment.message === "success") {
            setPaymentLink(webPayment.data.redirectUrl);
            window.open(webPayment.data.redirectUrl);
            // console.log(webPayment);
            setLinkModalVisible(true);
            setTopupModalVisible(false);
          } else {
            // console.log(webPayment.message);
            if (webPayment.message.includes("mobile")) {
              setTopupLoading(false);
              const errorMessage = webPayment.message.split(':')[1].trim(); // Extract error message
              setAccountNumberError(errorMessage);
            }
          }
        }
        
      } catch (error) {
        setTopupLoading(false);
        console.error("Error creating web payment:", error);
      }
    }

    setTopupLoading(false);
  }

  const handleTopupOpen = () => {
    setAccountName(null);
    setAccountNameError(null);
    setAmount(null);
    setAmountError(null);
    setAccountNumber(null);
    setAccountNumberError(null);
    setPaymentChannel(null);
    setPaymentChannelError(null);
    setQrCodeData(null);
  }

  const handleTopupCancel = () => {
    setTopupModalVisible(false);
  }

  const handleQrOk = () => {
    setAmount(null);
    setAmountError(null);
    setPaymentChannel(null);
    setPaymentChannelError(null);
    setQrCodeData(null);
    setTopupModalVisible(false);
  }

  const handlePaymentChannelChange = (selectedOption) => {
    setPaymentChannel(selectedOption);
  }

  const handleLinkModalClose = () => {
    setLinkModalVisible(false);
    setPaymentLink("");
  };

  const selectedProvider = async (provier) =>{
    setProviderToShow(provier);
  }


  const getItem = (label, key, children) => {
    return {
      key,
      children,
      label,
    };
  };

  const fitleredGames = location.pathname !== "/"
  ? games.filter(item => item.title !== "Slot" && item.title !== "Fishing" && item.title !== "Table" && item.title !== "Casino" && item.title !== "Poker")
  : games;
  
  const items = fitleredGames.map((obj, key) =>
    getItem(
      <div key={key} className={`flex gap-3 items-center ${isMobile ? '' : collapsed && 'justify-center'}`}>
        <Tooltip title={collapsed && obj.title} placement="right">
          <Typography
            level={4}
            // className="my-2 text-lg md:text-[14px] text-[#9C9C9C] font-semibold justify-center flex items-center gap-3"
            className={`${isMobile ? 'my-0 menu-item' : 'my-2'} menu-item-text-selected text-[#9C9C9C] text-lg md:text-[14px] font-semibold flex items-center gap-3`}
          >
            <Image 
              preview={false} 
              // src={obj.icon} 
              src={obj.icon }
              style={{ width: 40 , height:  40, filter: selectedCategory === obj.title && 'brightness(0) invert(1)'}} 
              className="menu-item-image"
            />
            { collapsed ? isMobile ? <span>{obj.title}</span> : null : <span>{obj.title}</span>}
            
          </Typography>
          
        </Tooltip>
      </div>,
      obj.title
      
    )
  );

  const [cashoutVisible, setCashoutVisible] = useState(false);
  const [cashinVisible, setCashinVisible] = useState(false);

  const filteredBackOffice = currentUser?.roleId !== 2
  ? backOffice.filter(item => {
      if (!cashoutVisible && item.title === "Cash-Out Request") {
        return false;
      }
      if (!cashinVisible && item.title === "Cash-In Request") {
        return false;
      }
      if (!cashinVisible && !cashoutVisible && item.title === "Transaction Records") {
        return false;
      }
      return true;
    })
  : backOffice;

  const backOfficeItems = filteredBackOffice.map((obj, key) =>
    getItem(
      <div key={key} className={`flex gap-3 items-center  ${isMobile ? '' : collapsed && 'justify-center'}`}>
        <Tooltip title={collapsed && obj.title} placement="right">
          <Typography
            level={4}
            // className={`my-2 menu-item text-lg md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3`}
            className={`${isMobile ? 'my-0 menu-item' : 'my-2'} menu-item-text-selected text-[#9C9C9C] text-lg md:text-[14px] font-semibold flex items-center gap-3`}
          >
            <Image preview={false} src={obj.icon} style={{ width: 40, height: 40, filter: selectedCategory === obj.title && 'brightness(0) invert(1)' }}/>
            {/* { collapsed ? null : <span>{obj.title}</span>} */}
            { collapsed ? isMobile ? <span>{obj.title}</span> : null : <span>{obj.title}</span>}
          </Typography>
        </Tooltip>
      </div>,
      obj.title
    )
  );

  const [paymentChannelVisible, setPaymentChannelVisible] = useState(false);

  const filteredUserPanel = currentUser?.roleId !== 5
  ? userPanel.filter(item => 
      item.title !== "Bet History" && item.title !== "Cash-In"  && item.title !== "Cash-Out" 
      && !((currentUser?.roleId === 5 || ( currentUser?.roleId > 2 && !paymentChannelVisible)) && item.title === "Payment Channel") 
      && !(currentUser?.roleId > 2 && item.title === "Settings")
    )
  : userPanel.filter(item => item.title !== "Payment Channel"  && item.title !== "WinCash" && item.title !== "Settings");
  
  const userPanelItems = filteredUserPanel.map((obj, key) =>
    getItem(
      <div key={key} className={`flex gap-3 items-center  ${isMobile ? '' : collapsed && 'justify-center'}`}>
        <Tooltip title={collapsed && obj.title} placement="right">
          <Typography
            level={4}
            // className="my-2 menu-item text-lg md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
            className={`${isMobile ? 'my-0 menu-item' : 'my-2'} menu-item-text-selected text-[#9C9C9C] text-lg md:text-[14px] font-semibold flex items-center gap-3`}
          >
            <Image preview={false} src={obj.icon} style={{ width: 40, height: 40, filter: selectedCategory === obj.title && 'brightness(0) invert(1)'}}/>
            {/* {collapsed ? null : <span>{obj.title}</span>} */}
            { collapsed ? isMobile ? <span>{obj.title}</span> : null : <span>{obj.title}</span>}
          </Typography>
        </Tooltip>
      </div>,
      obj.title
    )
  );
  
  return (
    <>
      
      { isLoaded ? 
        <Layout style={{ minHeight: "100vh", background: "#1D2232" }} className={`transition-opacity ${layoutLoading ? "opacity-100" : isMobile ? "opacity-0" : "opacity-0"}`}>
          <Sider
            collapsible
            collapsed={isMobile || collapsed}
          // collapsed={isMobileTest || collapsed}
            onBreakpoint={(broken) => {
              setCollapsed(true);
            }}
            style={{
              background: "#141720",
              zIndex: isMobile ? "0" : "1000",
              // zIndex: isMobileTest ? "0" : "1000",
            }}
            width={"317px"}
            className={
              location.pathname === "/login" ||
              location.pathname === "/contact" ||
              location.pathname === "/faq" ||
              location.pathname === "/terms-and-conditions" ||
              location.pathname === "/privacy-policy" ||
              location.pathname === "/signup" ||
              location.pathname === "/recover" ||
              location.pathname === "/reset-password" ||
              location.pathname === "/verification" ||
              location.pathname === "/games" || 
              location.pathname === "/transaction-policy" ||
              (isDeviceMobile && isMobile)
                ? "hidden"
                : "sidebar"
            }
            theme="light"
            collapsedWidth={isMobile ? 0 : undefined}
          // collapsedWidth={isMobileTest ? 0 : undefined}
            trigger={null}
          >
            <div className="sticky left-0 top-5 bottom-0 h-screen z-20">
              {/* <div className="flex flex-col px-7 py-10 gap-5 h-full overflow-y-auto"> */}
              { isSiderLoading ? (
                <div className={`flex justify-center items-center mt-[200px]`}>
                  <Spin size="large"/>
                  {/* <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff',}} />}
                    /> */}
                </div>
              ) : (
                <div className={`flex flex-col ${collapsed ? 'px-3 py-4' : 'px-5 py-2'} ${isSiderLoading ? 'opacity-0' : 'opacity-100'} duration-500 gap-5 h-full overflow-y-auto`}> {/* - xx */}
                  <div
                    // className="text-[20px] font-medium flex items-center gap-2 cursor-pointer"
                    className={`text-[20px] font-medium flex ${collapsed ? 'flex-wrap justify-center' : 'justify-between'} items-center gap-x-10 gap-y-3 w-full`} // - xx
                  > 
                    {/* <Image
                      style={{
                        width: "41px",
                        objectFit: "cover",
                      }}
                      preview={false}
                      src={coinlogo}
                    />
                    <span className="text-white">
                      Money<span className="text-textYellow italic">Win</span>
                    </span> */}

                    {/* - xx */}
                    {collapsed ? (
                      <MenuUnfoldOutlined
                        className="text-white text-[25px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                        onClick={() => setCollapsed(false)}
                      />
                    ) : (
                      <MenuFoldOutlined
                        className="text-white text-[25px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                        onClick={() => setCollapsed(true)}
                      />
                    )}
                    {/* <div className={`flex ${collapsed ? 'flex-col mt-5' : 'mt-0'} gap-3  text-white`}>
                      <div className="relative cursor-pointer" onClick={() => {navigate('/'); handleNavBarClear()}}>
                        { collapsed ? (
                          <Tooltip placement="right" title={<Image src={collapsed && casino} preview={false}/>}>
                            <Image src={collapsed ? casino1 : casino} preview={false} className="transform transition-transform hover:scale-110 rounded-md" />
                          </Tooltip>
                        ) : <Image src={collapsed ? casino1 : casino} preview={false} className="transform transition-transform hover:scale-110 rounded-md" />}
                      </div>
                      <div className="relative cursor-pointer" onClick={() => {navigate('/'); handleNavBarClear()}}>
                      { collapsed ? (
                        <Tooltip placement="right" title={<Image src={collapsed && sports} preview={false}/>}>
                          <Image src={collapsed ? sports1 : sports} preview={false} className="transform transition-transform hover:scale-110 rounded-md" />
                        </Tooltip>
                      ) : <Image src={collapsed ? sports1 : sports} preview={false} className="transform transition-transform hover:scale-110 rounded-md" />}
                      </div>
                    </div> */}
                  </div>
                  {/* - xx */}
                  {/* RECHARGE AND BALANCE */}
                  <div
                    className={`${
                      // token ? "flex" : "hidden"
                      isAuthenticated ? collapsed ? "hidden" : "flex" : "hidden" // -xx
                    } flex-col gap-3 h-[Auto] bg-[#202638] rounded-md p-4`}
                  >
                    <div className="flex gap-2 text-textYellow items-center">
                      <span className="text-xl font-medium ml-2">Wallet</span>
                      {currentUser?.roleId !== 5 && (
                        <Tooltip placement="right" title="This will show the commission gains/losses coming from your direct players and agents.">
                        <QuestionCircleOutlined className="text-[15px]"/>
                      </Tooltip>
                      ) }
                    </div>
                    {balance ? (
                      /*
                      <div className="bg-white h-[64px] w-full flex items-center justify-left rounded-md p-4">
                        <img src={coinlogo} alt="Coin Logo" className="max-h-full w-auto" />
                        <span className="font-semibold text-lg">{balance}</span>
                      </div>
                      */
                      <div className="bg-white h-[64px] w-full flex items-center justify-between rounded-md p-4">
                        <div className="flex justify-center items-center gap-2">
                          {/* <Image src={coinLogo} preview={false} style={{width: 25, height: 25,}}/> */}
                          {currentUser?.roleId === 5 && <Image src={coinLogo} preview={false} style={{width: 25, height: 25,}}/>}
                          <span className="font-semibold text-lg">{balance}</span>
                        </div>
                        <Tooltip title="">
                          <ReloadOutlined className={`text-[16px] cursor-pointer ${refreshWallet && 'animate-spin'}`} onClick={() => setRefreshWallet(!refreshWallet)}/>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="bg-white h-[64px] w-full flex items-center justify-center rounded-md">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      </div>
                    )}

                    <div
                      className={`${
                        currentUser?.roleId === 5 ? "flex" : "hidden"
                      } text-white h-[39px] w-full flex items-center justify-center gap-3 rounded-md cursor-pointer bg-gradient-to-t from-[#92680C] to-[#FCDB9C] hover:scale-[1.05] transition-all ease-in duration-150`}
                      onClick={handleRechargeClick}
                    >
                      <RechargeIcon />
                      <span className="font-regular text-md">Recharge</span>
                    </div>

                    {currentUser?.roleId < 5 && (

                      <div className="flex-col gap-3 items-center w-full justify-center h-[Auto] mt-4 bg-[#202638] rounded-md">

                        {currentUser?.roleId === 2 && (
                          <>
                            <div className="mb-6">
                              <div className="flex gap-2 text-textYellow items-center">
                                <span className="text-xl font-medium ml-2">WinCash</span>
                                <Tooltip placement="right" title="This is used for topping-up cash directly from a payment gateway, so the players under you can cash-out directly.">
                                  <QuestionCircleOutlined className="text-[15px]"/>
                                </Tooltip>
                              </div>
                              {moneywinCashBalance ? (
                                <div className="bg-[#2AA364] h-[64px] w-full justify-between flex items-center mt-2 text-white p-4 rounded-md">
                                  <span className="font-semibold text-lg">{moneywinCashBalance}</span>
                                  {/* <RechargeIcon onClick={() => { navigateTo('/userpanel/mw-cash'); setNavUserPanel(true); setNavBackOffice(false); setNavContact(false); setNavHome(false);}}/> */}
                                  <Image src={recharge} preview={false} style={{ width: 32, height: 32, filter: 'brightness(100)'}} className="cursor-pointer" onClick={() => { navigateTo('/userpanel/mw-cash'); setNavUserPanel(true); setNavBackOffice(false); setNavContact(false); setNavHome(false);}}/>
                                </div>
                              ) : (
                                <div className="bg-[#2AA364] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                </div>
                              )}
                            </div>

                            <Modal
                              title="Payment"
                              open={linkModalVisible}
                              onCancel={handleLinkModalClose}
                              footer={null}
                            >
                              {/* Display the payment link here */}
                              <p>
                                If you are not automatically redirected,{' '}
                                <a href={paymentLink} target="_blank" rel="noopener noreferrer">
                                  click here
                                </a>{' '}
                                to proceed with the payment.
                              </p>
                              {/* You can customize the content of the modal as needed */}
                            </Modal>

                            <Modal
                              title="Topup WinCash"
                              open={topupModalVisible}
                              onCancel={() => setTopupModalVisible(false)}
                              footer={qrCodeData ? (
                                <>
                                  <Button type="secondary" onClick={handleTopupCancel}>
                                    Cancel
                                  </Button>
                                  <Button type="primary" onClick={handleQrOk}>
                                    OK
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  type="primary"
                                  onClick={handleTopupConfirm}
                                  className="w-full h-[40px]"
                                >
                                  {paymentChannel === "qr_ph" ? "Generate QR" : "Confirm"}
                                </Button>
                              )}
                              onOpen={() => {
                                handleTopupOpen();
                              }}
                              closable={!topupLoading}
                            >
                              {topupLoading ? (
                                <div className="flex justify-center items-center h-32">
                                  {/* Loading indicator */}
                                  <Spin size="large" />
                                </div>
                              ) : (
                                <div className="flex flex-col w-full">
                                  {!qrCodeData ? (
                                    <Form.Item
                                      name="payment-channel"
                                      value={paymentChannel}
                                      validateStatus={paymentChannelError ? 'error' : ''}
                                      help={paymentChannelError}
                                    >
                                      <Select
                                        className="mt-0"
                                        size="large"
                                        placeholder="Select Payment Channel" 
                                        onChange={(selectedOption) => {
                                          handlePaymentChannelChange(selectedOption);
                                        }}
                                        options={paymentOptions}
                                      />
                                    </Form.Item>
                                    ) : null}
                                  

                                  {paymentChannel !== "qr_ph" ? (
                                    <Form.Item
                                      name="accountName"
                                      validateStatus={accountNameError ? 'error' : ''}
                                      help={accountNameError}
                                    >
                                      <Input
                                        placeholder="Account Name"
                                        size="large"
                                        style={{
                                          width: "100%",
                                        }}
                                        value={accountName}
                                        onChange={(e) => {
                                          setAccountName(e.target.value)
                                          setAccountNameError("");
                                        }}
                                      />
                                    </Form.Item>
                                  ) : null}
                                  

                                  {paymentChannel !== "qr_ph" ? (
                                    <Form.Item
                                      name="account-number"
                                      validateStatus={accountNumberError ? 'error' : ''}
                                      help={accountNumberError}
                                    >
                                      <Input
                                        placeholder="Account Number"
                                        size="large"
                                        style={{
                                          width: "100%",
                                        }}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const filteredValue = inputValue.replace(/[^0-9]/g, ''); // Keep only digits and decimal points
                                          setAccountNumber(filteredValue);
                                          setAccountNumberError("");
                                        }}

                                        value={accountNumber}
                                      />
                                    </Form.Item>
                                  ) : null}
                                  
                                  {!qrCodeData ? (
                                    <Form.Item
                                      name="amount"
                                      validateStatus={amountError ? 'error' : ''}
                                      help={amountError}
                                    >
                                      <Input
                                        placeholder="Amount"
                                        size="large"
                                        style={{
                                          width: "100%",
                                        }}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const filteredValue = inputValue.replace(/[^0-9.]/g, ''); // Keep only digits and decimal points
                                          setAmount(filteredValue);
                                          setAmountError("");
                                        }}
                                        value={amount}
                                      />
                                    </Form.Item>
                                  ) : null}

                                
                                  {qrCodeData && paymentLink ? (
                                    <div className="w-full flex flex-col items-center p-5">
                                      <div className="text-center bg-white p-2">
                                        <div className="text-xl">Payment Amount:</div>
                                        <div className="font-bold text-3xl mt-1" style={{ color: '#185F9B' }}>
                                          {parseFloat(amount).toFixed(2)}
                                        </div>
                                        <div className="mt-4 text-xl font-bold">Scan With <span style={{ color: '#012DB8' }}>GCash</span> to pay</div>
                                        <div className="mt-2">Note: Pay the <span className="font-bold">exact amount</span> shown above</div>
                                        <div className="mt-2"><span className="font-bold">One-time payment only</span></div>
                                      </div>
                                      <div className="mt-4">
                                        <QRCode
                                          className="shadow-md"
                                          errorLevel="H"
                                          value={paymentLink}
                                          icon={qrphLogo}
                                          size={300}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
            
                                </div>
                              )}
                            </Modal>
                          </>
                        )}
                        
                        <div>
                          <div className="flex gap-2 text-textYellow items-center">
                            <span className="text-xl font-medium ml-2">Chips</span>
                            <Tooltip placement="right" title="This will be requested from the Admin. Any cash-in from your players will be deducted from your chips.">
                              <QuestionCircleOutlined className="text-[15px]"/>
                            </Tooltip>
                          </div>
                          {chipsBalance ? (
                            <div className="bg-[#305EDB] h-[64px] w-full flex items-center mt-2 text-white p-4 rounded-md">
                              <span className="font-semibold text-lg">{chipsBalance}</span>
                            </div>
                          ) : (
                            <div className="bg-white h-[64px] w-full flex items-center justify-center rounded-md">
                              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </div>
                          )}
                        </div>
                      
                        <div className="mt-6">
                          <div className="flex gap-2 text-textYellow items-center">
                            <span className="text-xl font-medium ml-2">Promotional</span>
                            <Tooltip placement="right" title="This type of currency can't be cashed-out by players but, players can use it as a betting currency.">
                              <QuestionCircleOutlined className="text-[15px]"/>
                            </Tooltip>
                          </div>
                          {promoBalance ? (
                            <div className="bg-[#9C2552] h-[64px] w-full flex items-center mt-2 text-white p-4 rounded-md">
                              <span className="font-semibold text-lg">{promoBalance}</span>
                            </div>
                          ) : (
                            <div className="bg-[#9C2552] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {currentUser?.roleId === 5 && (
                      <div className="flex-col gap-3 items-center w-full justify-center h-[Auto] mt-4 bg-[#202638] rounded-md">
                        <div className="flex justify-start items-center gap-2">
                          <span className="text-textYellow text-xl font-medium ml-2">Bonus</span>
                          <Tooltip title="Bonus Chips will only be used when all the remaining amount on your Wallet is consumed. Winnings from using Bonus Chips will be retained, but the Bonus Chips used will be deducted regardless if you win/lose. ">
                            <QuestionCircleOutlined className="text-textYellow text-[15px]" />
                          </Tooltip>
                        </div>
                        <div
                          style={{ border: "1px solid #3574EC" }}
                          className="flex flex-col gap-3 items-center mt-3 w-full justify-center h-[89px] bg-[#3574EC] bg-opacity-25 rounded-md p-4"
                        >
                          <div className="bg-[#3574EC] h-[64px] w-full flex items-center text-white justify-center rounded-md">
                            <span className="font-semibold text-lg">{bonusBalance}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                    
                  {/* RECHARGE AND BALANCE */}
                  {/* {location.pathname === "/" ? (
                      // <span className="text-textYellow pt-5 text-xl font-medium">
                      <span className={`text-textYellow pt-5 text-xl font-medium ${collapsed ? 'hidden' : 'flex'}`}>
                        Games
                      </span>
                      ) : null} */}
                  {/* MENU ITEMS */}

                  {location.pathname === "/game" ? (
                      null
                    ) :
                      <div className="flex flex-col w-full bg-[#1D2232] rounded-md">
                        <div 
                          // className={`hover:bg-black text-[#9C9C9C] hover:rounded-md hover:opacity-40 p-2 ${siderHome ? 'bg-black text-[#9C9C9C] opacity-75 mx-2 rounded-md' : ''}  duration-300 flex items-center cursor-pointer font-semibold ${!collapsed ? 'justify-start my-4 px-8 gap-3 text-[#9C9C9C] hover:mx-2 hover:px-7' : 'justify-center my-3 px-'}`}
                          className={`hover:bg-[#141720] relative text-[#9C9C9C] hover:rounded-md hover:opacity-40 p-2 ${
                            siderHome ? 'bg-[#141720] opacity-80 text-white mx-2 rounded-md' : ''
                          }  duration-300 flex items-center cursor-pointer font-semibold ${
                            !collapsed ? 'justify-start my-4 px-8 gap-3 text-[#9C9C9C] hover:mx-2 hover:px-7' : 'justify-center my-3 px-'
                          }`}
                          onClick={() => {
                            // const pathToNavigate = userInfo && userInfo.role_id < 5 && location.pathname === "/backoffice" ? '/backoffice' : '/';
                            const pathToNavigate =
                            currentUser?.roleId > 4 
                              ? "/"
                              : currentUser?.roleId < 5
                              ? "/backoffice"
                              : "/";
                            setSiderHome(true);
                            navigateTo(pathToNavigate);
                            setSelectedItem(null);
                            selectedProvider(null);
                            setNavHome(true);
                            setNavBackOffice(true);
                            setNavUserPanel(false);
                            setNavContact(false);
                          }}
                        >
                          { collapsed ? (
                            <Tooltip placement="right" title={<div className="m-2"> Home </div>}>
                              <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>
                            </Tooltip>
                          ) : <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>}
                          { collapsed ? null : <span>Home</span>}
                          
                          {/* { collapsed ? (
                            <Tooltip placement="right" title={<div className="m-2"> Home </div>}>
                              <div className="flex items-center">
                                <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>
                                <span className="ml-2">Home</span>
                              </div>
                            </Tooltip>
                          ) : (
                            <div className="flex items-center">
                              <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>
                              <span className="ml-2">Home</span>
                            </div>
                          )} */}
                        </div>
                        <Divider className="my-0 bg-gray-500" />
                        <Menu
                          theme="light"
                          // className="bg-secondary menu-items "
                          className="bg-[#1D2232] rounded-b-md menu-items hover:text-[#1D2232]"
                          mode="inline"
                          selectedKeys={selectedCategory}
                          defaultSelectedKeys={[location.pathname]}
                          items={
                            location.pathname.startsWith("/backoffice")
                            ? backOfficeItems
                            : location.pathname.startsWith("/userpanel")
                            ? userPanelItems
                            : items
                          }
                          onClick={menuClick}
                        />
                      </div>
                  }
                  {/* xx */}
                  { location.pathname === "/" && ( 
                    // <div className="flex relative flex-col gap-5 font-semibold">
                    //   <div className={`flex bg-[#1D2232] w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${!collapsed ? 'justify-start py-2 px-7 gap-4 text-[#9C9C9C] ' : 'justify-center py-2'}`}>
                    //     { collapsed ? (
                    //       <Tooltip placement="right" title={<div className="m-2"> Gaming Providers</div>}>
                    //         <Image src={gamingprovider} preview={false} style={{ width: 40, height: 40,}}/>
                    //       </Tooltip>
                    //     ) : <Image src={gamingprovider} preview={false} style={{ width: 40, height: 40,}}/>}
                    //     { collapsed ? null : <span>Gaming Providers</span>}
                    //   </div>
                      
                    //   <div onClick={handleRegModalOpen} className={`${!userInfo ? 'flex' : 'hidden'} gradient-button shadowed glowing w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${!collapsed ? 'justify-start py-2 px-7 gap-3' : 'justify-center pb-1 pt-2'}`}>
                    //     { collapsed ? (
                    //       <Tooltip placement="right" title={<div className="m-2"> Register Now </div>}>
                    //         <Image src={registernow} preview={false}/>
                    //       </Tooltip>
                    //     ) : <Image src={registernow} preview={false}/>}
                    //     { collapsed ? null : <span>Register Now</span>}
                    //   </div>
                    // </div>
                    !isAuthenticated ? 
                      <div onClick={handleRegModalOpen} className={`flex gradient-button shadowed glowing w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${!collapsed ? 'justify-start py-2 px-7 gap-3' : 'justify-center pb-1 pt-2'}`}>
                      { collapsed ? (
                        <Tooltip placement="right" title={<div className="m-2"> Register Now </div>}>
                          <Image src={registernow} preview={false}/>
                        </Tooltip>
                      ) : <Image src={registernow} preview={false}/>}
                      { collapsed ? null : <span>Register Now</span>}
                    </div>
                  : null
                  )}
                  {/* xx */}
                  {/* MENU ITEMS */}
                
                  {/* MENU ITEMS */}
                  <Divider className="my-1 bg-dividerGray" />
                  {/* LOGOUT BUTTON */}
                  {isAuthenticated ?
                    <div
                      className={`flex bg-[#1D2232] w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${
                        !collapsed
                          ? 'justify-center py-4 px-7 gap-3'
                          : 'justify-center py-4' // Adjust padding for vertical centering
                      }`}
                      onClick={Logout}
                    >
                      {collapsed ? (
                        <Tooltip placement="right" title={<div className="m-2"> Logout </div>}>
                          {loggingOut ? 
                            <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                          : 
                          <div onClick={Logout}>
                            <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                          </div>
                          }
                          
                        </Tooltip>
                      ) : (
                        loggingOut ? (
                          <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                        ) : (
                          <>
                            <div className="flex gap-3">
                            <div className="flex gap-3 items-center" onClick={Logout}>
                              <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                              <span className="text-[#9C9C9C] font-semibold text-lg">Logout</span>
                            </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  : null}
                  
                  {/* LOGOUT BUTTON */}
                  { !collapsed && 
                    <span className="text-center text-[14px] mb-10 text-footerGray">
                      © MoneyWin
                    </span>
                  }
                </div>
              )}
            </div>
          </Sider>
          <Layout className="site-layout">
            <Header
              className={`header bg-primary p-0 md:px-7 ${(isMobile || isDeviceMobile) && location.pathname === '/game' ? 'hidden' : 'flex'}`}
              // className={`header bg-primary p-0 md:px-7`}
              style={{
                height: "5rem",
                // display: isMobile ? location.pathname === '/game' ? 'hidden' : 'hidden' : 'flex',
                alignItems: "center",
                justifyContent: "end",
                // position: isMobile ? "fixed" : "sticky",
                position: "sticky",
                // top: isMobile ? undefined : "0",
                top: "0",
                // bottom: isMobile ? "0" : undefined,
                // zIndex: isMobile ? "1500" : "1000",
                // zIndex: "1000",
                zIndex: collapsed ? '1000' : '500',
                width: "100%",
              }}
            >
              {/* <div className="hidden md:flex text-white  items-center justify-start w-full h-full">
                {collapsed ? (
                  <MenuUnfoldOutlined
                    // className="menu trigger "
                    className="text-white text-[20px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                    onClick={() => setCollapsed(false)}
                  />
                ) : (
                  <MenuFoldOutlined
                    // className="menu trigger "
                    className="text-white text-[20px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                    onClick={() => setCollapsed(true)}
                  />
                )}
              </div> */}

              <div className="flex md:hidden justify-between px-4 h-full items-center w-full">
                <div
                  className="text-[20px] font-medium flex items-center gap-2"
                  onClick={() => {
                    if (currentUser?.roleId < 5) {
                      navigateTo("/backoffice");
                    } else {
                      navigateTo("/");
                    }
                  }}
                >
                  <Image
                    style={{
                      // width: "41px",
                      width: "80px",
                      objectFit: "cover",
                    }}
                    preview={false}
                    // src={coinlogo}
                    src={MONEYWINLOGO1}
                  />
                  {/* <span className="text-white">
                    Money<span className="text-textYellow italic">Win</span>
                  </span> */}
                </div>
                
                <div className="md:hidden text-white flex items-center gap-2">
                  {/* {collapsed ? (
                    <MenuUnfoldOutlined
                      // className="menu trigger "
                      className="text-white text-[20px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                      onClick={() => setVisible(true)}
                    />
                  ) : (
                    <MenuFoldOutlined
                      // className="menu trigger "
                      className="text-white text-[20px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                      onClick={() => setVisible(true)}
                    />
                  )} */}

                  {isAuthenticated ? 
                  <div className="flex items-center">
                    <Avatar
                      className="bg-white text-black mr-4"
                      size={44}
                      src={profilePicture ? profilePicture : undefined}
                      // icon={<UserOutlined />}
                      onClick={() => {
                        navigateTo("/userpanel/profile");
                      }}
                    >
                      <span>{fname?.slice(1) && fname?.charAt(0).toUpperCase()}{lname?.slice(1) && lname?.charAt(0).toUpperCase()}</span>
                    </Avatar>
                    <div className="flex flex-col items-start w-[90px]">
                      <span className="text-white text-[14px] leading-6 text-ellipsis overflow-hidden w-[90px] md:w-auto">
                        {currentUser.username}
                      </span>
                      <div className="flex items-center gap-1">
                        <img
                          src={coinLogo}
                          alt="Coin Logo"
                          style={{ width: '15px', height: '15px' }} // Adjust the size as needed
                        />
                        <span className="text-[#FCDB9C] text-[14px] leading-6 text-ellipsis overflow-hidden w-[70px] md:w-auto">
                          {balance}
                          {/* 20,000,000.00 */}
                        </span>
                      </div>
                    </div>
                  </div>
                  :
                    <>
                    {location.pathname !== "/login" ? 
                        <button
                          className="text-[#FCDB9C] w-[90px] bg-transparent border border-[#FCDB9C] h-[35px] font-semibold rounded-lg flex items-center justify-center cursor-pointer hover:scale-[1.10] transition-all ease-in duration-150"
                          onClick={() => {
                            navigateTo("/login");
                            scrollToTop();
                          }}
                        >
                          {location.pathname.startsWith("/verification") ? "Verify Later" : "Sign In"}
                        </button>
                    : null }
                      
                      {location.pathname !== "/signup" && location.pathname !== "/login"?  
                        <button
                          className="bg-[#FFCE29] shadowed glowing gradient-button text-white border-0 w-[90px] h-[35px] font-semibold rounded-lg flex items-center justify-center cursor-pointer hover:scale-[1.10] transition-all ease-in duration-150"
                          onClick={() => {
                            navigateTo("/signup");
                            scrollToTop();
                          }}
                        >
                          <span className="outlined-text text-[14px]">Register</span>
                        </button>
                      : null}
                    </>
                  }
                </div>
              </div>

              {/* HEADER */}

              {isAuthenticated ? <AuthItems /> : <UnAuthItems />}
              

              {/* HEADER */}

              {/* <div className="md:flex flex-row hidden gap-16 justify-end items-center py-6 text-white">
                <span
                  className="cursor-pointer font-regular text-[16px]"
                  onClick={() => navigate("/")}
                >
                  Home
                </span>
                <span className="cursor-pointer font-regular text-[16px]">
                  Contact
                </span>
                <button
                  className="bg-buttonYellow border-0 w-[155px] h-[50px] font-semibold text-[16px] rounded-lg flex items-center text-center justify-center cursor-pointer hover:scale-[1.03] transition-all ease-in duration-150"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </button>
              </div> */}

              <Drawer
                closable={false}
                className="layout bg-secondary"
                width={"317px"}
                height={"100%"}
                placement={ isMobile ? "bottom" : "left"}
                onClick={() => setVisible(false)}
                onClose={closeDrawer}
                open={visible}
              >
                <div className="flex flex-col relative p-7 gap-5 bg-secondary h-full w-full">
                  <div className=" text-[20px]  justify-between my-7 font-medium flex items-center ">
                    <div className="flex flex-row absolute top-0 left-0 right-0 justify-center items-center gap-2">
                      <Image
                        style={{
                          // width: "41px",
                          width: "100px",
                          objectFit: "cover",
                        }}
                        preview={false}
                        // src={coinlogo}
                        src={MONEYWINLOGO1}
                      />
                      {/* <span className="text-white">
                        Money<span className="text-textYellow italic">Win</span>
                      </span> */}
                    </div>
                    {/* <ArrowLeftOutlined
                      className="text-white hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                      onClick={() => setVisible(false)}
                    /> */}
                    {/* <MenuFoldOutlined
                      className="text-white hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                      onClick={closeDrawer}
                    /> */}
                  </div>
                  {isAuthenticated ? (
                    <>
                      {/* <span
                        className="cursor-pointer text-white font-semibold text-lg ml-2"
                          onClick={() => {
                            navigate("/");
                            closeDrawer();
                          }}
                      >
                        Home
                      </span> */}
                      {/* New - xlx */}
                      {currentUser?.roleId === 5 ? "" : 
                        (
                          <div className="">
                            <Divider className="mt-[0px] mb-[20px] bg-dividerGray"/>
                            <span className="cursor-pointer text-white font-semibold text-lg ml-2"
                              onClick={() => {navigateTo("/backoffice"); closeDrawer()}}>
                              Back Office
                            </span>
                            <Divider className="mt-[20px] mb-[-5px] bg-dividerGray"/>
                          </div>
                        )
                      }
                      {/* xlx */}
                      
                      <div>
                        {currentUser?.roleId === 5 && <Divider className="mt-[0px] mb-[20px] bg-dividerGray"/>}
                        <span
                          className="cursor-pointer text-white font-semibold text-lg ml-2"
                          onClick={() => {
                            const path = currentUser?.roleId === 5 ? "/userpanel/betting-history" : "/userpanel/profile";
                            navigateTo(path);
                          }}
                        >
                          User Panel
                        </span>
                        <Divider className="mt-[20px] mb-[-5px] bg-dividerGray"/>
                      </div>
                      {/* <span className="cursor-pointer text-white font-semibold text-lg ml-2"
                        onClick={() => {
                          navigate("/contact");
                          closeDrawer();
                        }}>
                        Contact
                      </span> */}
                      <span className="ml-2 text-textYellow text-xl font-medium">Wallet</span>
                      {currentUser?.roleId === 5 ? (
                        <div className="bg-white h-[64px] w-full flex items-center justify-between rounded-md p-4">
                          <div className="flex justify-center items-center gap-2">
                            {currentUser?.roleId === 5 && <Image src={coinLogo} preview={false} style={{width: 25, height: 25,}}/>}
                            <span className="font-semibold text-lg">{balance}</span>
                          </div>
                          <div
                            className="cursor-pointer w-[39px] h-[39px] rounded-md flex items-center justify-center bg-gradient-to-t from-[#92680C] to-[#FCDB9C]"
                            onClick={() => { handleRechargeClick(); closeDrawer(); }}
                          >
                            <AddBalanceIcon />
                          </div>
                        </div>
                      ) : (
                        <div className="bg-white h-[64px] w-full flex items-center justify-center rounded-md p-4">
                          <span className="font-semibold text-lg">{balance}</span>
                        </div>
                      )}
                      

                      {currentUser?.roleId < 5 && (
                        <div>
                          {currentUser?.roleId === 2 && (
                            <div className="mb-6">
                              <span className="text-textYellow text-xl font-medium ml-2">WinCash</span>
                              {moneywinCashBalance ? (
                                <div className="bg-[#2AA364] h-[64px] w-full flex items-center mt-2 text-white px-5 rounded-md relative">
                                  <span className="font-semibold text-lg absolute left-1/2 transform -translate-x-1/2">
                                    {moneywinCashBalance}
                                  </span>
                                  <div
                                    className="cursor-pointer w-[39px] h-[39px] rounded-md flex items-center justify-center pr-3 bg-[#2AA364] absolute right-0"
                                    onClick={() => { handleTopUpClick(); }}
                                  >
                                    <RechargeIcon />
                                  </div>
                                </div>
                              ) : (
                                <div className="bg-[#2AA364] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                </div>
                              )}
                            </div>
                          )}

                          <div>
                            <span className="text-textYellow text-xl font-medium ml-2">Chips</span>
                            {chipsBalance ? (
                              <div className="bg-[#305EDB] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                                <span className="font-semibold text-lg">{chipsBalance}</span>
                              </div>
                            ) : (
                              <div className="bg-white h-[64px] w-full flex items-center justify-center rounded-md">
                              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </div>
                            )}
                          </div>
                        
                          <div className="mt-6">
                            <span className="text-textYellow text-xl font-medium ml-2">Promotional</span>
                            {promoBalance ? (
                              <div className="bg-[#9C2552] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                                <span className="font-semibold text-lg">{promoBalance}</span>
                              </div>
                            ) : (
                              <div className="bg-[#9C2552] h-[64px] w-full flex items-center mt-2 text-white justify-center rounded-md">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      
                      {currentUser?.roleId === 5 && (
                        <div>
                          <span className="text-textYellow text-xl font-medium ml-2">Bonus</span>
                          <div
                            style={{ border: "1px solid #3574EC" }}
                            className="flex flex-col gap-3 items-center mt-3 w-full justify-center h-[89px] bg-[#3574EC] bg-opacity-25 rounded-md p-4"
                          >
                            <div className="bg-[#3574EC] h-[64px] w-full flex items-center text-white justify-center rounded-md">
                              <span className="font-semibold text-lg">{bonusBalance}</span>
                            </div>
                          </div>
                        </div>
                      )}

                    </>
                  ) : ( //border border-solid border-[#FCDB9C] rounded-md
                    <>
                      {/* <span className="cursor-pointer text-white font-semibold text-lg"
                        onClick={() => {navigate("/contact"); closeDrawer()}}>
                        Contact
                      </span> */}
                      {location.pathname !== "/login" && ( // Conditionally render the button
                        <button
                          // className="cursor-pointer bg-button hover:bg-yellow-600 border-0 p-4 font-semibold rounded-md text-white text-lg"
                          className="cursor-pointer bg-gradient-to-l from-[#92680C] to-[#FCDB9C] border-0 p-4 mt-10 font-semibold rounded-md text-black text-lg"
                          onClick={() => {
                            navigateTo("/login");
                            closeDrawer();
                          }}
                        >
                          <span className="outlined-text text-white">Sign In</span>
                        </button>
                      )}
                    </>
                  )}
                  {/* <div 
                    className={`hover:bg-[#141720] relative text-[#9C9C9C] hover:rounded-md hover:opacity-40 p-2 ${
                      siderHome ? 'bg-[#141720] opacity-80 text-white mx-2 rounded-md' : ''
                    }  duration-300 flex items-center cursor-pointer font-semibold ${
                      !collapsed ? 'justify-start my-4 px-8 gap-3 text-[#9C9C9C] hover:mx-2 hover:px-7' : 'justify-center my-3 px-'
                    }`}
                    onClick={() => {
                      const pathToNavigate = userInfo && userInfo.role_id < 5 && location.pathname === "/backoffice" ? '/backoffice' : '/';
                      navigateTo(pathToNavigate);
                      selectedProvider(null);
                      setSelectedItem(null);
                      setNavHome(true);
                      setNavBackOffice(false);
                      setNavUserPanel(false);
                      setNavContact(false);
                    }}
                  >
                    { collapsed ? (
                      <Tooltip placement="right" title={<div className=""> Home </div>}>
                        <Image src={home} preview={false} style={{ width: 32, height: 32,}}/>
                      </Tooltip>
                    ) : <Image src={home} preview={false} style={{ width: 32, height: 32,}}/>}
                    { collapsed ? null : <span className="text-lg md:text-[14px]">Home</span>}
                  </div> */}
                  <div 
                    // className={`hover:bg-black text-[#9C9C9C] hover:rounded-md hover:opacity-40 p-2 ${siderHome ? 'bg-black text-[#9C9C9C] opacity-75 mx-2 rounded-md' : ''}  duration-300 flex items-center cursor-pointer font-semibold ${!collapsed ? 'justify-start my-4 px-8 gap-3 text-[#9C9C9C] hover:mx-2 hover:px-7' : 'justify-center my-3 px-'}`}
                    className={`hover:bg-[#141720] text-xl relative text-[#9C9C9C] hover:rounded-md hover:opacity-40 p-2 ${
                      siderHome ? 'bg-[#141720] opacity-80 text-white rounded-md' : ''
                    }  duration-300 flex items-center cursor-pointer font-semibold justify-start md:my-4 px-8 gap-3 hover:mx-2 hover:px-7`}
                    onClick={() => {
                      // const pathToNavigate = userInfo && userInfo.role_id < 5 && location.pathname === "/backoffice" ? '/backoffice' : '/';
                      const pathToNavigate =
                        currentUser?.roleId > 4 
                          ? "/"
                          : currentUser?.roleId < 5
                          ? "/backoffice"
                          : "/";
                      setSiderHome(true);
                      navigateTo(pathToNavigate);
                      setSelectedItem(null);
                      selectedProvider(null);
                      setNavHome(true);
                      setNavBackOffice(false);
                      setNavUserPanel(false);
                      setNavContact(false);
                    }}
                  >
                    { collapsed ? (
                      <Tooltip placement="right" title={<div className="m-2"> Home </div>}>
                        <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>
                      </Tooltip>
                    ) : <Image src={home} preview={false} style={{ width: 32, height: 32, filter: siderHome && 'brightness(100)'}}/>}
                    <span>Home</span>
                  </div>

                  
                  <Divider className="my-[-5px] bg-dividerGray" />
                  
                  {/* RECHARGE AND BALANCE */}
                  {/* {location.pathname === "/" ? (
                    <span className="text-textYellow pt-0 ml-2 text-xl font-medium">
                      Games
                    </span>
                    ) : null} */}
                  {/* MENU ITEMS */}

                  {location.pathname === "/game" ? (
                    null
                  ) :
                    <>
                      <Menu
                        theme="light"
                        // className="bg-secondary menu-items"
                        className="menu-items bg-secondary"
                        mode="inline"
                        selectedKeys={selectedCategory}
                        defaultSelectedKeys={[location.pathname]}
                        items={
                          location.pathname.startsWith("/backoffice")
                            ? backOfficeItems
                            : location.pathname.startsWith("/userpanel")
                            ? userPanelItems
                            : items
                        }
                        onClick={menuClick}
                      />
                    </>
                  }
                  {/* { token ? <Divider className="my-3 bg-dividerGray" /> :  location.pathname === '/contact' ? null : <Divider className="my-3 bg-dividerGray" />} */}
                  { location.pathname.startsWith === '/backoffice' || location.pathname === '/' || location.pathname.startsWith === '/userpanel' ? <Divider className="my-3 bg-dividerGray" /> : null }
                  {/* LOGOUT BUTTON */}
                  
                  {isAuthenticated ? 
                    <div
                      className={`${
                        isAuthenticated ? 'flex' : 'hidden'
                      } items-center gap-3 justify-center cursor-pointer hover:bg-[#271e30] ${(location.pathname === '/contact' || location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions' || location.pathname === '/faq' || location.pathname === '/games') && 'mt-[-30px]'} transition-all ease-in duration-150 p-2 rounded-lg`}
                      onClick={()=>{Logout(); closeDrawer()}}
                    >
                      {loggingOut ? (
                        <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                      ) : (
                        <>
                          <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                          <span className="text-center text-white font-semibold text-lg">Logout</span>
                        </>
                      )}
                    </div>
                  : null}
                  {/* LOGOUT BUTTON */}
                  <span className="text-center pb-[140px] md:pb-0 text-[14px] text-footerGray">
                    © MoneyWin
                  </span>
                </div>
              </Drawer>
              
            </Header>
              
            <Content
              className="layout bg-mobile-bg md:bg-desktop-bg bg-center bg-fixed bg-repeat"
              style={{
                minHeight: '1080px',
                height: '100%', // Set the height to fill the viewport height
              }}
            >
              {/* {children} */}
              {setMessageHolder}
              {selectedCategory ? (
                location.pathname === "/" ? (
                  <div>
                    <AllGames categoriesToShow={selectedCategory} toggleRegModal={toggleRegModal} selectedProvider={selectedProvider} providerToShow={providerToShow} navigateToSignUp={navigateToSignUp}/>
                  </div>
                ) : children  
              ) : (
                <>
                  {(location.pathname !== "/") && children}
                  {((location.pathname === "/" && !isAuthenticated) || (location.pathname === "/" && currentUser?.roleId === 5)) && (
                    <div>
                      <Home />
                      <AllGames
                        categoriesToShow={selectedCategory}
                        toggleRegModal={toggleRegModal}
                        selectedProvider={selectedProvider}
                        providerToShow={providerToShow}
                        navigateToSignUp={navigateToSignUp}
                      />
                    </div>
                  )}
                </>
              )}

              {/* <div className={`flex fixed bottom-0 z-[1500] bg-gradient-to-t from-black to-[#212121] md:hidden rounded-t-[30px] h-[80px] justify-between px-2 items-center w-full 
                    location.pathname === "/game" ||
                    location.pathname.startsWith("/verification") ? "hidden" : ""
                    }`
                  }> */}
              <div className={`${location.pathname === "/game" || location.pathname.startsWith("/verification") ? "hidden" : "flex"} fixed bottom-0 z-[1500] bg-gradient-to-t from-black to-[#212121] md:hidden rounded-t-[30px] h-[80px] justify-between px-2 items-center w-full`}>
                {mobileNavItems.map((item, index) => (
                  <div 
                    key={index} 
                    className={`flex flex-col ${item.title === "Deposit" ? "mb-[55px] p-3 rounded-full bg-gradient-to-t from-black to-[#212121] shadowed glowing" : "mb-5 px-5"} justify-center items-center`}
                    onClick={() => {
                      switch (item.title) {
                        case "Browse":
                          setVisible(!visible);
                          setRefreshWallet(!refreshWallet);
                          break;
                        case "App":
                          if(!messageHolderVisible){
                            setMessageHolderVisible(true);
                            showTimedMessageWithAction(messageHolder, "Download App", "Coming Soon!", "Close", 5, true,"warning", async () => {
                              setMessageHolderVisible(false);
                            })
                          }
                          break;
                        case "Deposit":
                        case "Account":
                          closeDrawer();
                          if (isAuthenticated) {
                            navigateTo(
                              item.title === "Deposit" ? "/userpanel/cashin" : "/userpanel/profile"
                            );
                          } else {
                            navigateTo("/signup");
                          }
                          break;
                        case "Customer Care":
                          closeDrawer();
                          navigateTo("/contact");
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <div className="flex-none">
                      <Image preview={false} src={item.icon} style={{ height: "32px", width: "32px", objectFit: "cover"}}/>
                    </div>
                    <div className={`flex absolute ${item.title === "Deposit" ? "mt-[80px]" : "mt-[55px]"}`}>
                      <span className="text-white flex-none text-[9px]">{item.title}</span>
                    </div>
                  </div>
                ))}
              </div>
              <Modal
                title={null} // Remove the title
                footer={null} // Remove the footer
                open={isRegModalVisible}
                onCancel={handleRegModalClose}
                className="mt-20 mb-20"
                centered
                width={"50%%"}
                bodyStyle={{ background: 'transparent' }} // Set content background to transparent
                wrapClassName="borderless-modal custom-modal"
                closeIcon={<span className="text-3xl font-normal pr-5 pt-1">×</span>}
                style={{ border: "2px solid #000", borderRadius: "8px", padding: "0" }}
                >
                  <div style={{ width: "100%", border: "none" }}>
                    <SignUp isModal={true} toggleRegModal={toggleRegModal} toggleVerificationModal={toggleVerificationModal} referral={referralCode}/>
                  </div>
              </Modal>

              <Modal
                title={null} // Remove the title
                footer={null} // Remove the footer
                open={isVericationModalVisible}
                onCancel={handleVerificationModalClose}
                className="mt-20 mb-20"
                centered
                width={"50%%"}
                bodyStyle={{ background: 'transparent' }} // Set content background to transparent
                wrapClassName="borderless-modal custom-modal"
                closeIcon={<span className="text-3xl font-normal pr-5 pt-5">×</span>}
                style={{ boder: "none", padding : "0"}}
                >
                  <div style={{ width: "100%", border: "none" }}>
                    <MobileVerification isModal={true} toggleVerificationModal={toggleVerificationModal}/>
                  </div>
              </Modal>
              <Modal
                open={isDisclaimerOpen}
                // onCancel={() => setIsDisclaimerOpen(false)}
                footer={null}
                closeIcon={null}
              >
                <div className="flex flex-col gap-4 justify-center items-center mt-10 mb-[60px] md:mb-0 md:py-6 md:px-8">
                  <div className="md:w-[341px] absolute mx-auto flex items-center justify-center md:text-center top-[-80px]">
                    <Image preview={false} src={mwLogo} style={{ objectFit: 'cover', height: 140,}}/>
                  </div>
                  <span className="font-bold text-[25px]">DISCLAIMER</span>
                  <span className="text-center leading-relaxed my-6">Welcome! Before you explore our website, we'd appreciate it if you could spare a moment to check out our <a href="/terms-and-conditions" target="_blank" rel="noreferrer" onClick={handleClick}>Terms and Conditions</a> and <a href="/transaction-policy" target="_blank" rel="noreferrer" onClick={handleClick}>Transaction Policy</a>. Your entry signifies that you've read and agreed to these terms.  <br></br><br></br> Thanks for your understanding!</span>
                  <button className="gradient-button w-[300px] h-[50px] outlined-text border-none rounded-md tracking-widest cursor-pointer" onClick={() => {setIsDisclaimerOpen(false); handleClick()}}>CONFIRM</button>
                </div>
              </Modal>
            </Content>
            <Footer
              className={`bg-primary md:bg-primary relative pb-[100px] md:pb-0`}
              style={{
                textAlign: "center",
              }}
            >
              <div className="flex flex-col max-w-[1360px] w-full mx-auto ">
                <div className="flex w-full md:justify-between justify-center md:px-10 items-center logo-contact ">
                  <div className="hidden md:flex flex-row items-center gap-2">
                    <Image
                      style={{
                        width: "90px",
                        objectFit: "cover",
                      }}
                      preview={false}
                      // src={coinlogo}
                      src={MONEYWINLOGO1}
                    />
                    {/* <span className="text-white text-[20px]">
                      Money<span className="text-textYellow italic">Win</span>
                    </span> */}
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    <Image className="rounded-lg" preview={false} src={email} />
                    <span className="text-white text-[16px]">
                      support@moneywin.bet
                    </span>
                  </div>
                </div>
                <Divider className="bg-footerGray" />
                <div className="flex flex-wrap gap-5 md:flex-row md:justify-evenly z-[20]">
                  <div className="flex flex-col text-left text-white gap-5">
                    
                    <span className="uppercase font-semibold text-[18px] italic">
                      Games
                    </span>
                    <span className="text-textLightGray text-[16px] cursor-pointer hover:text-gray-600" onClick={() => {navigateTo('/games?game=0'); handleNavBarClear();}}>Slot</span>
                    <span className="text-textLightGray text-[16px] cursor-pointer hover:text-gray-600" onClick={() => {navigateTo('/games?game=1'); handleNavBarClear();}}>Casino</span>
                    <span className="text-textLightGray text-[16px] cursor-pointer hover:text-gray-600" onClick={() => {navigateTo('/games?game=2'); handleNavBarClear();}}>Fishing</span>
                    <span className="text-textLightGray text-[16px] cursor-pointer hover:text-gray-600" onClick={() => {navigateTo('/games?game=3'); handleNavBarClear();}}>Poker</span>
                    <span className="text-textLightGray text-[16px] cursor-pointer hover:text-gray-600" onClick={() => {navigateTo('/games?game=4'); handleNavBarClear();}}>Table</span>
                  </div>
                  <div className="flex flex-col text-left text-white gap-5">
                    <span className="uppercase font-semibold text-[18px] italic">
                      HELP
                    </span>
                    <span
                      className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                      onClick={() => {
                        navigateTo("/contact");
                        setNavBackOffice(false);
                        setNavHome(false);
                        setNavUserPanel(false);
                        setNavContact(true);
                    }}
                    >
                      Contact Us
                    </span>
                    {isAuthenticated &&
                      <span
                        className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                        onClick={() => {navigateTo("/privacy-policy"); handleNavBarClear()}}
                      >
                        Privacy Policy
                      </span>
                    }
                   
                    <span 
                      className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                      onClick={() => {navigateTo("/terms-and-conditions"); handleNavBarClear()}}
                    >
                      Terms and Conditions
                    </span>
                    <span 
                      className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                      onClick={() => {navigateTo("/transaction-policy"); handleNavBarClear()}}
                    >
                      Transaction Policy
                    </span>
                    <span
                      className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                      onClick={() => {navigateTo("/faq"); scrollToTop(); handleNavBarClear()}}

                    >
                      FAQ
                    </span>
                  </div>
                  <div className="flex flex-col text-left text-white gap-5">
                    <span className="uppercase font-semibold text-[18px] italic">
                      ABOUT US
                    </span>
                    <span className="text-textLightGray text-[16px]">
                      Join now for the best <br />{" "}
                      online gaming experience!
                    </span>
                  </div>
                </div>
                <Divider className="bg-footerGray" />
                <span className="text-footerGray py-5">
                  Copyright © 2023 MoneyWin. All rights reserved.
                </span>
              </div>
              <div className="absolute bottom-0 left-0 block z-[9]">
                <Image preview={false} src={footerImg} />
              </div>
              <div className="md:absolute md:bottom-0 md:right-16 hidden md:block z-[10]">
                <Image preview={false} src={footerImgTwo} />
              </div>
            </Footer>
          </Layout>
        </Layout>
      : (
        <div className={`bg-secondary w-full h-[100vh] transition-opacity flex justify-center ${landingLoading ? "opacity-100" : "opacity-0"} items-center`}>
          <div
            className={`text-[30px] flex flex-col justify-center items-center font-medium gap-2`}
            onClick={() => {navigateTo("/");}}
          >
            <Image
              style={{
                // width: "61px",
                width: "150px",
                objectFit: "cover",
              }}
              preview={false}
              // src={coinlogo}
              src={MONEYWINLOGO1}
            />
            {/* <span className="text-white">
              Money<span className="text-textYellow italic">Win</span>
            </span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default LayoutWithRoute;
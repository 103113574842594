import React from 'react'
import { disclaimerCashInItems, disclaimerCashOutItems, disclaimerGenRules } from '../../../static/constants';
const TransactionPolicy = () => {
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-terms-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10 py-16 px-2 md:px-4">
        <span className="text-white text-[36px] font-semibold text-center">
          Transaction Policy
        </span>
        <div className="w-full min-h-[389px] max-w-[900px] bg-privacy p-8 md:p-12 text-white md:text-[18px] text-[16px] leading-8 text-justify">
          <div className="flex flex-col items-center gap-4 p-3">
            <span className="text-center">Welcome to MoneyWin! Before you proceed with cashing in or cashing out, please take a moment to read and understand our rules and procedures.</span>
            <div className="flex flex-col items-center gap-4">
              <div className="flex flex-col text-left gap-3">
                <span className="font-semibold text-[#FCDB9C]">Cashing In:</span>
                { disclaimerCashInItems.map((item, index) => (
                  <div key={index} className='flex flex-col'>
                    <span className='text-[14px]'>{item.title}</span>
                    <span className="text-[13px] mx-[15px]">{item.label}</span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col text-justify gap-3">
                <span className="font-semibold text-[#FCDB9C]">Cashing Out:</span>
                { disclaimerCashOutItems.map((item, index) => (
                  <div key={index} className='flex flex-col'>
                    <span className='text-[14px]'>{item.title}</span>
                    <span className="text-[13px] mx-[15px]">{item.label}</span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col text-justify gap-3">
                <span className="font-semibold text-[#FCDB9C]">General Rules:</span>
                { disclaimerGenRules.map((item, index) => (
                  <div key={index} className='flex flex-col'>
                    <span className='text-[14px]'>{item.title}</span>
                    <span className="text-[13px] mx-[15px]">{item.label}</span>
                  </div>
                ))}
              </div>
              <span className="text-center text-[13px]">By using MoneyWin, you agree to adhere to these cash-in and cash-out rules. If you have any questions or concerns, please contact our customer support team at <span className="text-blue-500 font-semibold">support@moneywin.bet</span></span>
            </div>
            <span className="text-center text-[13px]">Enjoy playing in MoneyWin!</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionPolicy
import { Card, Input, Select, Table, Spin, Button, Modal, Switch, DatePicker, Tooltip, Divider, message as floatingMessage  } from "antd";
import { SearchOutlined, CloseOutlined, CloseCircleFilled } from "@ant-design/icons";
import React from "react";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import "../../layout/index.css";
import { useEffect , useState} from "react";
import { getManagedUsers, generateReferral, modifyPercentage, getRemainingPercentage, addChips, getUpline, getChipsInfo, getUserInfo, getUserBalance, addPlayerChips, addPlayerBonusChips } from "../../../services/moneywin/moneywin-api";
import { showConstrainedMessages, showTimedMessage, showTimedMessageWithAction, formatAsMoney } from "../../../services/util/utilities";
import moneyWinConfig from "../../../services/moneywin/moneywin-config";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const TeamManagement = () => {

  const location = useLocation();
  const [user_id, setUserId] = useState(location.state?.user_id);
  const [role_id, setRoleId] = useState(location.state?.role_id);
  const [message, messageHolder] = Modal.useModal();
  const [options, setOptions] = useState([]);
  const [managedUsers, setManagedUsers] = useState([]);
  const [managedUsers2, setManagedUsers2] = useState([]);
  const [managedPlayers, setManagedPlayers] = useState([]);
  const [managedPlayers2, setManagedPlayers2] = useState([]);
  const [managedPlayers3, setManagedPlayers3] = useState([]);
  const [referralData, setReferralData] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
  const [cardTitle1, setCardTitle1] = useState(null);
  const [cardTitle2, setCardTitle2] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchMode, setSearchMode] =useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [agentPercentage, setAgentPercentage] = useState(null);
  const [remaingPercentage, setRemainingPercentage] = useState(null);
  const [hasUsers, setHasUsers] = useState(false);
  const [hasPlayers, setHasPlayers] = useState(false);
  const [hasPlayers2, setHasPlayers2] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [showCommission, setShowCommission] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [chipsModal, setChipsModal] = useState(false);
  const [chipsModalTitle, setChipsModalTitle] = useState(false);
  const [addChipsLoading, setAddChipsLoading] = useState(false);
  const [chipsInfo, setChipsInfo] = useState([]);
  const [chipsModalVisible, setChipsModalVisible] = useState(false);
  const [chipsAmount, setChipsAmount] = useState(null);
  const [type, setType] = useState("chips");
  const [playerInfo, setPlayerInfo] = useState([]);
  const [defaultValue, setDefaultValue] = useState('GENERATE REFERRAL');
  const [sortByDefaultValue, setSortByDefaultValue] = useState("ALL LEVELS");
  const [totalBetLost, setTotalBetLost] = useState('');
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const generateOptions = (roleId) => {
      let generatedOptions = [];
      if (roleId === 1) {
        generatedOptions = [
            { value: '1', label: 'ADMIN' },
            { value: '2', label: 'HOUSE' },
            { value: '3', label: 'SUPER AGENT' },
            { value: '4', label: 'AGENT' },
            { value: '5', label: 'PLAYER' },
        ];
      } else if (roleId === 2) {
        generatedOptions = [
            { value: '3', label: 'SUPER AGENT' }, 
            { value: '4', label: 'AGENT' },
            { value: '5', label: 'PLAYER' },
        ];
      } else if (roleId === 3) {
        generatedOptions = [
            { value: '4', label: 'AGENT' },
            { value: '5', label: 'PLAYER' },
        ];
      } else if (roleId === 4) {
        generatedOptions = [
            { value: '5', label: 'PLAYER' },
        ];
      }

      return generatedOptions;
    };

    const updatedOptions = generateOptions(role_id);
    setOptions(updatedOptions);
  }, [role_id]);

  const filteredOptions = options
  .filter(option => option.value !== "5")
  .concat({ label: "ALL LEVELS", value: "all" });

  const filteredTeamManagement = managedUsers && managedUsers.length > 0
  ? managedUsers.filter((item) => {
      if (sortByDefaultValue === "ALL LEVELS" || sortByDefaultValue === "all" || sortByDefaultValue === null) {
        return true;
      }

      return sortByDefaultValue ? item.role_id.toString() === sortByDefaultValue : false;
    })
  : [];

  useEffect(() => {
    if(searchMode && fromDate && toDate){
      handleSearchEnter();
    }else if(searchMode){
      handleSearchEnter();
    }
  }, [fromDate, toDate, searchMode]);

  useEffect(() => {
    
    const fetchData = async () => {

      const response = await getUserInfo(token);
      
      if (response.user_id && response.role_id) {

        if(searchMode){
          return;
        }

        setUserId(response.user_id);
        setRoleId(response.role_id);
        
        let usersData; // Use 'let' to declare the variable
        
        if (fromDate && toDate) {
            usersData = await getManagedUsers(response.role_id, response.user_id, fromDate, toDate);
        } else {
            usersData = await getManagedUsers(response.role_id, response.user_id);
        }
        if (usersData) {
            setManagedUsers(usersData.users);
            setManagedPlayers(usersData.players);
            if(usersData.players){
              const totalGGR = usersData.players.reduce((sum, player) => sum + parseFloat(player.commission_gained || '0'), 0);
              setTotalBetLost(totalGGR);
            }
            // setTotalBetLost(totalBetLost);
        }
      }else if(response.timed_out || response.request_failed){
        floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
      }else if(response.error){
        showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
        async () => {
          dispatch(logout());
        });  
      }
      setTableLoading(false);
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => {
      clearInterval(interval);
    };
      
  }, [user_id, role_id, searchMode, fromDate, toDate, dispatch, message, token]);

  const generateRef = async (generated) => {
    if (role_id && user_id && generated) {
        const referralData = await generateReferral(role_id, user_id, generated);
        setReferralData(referralData);
    }
  };

  const handleOpenDialog = async (record) => {
    if(record.role_id === 5){
      return;
    }
    const remaining = await getRemainingPercentage(record.username);
    if(remaining){
      setAgentPercentage(null);
      setRemainingPercentage(remaining)
      setSelectedRecord(record);
      setVisible(true);
    }else{
      console.log("error");
    }
  };

  const handleCloseDialog = () => {
    setSelectedRecord(null);
    setVisible(false);
  };
  
  const handleConfirm = async (username, percentage) => {
    if(!agentPercentage){
      return;
    }
    if(username && percentage){
      if(await modifyPercentage(username, percentage)){
        setAgentPercentage("");
        handleCloseDialog();
      }
    }
  };

  const handleAgentPercentageChange = (e) => {
    let value;
    if (e.type === "paste") {
      // Handle clipboard paste event
      const clipboardData = e.clipboardData || window.clipboardData;
      value = parseFloat(clipboardData.getData("text/plain"));
    } else {
      // Handle input event
      value = parseFloat(e.target.value);
    }
    
    const maxValue = remaingPercentage.total_percentage; // Set the maximum value
  
    if (isNaN(value)) {
      value = ""; // Clear the input if the value is not a valid number
    } else {
      // Enforce the maximum value
      value = Math.min(value, maxValue);
    }
  
    setAgentPercentage(value);
  };

  const handleRowExpand = async (expanded, record) => {
    
    let newExpandedRowKeys = [];

    if (expanded) {
      newExpandedRowKeys = [record.user_id];
      setExpandedRowKeys([]); 
      setExpandedRowKeys2([]); 
    }
  
    setExpandedRowKeys(newExpandedRowKeys);
  
    if (((searchMode && (hasPlayers === false && hasUsers === false)) || !searchMode) && expanded) {
      await getUsers(record.role_id, record.user_id, setManagedUsers2, setManagedPlayers2);
    }

    let cardTitle = "";
    if (record.role_id < 4) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle1(cardTitle);
  };
  
  const handleSecondRowExpand = async (expanded, record) => {
    setExpandedRowKeys2(expanded ? [record.user_id] : []);
  
    if (((searchMode && (hasPlayers2 === false)) || !searchMode) && expanded) {
      await getUsers(record.role_id, record.user_id, null, setManagedPlayers3);
    }

    let cardTitle = "";
    if (record.role_id < 4) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle2(cardTitle);
  };

  const getUsers = async (role_id, user_id, setManagedUsers, setManagedPlayers) => {
    if (user_id && role_id) {
      if(setManagedUsers != null){
        setManagedUsers(null); 
      }
      
      setManagedPlayers(null);
      
      let usersData; // Use 'let' to declare the variable
          
      if (fromDate && toDate) {
          usersData = await getManagedUsers(role_id, user_id, fromDate, toDate);
      } else {
          usersData = await getManagedUsers(role_id, user_id);
      }
      
      if (usersData) {
        if(setManagedUsers != null){
          setManagedUsers(usersData.users);
        }
        setManagedPlayers(usersData.players)
      }
    }
  };

  const handleSearchEnter = async () => {
    
    const username = searchInput.trim().toLowerCase(); 
    if(username !== ''){
      setTableLoading(true);
      setSearchMode(true);
      setManagedUsers(null);
      setManagedUsers2(null);
      setManagedPlayers(null);
      setManagedPlayers2(null);
      setManagedPlayers3(null);
      setHasPlayers(false);
      setHasPlayers2(false);
      setHasUsers(false);

      const response = await getUserInfo(token);
      if (response && response.user_id) {

        let upline; 

        if (fromDate && toDate) {
          upline = await getUpline(username, response.user_id, fromDate, toDate);
        } else {
          upline = await getUpline(username, response.user_id);
        }
        //console.log(upline);
        
        if(upline && upline.current_user){
          const currentUser = upline.current_user[0];

          if (upline.upline.length === 0) {
            if(currentUser.role_id === 5){
              setManagedPlayers(upline.current_user);
            }else{
              setManagedUsers(upline.current_user)
            }
          }else if (upline.upline.length === 1) {
            if(currentUser.role_id === 5){
              setManagedUsers(upline.upline);
              setManagedPlayers2(upline.current_user);
              setHasPlayers(true);
            }else if(currentUser.role_id === 4){
              setManagedUsers(upline.upline);
              setManagedUsers2(upline.current_user); 
              setHasUsers(true);
            }
          }else if (upline.upline.length === 2) {
            setManagedUsers([upline.upline[0]]);
            setManagedUsers2([upline.upline[1]]);
            setManagedPlayers3(upline.current_user);
            setHasUsers(true);
            setHasPlayers2(true);
          }
        }
      }
    }
    setTableLoading(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value); 
    if(event.target.value === ''){
      setSearchMode(false);
    } 
  };

  const handleClearSearch = () => {
    setSearchMode(false);
    setSearchInput('');
  };

  const handleSwitchChange = (checked) => {
    setShowCommission(checked);
  };

  const handleDateRangeChange = (dates) => {
    setTableLoading(true);
    if (dates && dates.length > 0) {
      const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');

      setFromDate(startDate);
      setToDate(endDate);
    }else{
      setFromDate(null);
      setToDate(null);
    }
  };

  const handleOpenChipsDialog = async (record, type) => {
    if(record.roleId === 5){
      return;
    }
    
    setChipsInfo([]);
    setChipsAmount(null);

    setSelectedRecord(record);
    setChipsModal(true);

    if(type === "chips"){
      setChipsModalTitle("Chips");
    }else{
      setChipsModalTitle("Promotional Chips");
    }

    const chips = await getChipsInfo(record.user_id);

    if(chips){
      setChipsInfo(chips);
    }else{
      setChipsModal(false);
    }
  };

  const handleConfirmChips = async (selectedRecord) => {
    if (selectedRecord && selectedRecord.user_id && chipsAmount) {
      let type = chipsModalTitle === "Chips" ? "chips" : "promotional";

      const messages = ["Account Name : " + selectedRecord.username, "Amount : " + chipsAmount];

      showConstrainedMessages(message,`Add ${type} Confirmation`, messages, "PROCEED", 2, async () => {
        setAddChipsLoading(true);
  
        const response = await getUserInfo(token);

        if (response) {

          const result = await addChips(response.user_id, selectedRecord.user_id, chipsAmount, type);
  
          if(result.success){
            setChipsAmount(""); // Clear the input field
            handleCloseChips(); // Close the chips modal
            showTimedMessage(message, "Success", `${type === "chips" ? "Chips" : "Promotional Chips"} Added Successfully`, "OK", 5, true);
          }else{
            showTimedMessage(message, "Failed", result.message, "OK", 5, false);
          }

          
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
        }

        setAddChipsLoading(false);
  
      });
    }
  };

  const handleChipsAmount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ""); 
    setChipsAmount(value);
  };

  const handleAddChips = async (record) => {
    setType("Chips");
    if(record && record.user_id && record.role_id === 5){
      setChipsModalVisible(true);
      const playerData = await getUserBalance(record.username, 'PHP')
      if(playerData){
        setPlayerInfo(playerData);
        setSelectedRecord(record);
      }else{
        showTimedMessage(message, "Failed to retrieve balance", "An error occured", "OK", 5, false);
      }
    }
  }

  const handleAddBonusChips = async (record) => {
    setType("Bonus Chips");
    if(record && record.user_id && record.role_id === 5){
      setChipsModalVisible(true);
      const playerData = await getUserBalance(record.username, 'PHP')
      if(playerData){
        setPlayerInfo(playerData);
        setSelectedRecord(record);
      }else{
        showTimedMessage(message, "Failed to retrieve balance", "An error occured", "OK", 5, false);
      }
    }
  }

  const handleAddChipsConfirm = async () => {
    if(!chipsAmount || chipsAmount <= 0){
      return; 
    }

    if (selectedRecord && chipsAmount) {
      const messages = ["Account Name : " + selectedRecord.username, "Amount : " + chipsAmount];
     
      showConstrainedMessages(message,`Add ${type} Confirmation`, messages, "PROCEED", 2, async () => {
  
        const response = await getUserInfo(token);
        
        if (response && response.user_id) {
  
          if(type === "Chips"){
            const result = await addPlayerChips(response.user_id, selectedRecord.user_id, chipsAmount);
            if(result.success){
              setChipsAmount(""); 
              handleAddChipsClose(); 
              showTimedMessage(message, "Success", "Chips added successfully.", "OK", 5, true);
            }else{
              showTimedMessage(message, "Failed", result.message, "OK", 5, false);
            }
          }else{
            const result = await addPlayerBonusChips(response.user_id, selectedRecord.user_id, chipsAmount);
            if(result.success){
              setChipsAmount(""); 
              handleAddChipsClose(); 
              showTimedMessage(message, "Success", "Bonus chips added successfully.", "OK", 5, true);
            }else{
              showTimedMessage(message, "Failed", result.message, "OK", 5, false);
            }
          }

          
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later.", "OK", 5, false);
        }
  
      });
    }
  }

  const handleAddChipsClose = async (record) => {
    setChipsModalVisible(false);
    setPlayerInfo([]);
    setChipsAmount(null);
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "%",
      width: 150,
      dataIndex: "agent_percentage",
      key: "agent_percentage",
      render: (text, record) => (
        <div className="flex justify-between items-center">
          <span>{text === "0%" ? "-" : text}</span>
          <Button onClick={() => handleOpenDialog(record)}>Modify</Button>
        </div>
      ),
    },
    {
      title: "Total GGR",
      dataIndex: "total_ggr", 
      key: "total_ggr",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatAsMoney(text)}
          </span>
        );
      }
    },
    {
      title: "Commission Gained",
      dataIndex: "commission_gained", 
      key: "commission_gained",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatAsMoney(text)}
          </span>
        );
      }
    },
    role_id < 3 && {
      title: "",
        key: "accept_request",
        align: "center",
        width: "150px",
        render: (text, record) => {
          return (
            <div style={{ width: "120px", textAlign: "center", margin: "0 auto" }}>
              <Button 
                type="primary" 
                className="mb-2" 
                style={{ minWidth: "120px", backgroundColor: "#FFC839" }}
                onClick={() => handleOpenChipsDialog(record, "chips")}
              >
                Chips
              </Button>
  
              <Button 
                style={{ minWidth: "120px" }}
                onClick={() => handleOpenChipsDialog(record, "promotional")}
              >
                Promo Chips
              </Button>
            </div>
          );
        },
    }
  ].filter(Boolean);

  const personalDetailColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (record) => (record ? record : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
  ];
 
  const playerColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Total GGR",
      dataIndex: "commission_gained",
      key: "commission_gained",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatAsMoney(text)}
          </span>
        );
      }  
    },
    role_id < 3 && {
      title: "Actions",
      key: "actions",
      align: "center",
      width: "150px",
      render: (text, record) => (
        <div style={{ width: "130px", textAlign: "center", margin: "0 auto" }}>
          <Button
            type="primary"
            className="mb-2" 
            onClick={() => handleAddChips(record)}
            style={{ backgroundColor: "#305EDB" }} // Add margin to the right
          >
            Add Chips
          </Button>
          <Button
            type="primary"
            onClick={() => handleAddBonusChips(record)}
            style={{ backgroundColor: "#9C2552" }} // Add margin to the right
          >
            Add Bonus
          </Button>
        </div>
      ),
    },
  ].filter(Boolean);

  const playerDetailColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    { 
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (record) => (record ? record : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
  ];
  
  const handleCloseChips = () => {
    setChipsModal(false);
    setSelectedRecord(null);
  };

  const handleGenerateReferral = (value, option) => {
    setReferralData(null);
    generateRef(value);
    setDefaultValue(value);
  };

  const handleSelectSortBy = (value, option) => {
    setSortByDefaultValue(value);
  };

  return (
    <div className="bg-primary h-full w-full p-5">
      <Modal
        title="Modify Commission Percentage"
        open={visible}
        onCancel={handleCloseDialog} 
        footer={null}
        onOpen={() => {
          setAgentPercentage(""); // Clear the input field on open
        }}
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <span>Distributable:</span>
              <span>Given:</span>
              <span>Remaining:</span>
              <span>Username:</span>
            </div>
            <div className="flex flex-col gap-2 items-end justify-end">
              <span>{remaingPercentage && remaingPercentage.total_percentage + "%"}</span>
              <span>{selectedRecord && selectedRecord.agent_percentage}</span>
              <span className="font-bold shadowed-text glowing-text" >{remaingPercentage && remaingPercentage.remaining_percentage + "%"}</span>
              <span className="font-semibold">{selectedRecord && selectedRecord.username}</span>
            </div>
          </div>
          <Divider className="my-[5px]"/>
          <div className="flex flex-col gap-1">
            <label>Percentage: </label>
            <Input
              className="h-[40px]"
              placeholder="Enter % to give"
              onChange={handleAgentPercentageChange}
              value={agentPercentage}
            />
          </div>
          <div className="flex justify-end">
            <Button type="primary" style={{ backgroundColor: "black" }} onClick={() => handleConfirm(selectedRecord && selectedRecord.username, agentPercentage)}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={`Give ${chipsModalTitle}`}
        open={chipsModal}
        onCancel={handleCloseChips}
        footer={null}
        onOpen={() => {
          setChipsAmount(""); // Clear the input field on open
        }}
      >
        <div className="flex flex-col">
          {addChipsLoading ? (
            <div className="flex items-center justify-center w-full" style={{ minHeight: '200px', minWidth: '200px'}}>
              <Spin />
            </div>
          ) : (
            <>
              {chipsModalTitle === "Chips" ? (
                <>
                  <label className="pb-2">Given {chipsModalTitle}: {chipsInfo && formatAsMoney(chipsInfo.total_chips)}</label>
                  <label className="pb-2">Remaining {chipsModalTitle}: {chipsInfo && formatAsMoney(chipsInfo.remaining_chips)}</label>
                </>
              ) : (
                <>
                  <label className="pb-2">Given {chipsModalTitle}: {chipsInfo && formatAsMoney(chipsInfo.total_promo)}</label>
                  <label className="pb-2">Remaining {chipsModalTitle}: {chipsInfo && formatAsMoney(chipsInfo.remaining_promo)}</label>
                </>
              )}
              <Input
                className="py-3 mb-2"
                onChange={handleChipsAmount}
                value={chipsAmount}
              />
              <div className="flex justify-end">
                <Button 
                  type="primary" 
                  style={{ backgroundColor: "black" }} 
                  onClick={() => handleConfirmChips(selectedRecord)}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal
        title={`Add ${type}`}
        open={chipsModalVisible}
        onCancel={handleAddChipsClose}
        footer={null}
        onOpen={() => {
          setChipsAmount(""); // Clear the input field on open
        }}
      >
        <div className="flex flex-col">
          {playerInfo.username ? (
            <div className="flex justify-between items-center">
              <span className="pb-2">Username:</span>
              <span>{playerInfo?.username}</span>
            </div>
          ) : (
            <label className="pb-2">
                Username: loading...
            </label>
          )}
          {playerInfo.bonus || playerInfo.amount ? (
            <div className="flex justify-between items-center">
              <span className="pb-2">
                {type === "Chips" ? 'Remaining Chips' : 'Bonus Chips'}: 
              </span>
              <span>
                {parseFloat((type === "Chips" ? playerInfo?.amount : playerInfo?.bonus)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          ) : (
            <label className="pb-2">
              {type === "Chips" ? 'Remaining Chips' : 'Bonus Chips'}: loading...
            </label>
          )}
          <Divider className="my-[5px]"/>
          <div className="flex flex-col gap-1">
            <span>Add {type} Amount:</span>
            <Input
              className="py-3 mb-2"
              placeholder="Enter Amount"
              onChange={handleChipsAmount}
              value={chipsAmount}
            />
          </div>
          <div className="flex justify-end">
            <Button 
              type="primary" 
              style={{ backgroundColor: "black" }} 
              onClick={() => handleAddChipsConfirm(selectedRecord)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Card
        title={
          <div className="flex items-center gap-2">
            <span>Team Management</span>
            <Switch 
              className="details" 
              checkedChildren="Commissions" 
              unCheckedChildren="Personal Details" 
              defaultChecked 
              onChange={handleSwitchChange}/>   
          </div>
        }
        className="mb-4"
      >
        {messageHolder}

        <div className="flex flex-col lg:flex-row lg:justify-between w-full mb-3 gap-4 lg:items-center">
          <div className="flex flex-col lg:flex-row gap-4">

            <Input.Search
              id="searchInput"
              size="large"
              enterButton={
                <button
                  className="border-none h-[40px] px-4 text-white rounded-lg bg-black text-sm font-semibold cursor-pointer hover:bg-[#2f2936] transition-all ease-in duration-150"
                  style={{ color: "white", borderRadius: "0 5px 5px 0" }}
                  onClick={handleSearchEnter}
                >
                  <SearchOutlined style={{ color: "white" }} />
                </button>
              }
              suffix={
                searchMode ? (
                  <button
                    className="border-none h-[20px] w-[20px] rounded-full bg-red-500 text-white cursor-pointer ml-2 flex items-center justify-center"
                    style={{ color: "white" }}
                    onClick={handleClearSearch}
                  >
                    <CloseOutlined style={{ fontSize: "10px" }} />
                  </button>
                ) : null
              }
              onChange={handleSearchInputChange}
              value={searchInput}
              onPressEnter={handleSearchEnter}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  handleClearSearch();
                }
              }}
            />

            <DatePicker.RangePicker
              className="lg:min-w-[250px] h-[40px]"
              format="YYYY-MM-DD HH:mm:ss a"
              showTime
              use12Hours
              placeholder={['Date From', 'Date To']}
              onChange={handleDateRangeChange}
            />

            <Select
              size="large"
              className="w-full"
              value={sortByDefaultValue}
              placeholder="Filter"
              options={filteredOptions}
              onSelect={handleSelectSortBy}
            />

          </div>
          <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
            <div className="flex justify-center items-center w-full gap-4">

              {referralData ? 
                <Tooltip title="Clear referral field">
                  <CloseCircleFilled className="text-[25px] cursor-pointer" onClick={() => {setReferralData(''); setDefaultValue('GENERATE REFERRAL')}}/>
                </Tooltip>
              : null}

              <Select
                size="large"
                className="w-full"
                value={defaultValue}
                options={options}
                onSelect={handleGenerateReferral}
              />

            </div>

            <Input
              id="referralInput"
              className="lg:min-w-[200px]"
              size="large"
              value={referralData ? `${moneyWinConfig.ENVIRONMENT === "TEST" ? "dev.moneywin.bet" : "moneywin.bet"}/signup?ref=${referralData.referralCode}` : ''}
            />
            
          </div>
        </div>
        <div className="p-1  rounded-lg">
        <Table
          bordered
          scroll={{
            x: 1000,
            y: "100%",
          }}
          dataSource={
            role_id !== 4
              ? sortByDefaultValue
                ? filteredTeamManagement
                : managedUsers
              : managedPlayers
          }
          loading={tableLoading}
          className={`min-h-[230px] shadow-md rounded-md`}
          columns={role_id === 4 ? (showCommission ? playerColumns : playerDetailColumns) : (showCommission ? columns : personalDetailColumns)}
          rowKey="user_id"
          expandable={role_id === 4 ? null : {
            expandedRowKeys,
            onExpand: handleRowExpand,
            expandedRowRender: (record) => (
              <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#305EDB] rounded-lg">
                <span className="text-xl font-semibold text-[#305EDB]">{record.username}</span>
                <Card title={cardTitle1}>
                  <Table
                    bordered
                    dataSource={record.role_id !== 4 ? managedUsers2 : managedPlayers2}
                    className={`mb-4 min-h-[230px]`}
                    loading={tableLoading}
                    columns={record.role_id === 4 ?  (showCommission ? playerColumns : playerDetailColumns) : (showCommission ? columns : personalDetailColumns)}
                    rowKey="user_id"
                    expandable={record.role_id === 4 ? null : {
                      expandedRowKeys: expandedRowKeys2,
                      onExpand: handleSecondRowExpand,
                      expandedRowRender: (record) => (
                        <div className="flex flex-col gap-4 p-5 border-solid border-[#9C2552] border-2 rounded-lg">
                          <span className="text-xl font-semibold text-[#9C2552]">{record.username}</span>
                          <Card title={cardTitle2}>
                            <Table
                              bordered
                              className={`min-h-[230px]`}
                              loading={tableLoading}
                              dataSource={managedPlayers3}
                              columns={showCommission ? playerColumns : playerDetailColumns}
                              rowKey="user_id"
                              expandable={null}
                            />
                          </Card>
                        </div>
                      ), 
                    }} 
                  />
                </Card>

                {record.role_id !== 4 && managedPlayers2?.length > 0 ? (
                    <Card title="Players">
                      <Table
                        bordered
                        scroll={{
                          x: 1000,
                          y: 600,
                        }}
                        className={`min-h-[230px]`}
                        loading={tableLoading}
                        dataSource={managedPlayers2}
                        columns={showCommission ? playerColumns : playerDetailColumns}
                        rowKey="user_id"
                      />
                    </Card>
                  ) : null}
                  
              </div>
            ),
          }} 
        />
        </div>
      </Card>

      {role_id < 4 && managedPlayers?.length > 0 ? (
        <Card title={
          <div className="flex justify-between items-center">
            <span>Players</span>
            <span>Total GGR: {formatAsMoney(totalBetLost)}</span>
          </div>
        }>
          <Table
            bordered
            scroll={{
              x: 1000,
              y: 600,
            }}
            loading={tableLoading}
            className={`min-h-[230px] shadow-md rounded-md`}
            dataSource={managedPlayers}
            columns={showCommission ? playerColumns : playerDetailColumns}
            rowKey="user_id"
          />
        </Card>
      ) : null}

    </div>
  );
};

export default TeamManagement;
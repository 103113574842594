const serviceSID = 'VA9ea1de48002afb660457f5f78820d9a5';
const accSID = 'AC6d51a3847f54f01a58c6bce656ae2fda';
const authToken = 'e25f14b3784df25ba9664269dffed77b';

export const twilioSendOTP = async (toPhoneNumber) => {    
  const url = `https://verify.twilio.com/v2/Services/${serviceSID}/Verifications`;

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Basic ${btoa(`${accSID}:${authToken}`)}`,
  });

  const body = `To=${encodeURIComponent(toPhoneNumber)}&Channel=sms`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body.toString(),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Twilio API Error:', errorResponse);
      throw new Error('Error sending OTP.');
    }

    const verification = await response.json();
    console.log('OTP sent:', verification.status);
    //console.log('xx', verification);
    //console.log(verification.send_code_attempts.length);
    //setVerificationStatus(verification.status);
    return { verificationStatus: verification.status, attempts: verification.send_code_attempts.length };
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw new Error('Error sending OTP.');
  }
};
  

export const twilioVerifyOTP = async (toPhoneNumber, code) => {

  const url = `https://verify.twilio.com/v2/Services/${serviceSID}/VerificationCheck`;

  const headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${btoa(`${accSID}:${authToken}`)}`,
  });

  const body = `To=${encodeURIComponent(toPhoneNumber)}&Code=${encodeURIComponent(code)}`;

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: body.toString(),
      });

      if (!response.ok) {
          const errorResponse = await response.json();
          //console.error('Twilio API Error:', errorResponse);
          throw new Error('Error verifying OTP.');
      }

      const verificationCheck = await response.json();
      console.log('OTP Verification result:', verificationCheck.status);
      // console.log('Number of attempts:', verificationCheck); // Display the number of attempts
      return {
        verificationCheckStatus: verificationCheck.status,
        attempts: verificationCheck,
        sid: verificationCheck.sid
      };
  } catch (error) {
      console.error('Error verifying OTP:', error);
      throw new Error('Error verifying OTP.');
  }
};
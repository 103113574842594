import React, { useState, useEffect, useRef } from 'react'
import {Modal, Form, Image, Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import mwLogo from "../../../assets/resources/logos/mw-logo-signup.png";
import { useNavigate, useParams} from "react-router-dom";
import { twilioSendOTP, twilioVerifyOTP } from '../../../services/twilio/twilio-api';
import { AES, enc } from 'crypto-js';
import { updateMobileVerification } from '../../../services/moneywin/moneywin-api';

const MobileVerification = (toggleVerificationModal, mobileNumber) => {
    const navigate = useNavigate();
    const { mobile_number } = useParams();
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [verificationCode, setVerificationCode] = useState([]);
    const [setRandomNumber] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const inputRefs = useRef([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLoading] = useState(false);
    const [maxSendAttempts, setMaxSendAtttempts] = useState(0);
    const [maxSendMessage, setMaxSendMessage] = useState('');
    const [verifyAttempt, setVerifyAttempt] = useState(0);
    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    useEffect(() => {
        const randomCode = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join('');
        setRandomNumber(randomCode.split(''));
    }, []);

    const handleInputChange = (value, index) => {
        const numericValue = value.replace(/[^0-9]/g, '');
    
        const newCode = [...verificationCode];
        newCode[index] = numericValue;
    
        if (!numericValue && index > 0) {
            inputRefs.current[index - 1].focus(); // Focus on the previous input
        } else if (numericValue && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    
        setVerificationCode(newCode);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        if(mobileNumber){
            toggleVerificationModal(false);
        }else{
            navigate('/')
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if(mobileNumber){
            toggleVerificationModal(false);
        }else{
            navigate('/')
        }
    };

    const handleVerify = async () => {
        const decrypted = AES.decrypt(mobileNumber ? mobileNumber : mobile_number, process.env.REACT_APP_ENCRYPT_KEY).toString(enc.Utf8);
        if(decrypted)
        {
            // console.log(decrypted);
            //console.log('User number: ', formatMobileNumber(decrypted));
            setIsLoading(true);
            if(verifyAttempt === 5) 
            {
                setMaxSendMessage("You have reached the maximum number of verify attempts. Please wait for a while before trying again.");
                return;
            }
            else
            {
                // const toPhoneNumber = '+639761808302';
                const result = await twilioVerifyOTP(decrypted, verificationCode.join(''))
                .then(result => {
                    if(result.verificationCheckStatus === 'approved')
                    {
                        updateMobileVerification(formatMobileNumber(decrypted));
                        setErrorMessage('');
                        setIsModalOpen(true);
                        setVerifyAttempt(0);
                    }
                    else if (result.verificationCheckStatus.toLowerCase() === 'expired')
                    {
                        setErrorMessage('The OTP code has expired. Please request a new one.');
                    }
                    else
                    {
                        setErrorMessage('Invalid OTP. Please try again.');
                        setVerifyAttempt(verifyAttempt + 1);
                    }                
                })
                .catch(error => {
                    setErrorMessage('An error occurred while verifying OTP.');
                    console.error(error);
                });
            }
            setIsLoading(false);
        }
        else
        {
            if(mobileNumber){
                toggleVerificationModal(false);
            }else{
                navigate('/')
            }
        }
    };

    const handleResendOTP = () => {

        const decrypted = AES.decrypt(mobileNumber ? mobileNumber : mobile_number, process.env.REACT_APP_ENCRYPT_KEY).toString(enc.Utf8);
        if(decrypted)
        {
            if(maxSendAttempts === 5) 
            {
                setMaxSendMessage("You have reached the maximum number of allowed OTP send attempts. Please wait for a while before trying again.");
                return;
            }
            else
            {
                // const toPhoneNumber = '+639761808302';
                twilioSendOTP(decrypted)
                .then(result => {
                    setMaxSendAtttempts(result.attempts);
                //navigate("/verification")
                })
                .catch(error => console.error(error));
    
                setIsResendDisabled(true);
                setRemainingTime(120); 
        
                const cooldownExpiry = Date.now() + 2 * 60 * 1000;
                localStorage.setItem('resendCooldown', cooldownExpiry);
        
                setTimeout(() => {
                    setIsResendDisabled(false);
                    setRemainingTime(0);
                    localStorage.removeItem('resendCooldown');
                }, 2 * 60 * 1000);
            }
        }
        else
        {
            if(mobileNumber){
                toggleVerificationModal(false);
            }else{
                navigate('/')
            }
        }
        
    };

    useEffect(() => {
        const storedCooldown = localStorage.getItem('resendCooldown');
        if (storedCooldown) {
            const currentTime = Date.now();
            const cooldownExpiry = parseInt(storedCooldown);

            if (cooldownExpiry > currentTime) {
                const remainingCooldown = Math.ceil((cooldownExpiry - currentTime) / 1000);
                setIsResendDisabled(true);
                setRemainingTime(remainingCooldown);
            } else {
                localStorage.removeItem('resendCooldown');
                setIsResendDisabled(false);
            }
        }
    }, []);

    useEffect(() => {
        let countdownInterval;

        if (remainingTime > 0 && isResendDisabled) {
            countdownInterval = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);
        } else {
            clearInterval(countdownInterval);
        }

        return () => {
            clearInterval(countdownInterval);
        };
    }, [remainingTime, isResendDisabled]);

    const formatTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const formatMobileNumber = (number) => {
        if (number.startsWith("+63")) {
            return "0" + number.slice(3);
        }
        return number;
    };
  return (
    <div className="flex flex-col justify-between h-full w-full max-w-[508px] md:justify-center">
        <div className="bg-primary md:bg-white rounded-[10px] border-0 text-white relative md:p-10 h-auto">
            <div className="flex flex-col items-center pr-5 pl-5 gap-4 w-full md:pt-12 h-full">
                {/* <div className="w-[198px] md:w-[341px] md:absolute md:top-[-190px]">
                    <Image width={"100%"} preview={false} src={loginLogo} />
                </div> */}
                <div className="md:w-[341px] md:absolute mx-auto flex items-center justify-center md:text-center md:top-[-90px]">
                    <Image width={"100%"} preview={false} src={mwLogo} style={{ objectFit: 'fill', height: 165, width: 250,}}/>
                </div>
                <span className="font-semibold text-[20px] text-white md:text-[28px] md:text-textDarkGray pt-6">
                Verification
                </span>
                <span className='text-white md:text-textDarkGray'>
                    You will get an OTP via SMS
                </span>

                <Form
                className="w-full mt-[50px]"
                layout="vertical"
                name="basic"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                >
                    <Form.Item
                        className="login-input"
                        label={
                        <span className="label-style md:text-black">Verification Code</span>
                        }
                        name="verification"
                        rules={[
                        {
                            required: true,
                            message: "Please enter a verification code!",
                        },
                        ]}
                        validateStatus=""
                    >
                        <div className="flex h-[56px]">
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <Input
                                key={index}
                                className="mx-1 md:mx-2 text-center shadow-md font-bold text-[16px]"
                                maxLength={1}
                                value={verificationCode[index]}
                                placeholder="#"
                                onChange={(e) => handleInputChange(e.target.value, index)}
                                ref={(input) => (inputRefs.current[index] = input)}
                                />
                            ))}
                        </div>
                    </Form.Item>

                    {errorMessage && (
                        <div className="text-red-500 text-center my-2 absolute">
                            {errorMessage}
                        </div>
                    )}
                    <div className="w-full flex items-center justify-center">
                        <button
                            type="primary"
                            htmltype="submit"
                            className="w-full h-[55px] text-[22px] font-semibold hover:bg-yellow-600 bg-[#FFCE29] shadowed gradient-button duration-300 mt-[50px] cursor-pointer text-white border-0 rounded-lg md:max-w-[285px]"
                            disabled=""
                            onClick={handleVerify}
                        >
                            {isLoading ? (
                                <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                                />
                            ) : (
                                <span className="outlined-text text-2xl">Verify</span>
                            )}
                        </button>
                    </div>
                </Form>

                <Modal 
                    className='md:min-w-[600px]'
                    title={maxSendMessage ? "Error" : "Account Verified"}
                    open={isModalOpen} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    confirmLoading={isLoading}
                    footer={isModalLoading ? null : undefined}
                >
                    {isModalLoading ? (
                        <div className="flex items-center justify-center p-4 h-300">
                            <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000', strokeWidth: 2 }} spin />}
                            />
                        </div>
                    ) : (
                        <>
                            {maxSendMessage ? (
                                <div className="text-red-500 text-center mb-4 m-[2vw]">
                                    {maxSendMessage}
                                </div>
                            ) : (
                                <div className='flex flex-col justify-center items-center my-[30px]'>
                                    <span>Your account is verified! You can now log in.</span>
                                </div>
                            )}
                        </>
                    )}
                </Modal>
                
                <div className="flex mt-[20px] justify-center flex-col items-center">
                    <div>
                        <span className="text-white md:text-black">
                            Didn't receive OTP?&nbsp;
                            <span
                                className={`text-[#FCDB9C] cursor-pointer ${
                                    isResendDisabled ? 'opacity-50 pointer-events-none' : 'hover:underline'
                                }`}
                                onClick={handleResendOTP}
                            >
                                Resend again
                            </span>
                        </span>
                        {isResendDisabled && (
                            <span className="text-white md:text-gray-500 ml-2">
                                {`(${formatTime(remainingTime)})`}
                            </span>
                        )}
                    </div>
                    {/* {maxSendAttempts && (
                        <>
                            <span className="text-white md:text-gray-500 py-3">
                                {`OTP Send Attempts: ${maxSendAttempts} / 5`}
                            </span>
                        </>
                    )} */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default MobileVerification
import React, { useEffect, useState } from 'react'
import { CaretRightOutlined } from "@ant-design/icons";
import { gamesItems } from '../../../static/constants';
import { useLocation } from 'react-router-dom';
const GamesDescription = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const game = queryParams.get('game');
    if(game) {
      const gameInt = parseInt(game, 10);
      if (!isNaN(gameInt)) {
        setSelectedItem(gameInt);
      } else {
        console.error('Invalid game parameter:', game);
      }
    }
  }, [location.search]);
  return (
    <div id="accordionExample" className="flex flex-col  justify-center items-center pt-[100px] gap-10 w-full z-[99] text-white">
      <span className="text-white text-center text-[36px] font-semibold mb-5">
        Games
      </span>
      <div className="gap-3 flex flex-col p-5 w-full max-w-[900px] mx-auto">
        <div className="gap-3 flex flex-col w-full">
          {
            gamesItems.map((item, index) => (
              <div key={index}>
                <div
                  className={`flex items-center w-full justify-between ${
                    selectedItem === index ? 'bg-gradient-to-l from-[#92680C] to-[#FCDB9C] text-black' : ''
                    // selectedGame === item.title ? 'bg-gradient-to-l from-[#92680C] to-[#FCDB9C] text-black' : ''
                  }`}
                  onClick={() => setSelectedItem(selectedItem === index ? null : index)}
                // onClick={() => setSelectedItem(selectedGame === item.title ? null : index)}
                  style={{ 
                    border: "1px solid #FCDB9C",
                  }}
                >
                  <span className="m-5 text-[16px] font-semibold">{item.title}</span>
                  {/* {selectedGame === item.title ? ( */}
                  {selectedItem === index ? (
                    <CaretRightOutlined className="mx-5 text-[16px] border border-solid border-[#FCDB9C] text-white p-[6px] rounded-md" rotate={90} />
                  ) : (
                    <CaretRightOutlined className="mx-5 text-[16px] border border-solid border-[#FCDB9C] text-white p-[6px] rounded-md" />
                  )}
                </div>
                <div
                  id="collapseOne"
                  className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                    // selectedGame === item.title ? "max-h-96" : "max-h-0"
                    selectedItem === index ? "max-h-96" : "max-h-0"
                  }`}
                  data-te-collapse-item
                  data-te-collapse-show
                  aria-labelledby="headingOne"
                  data-te-parent="#accordionExample"
                  style={{
                    borderRight: "1px solid #FCDB9C",
                    borderLeft: "1px solid #FCDB9C",
                    borderBottom: "1px solid #FCDB9C",
                    background: "#12151D"
                  }}
                >
                  <div className="py-8 px-4 text-justify">
                    <span className="text-[14px] leading-relaxed">{item.label}</span>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default GamesDescription
import { Divider, Form, Image, Input, Spin, Modal, Button, message, Tag } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useState, useRef} from "react";
import React from "react";
import mwLogo from "../../../assets/resources/logos/mw-logo-signup.png";
import "./login.css";
import { useNavigate, useLocation} from "react-router-dom";
import { createUser } from "../../../services/moneywin/moneywin-api";
import { twilioSendOTP } from "../../../services/twilio/twilio-api";
import { AES } from 'crypto-js';
import { useEffect } from "react";


const SignUp = ({isModal, toggleRegModal, toggleVerificationModal, referral}) => {

  const navigate = useNavigate();
  const location = useLocation()
  const formRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [referralCode, setReferralCode] = useState(referral);
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setIsLoading(false);
  }, [toggleRegModal]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refParam = queryParams.get("ref");

    if (refParam !== "null" && refParam !== null) {
      setReferralCode(refParam);
    }

  }, [location]);

  const handleFieldError = (value, errorMessage) => {
    if (!value) {
      setIsLoading(false);
      message.error({ content: errorMessage, key: 'custom_message' });
      return true;
    }
    return false;
  };
  
  const onFinish = async () => {
    setIsLoading(true);
  
    if (
      handleFieldError(firstName, "Please enter your first name") ||
      handleFieldError(lastName, "Please enter your last name") ||
      handleFieldError(username, "Please enter a username") ||
      handleFieldError(!username.includes(" "), "Invalid username") ||
      handleFieldError(!username.length < 8, "Username must be at least 8 characters long.") ||
      handleFieldError(email, "Please enter an email") ||
      handleFieldError(email.match(emailRegex), "Please enter a valid email address") ||
      handleFieldError(mobileNumber, "Please enter a mobile number") ||
      handleFieldError(password, "Please enter a password") ||
      handleFieldError(!password.includes(" "), "Password must not contain whitespace") ||
      handleFieldError(!password.length < 8, "Password must be at least 8 characters long") ||
      handleFieldError(password === confirm_password, "Password does not match") ||
      handleFieldError(confirm_password, "Please re-type your password")
    ) {
      return;
    }
  
    message.loading({ content: 'Checking Details...', key: 'custom_message' });
  
    const create = await createUser(username, password, email, mobileNumber, firstName, lastName, referralCode);
    console.log(create);
    if (create.success) {
      message.success({ content: "Registered Successfully.", key: 'custom_message' });
      
      if (isModal) { toggleRegModal(false) } ;

      formRef.current.resetFields();
      setReferralCode(null);
      setUsername(null);
      setPassword(null);
      setConfirmPassword(null);
      setFirstName(null);
      setLastName(null);
      setMobileNumber(null);
      handleSendOTP();
    } else {
  
      if (create.username) {
        message.error({ content: create.username, key: 'custom_message' });
      } else if (create.email) {
        message.error({ content: create.email, key: 'custom_message' });
      } else if (create.mobile_number) {
        message.error({ content: create.mobile_number, key: 'custom_message' });
      } else if (create.first_name) {
        message.error({ content: create.first_name, key: 'custom_message' });
      } else if (create.last_name) {
        message.error({ content: create.last_name, key: 'custom_message' });
      } else if (create.referral) {
        message.error({ content: create.referral, key: 'custom_message' });
      } else if (create.message) {
        message.error({ content: create.message, key: 'custom_message' });
      }else{
        message.error({ content: "An error has occured. Please try again later", key: 'custom_message' });
      }
    }
  
    setIsLoading(false);
  };

  // xx
  const handleSendOTP = async () => {
    try {
      const format = formatMobileNumber(mobileNumber);
      const encrypted = AES.encrypt(format, process.env.REACT_APP_ENCRYPT_KEY).toString();
      await twilioSendOTP(format);
  
      if (isModal) {
        toggleVerificationModal(true);
      } else {
        navigate(`/verification/${encrypted}`);
      }
      
    } catch (error) {
      console.error(error);
      if (!isModal) {
        navigate("/");
      }
    } finally {
      setIsLoading(false);
    }
  };
  // xx
  
  const formatMobileNumber = (number) => {
    if (number.startsWith("0")) {
        return "+63" + number.slice(1);
    }
    return number;
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleTagClose = () => {
    setReferralCode(null);
  };

  return (
    <div className="flex flex-col justify-center items-center h-full w-full md:w-[600px] md:py-0">
      <div className="bg-primary h-full md:bg-white p-10 rounded-[10px] border-0 text-white relative md:p-10 md:h-[auto] w-full ">
        <Modal
          open={successModalVisible}
          onCancel={() => setSuccessModalVisible(false)}
          footer={[
            <Button
              key="login"
              className="modal-button justify-center" 
              onClick={() => {
                setSuccessModalVisible(false);
                navigate("/login");
              }}
            >
              Login
            </Button>,
          ]}
          wrapClassName="centered-modal-container"
          style={{ maxWidth: "300px" }}
        >
          <div className="centered-modal-content">
            <p>Registration successful! You can now log in.</p>
          </div>
        </Modal>

        <div className="flex flex-col items-center gap-4 w-full md:pt-10 h-full">
          {/* <div className="w-[198px] md:w-[341px] md:absolute text-center md:top-[-80px]"> */}
          <div className="md:w-[341px] md:absolute mx-auto flex items-center justify-center md:text-center md:top-[-90px]">
            <Image width={"100%"} preview={false} src={mwLogo} style={{ objectFit: 'fill', height: 165, width: 250,}}/>
          </div>
          
          <span className="font-semibold text-[18px] text-white md:mt-5 md:text-[28px] md:text-black">
            Create an Account
          </span>
          <span className="text-white md:text-black">All fields are required.</span>
          <Form
            ref={formRef}
            className="w-full mt-10"
            layout="vertical"
            name="basic"
            initialValues={{
              firstname: "",
              lastname: "",
              userName: "",
              Email: "",
              Password: "",
              confirmPassword: "",
              mobile: "",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-col md:grid md:grid-cols-2 gap-x-2 w-full">
            {/* <div className="grid grid-cols-2 gap-4 w-full"> */}

            <div className="w-full">
              <Form.Item
                className="login-input"
                // label={
                //   <span className="label-style text-lg md:text-black">
                //     First Name
                //   </span>
                // }
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name!",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000",
                  }}
                  tabIndex={1}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item
                className="login-input"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000",
                  }}
                  tabIndex={1}
                  onChange={(e) => {
                    setLastName(e.target.value);

                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full md:col-span-2">
              <Form.Item
                className="login-input"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Please enter a username!",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000",
                    gridColumn: "1", // This places the input in the first column
                  }}
                  tabIndex={3}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item
                className="login-input "
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000"
                  }}
                  tabIndex={4}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item
                className="login-input"
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number!",
                  },
                ]}
              >
                <Input
                  placeholder="Mobile Number"
                  size="large"
                  style={{
                    height: '46px',
                    padding: '10px',
                    borderRadius: '7px',
                    fontSize: '16px',
                    border: "1px solid #000"
                  }}
                  tabIndex={5}
                  onChange={(e) => {
                    setMobileNumber(e.target.value)
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item
                className="login-input "
                name="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000"
                  }}
                  tabIndex={6}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item
                className="login-input "
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please re-type your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  size="large"
                  style={{
                    height: "46px",
                    padding: "10px",
                    borderRadius: "7px",
                    fontSize: "16px",
                    border: "1px solid #000"
                  }}
                  tabIndex={7}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                  }}
                />
              </Form.Item>
            </div>
              
            </div>
            <div className="w-full flex flex-col items-center justify-center">

              { referralCode && referralCode !== "null" && (
                <Tag
                  closable
                  bordered={true}
                  color={isModal ? undefined : 'transparent'}
                  onClose={handleTagClose} 
                  className={`mt-2 ${isModal ? '' : 'text-[15px] p-2 mb-2 ant-tag-white'}`}
                  style={{ color: isModal ? 'black' : 'white' }}
                >
                  {"referral code: " + referralCode + "   "} 
                </Tag>
              )}

              <button
                type="primary"
                htmltype="submit"
                tabIndex={8}
                className="w-full h-[55px] text-[22px] font-semibold hover:bg-yellow-600 bg-[#FFCE29] shadowed gradient-button mt-4 duration-300 text-white border-0 rounded-lg md:max-w-[285px] cursor-pointer"
                disabled={isLoading}
                >
                {isLoading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                  />
                ) : (
                  <span className="outlined-text text-2xl">Sign Up</span>
                )}
              </button>
              
            </div>
          </Form>
          {/* <button
            type="primary"
            htmltype="submit"
            tabIndex={8}
            className="w-full h-[55px] text-[22px] font-semibold hover:bg-yellow-600 bg-buttonYellow mt-5 duration-300 text-white border-0 rounded-lg md:max-w-[285px] cursor-pointer"
            disabled={isLoading}
            onClick={handleSendOTP}
              
            >
            {isLoading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
              />
            ) : (
              "Test"
            )}
          </button> */}
          <div className="md:flex hidden">
            <span className="text-black">
              Already have an account?&nbsp;
              <span
                className="text-[#A47B24] hover:underline cursor-pointer"
                onClick={() => {
                  if (isModal) {
                    toggleRegModal(false);
                  } else {
                    window.scrollTo(0, 0); 
                    navigate("/login");
                  }
                }}
              >
                Sign In!
              </span>
            </span>
          </div>
          <div className="flex justify-evenly md:hidden pt-5">
            <span className="text-textGray tracking-wide">Already have an account?</span>
            <Divider type="vertical" className="bg-white mt-1" />
            <span
              tabIndex={9}
              className="text-[#FCDB9C] font-semibold cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0); 
                navigate("/login");
              }}
            >
              Sign In!
            </span>
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default SignUp;

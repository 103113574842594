import { Card, Table, DatePicker, Select, Modal, message as floatingMessage  } from "antd";
import React from "react";
import { useEffect , useState} from "react";
import { getTransactionRequests, getUserInfo} from "../../../services/moneywin/moneywin-api";
import moment from 'moment';
import { formatAsMoney, showTimedMessageWithAction, formatDate } from "../../../services/util/utilities";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const TransactionRecords = () => {

  const [dateRange, setDateRange] = useState([]);
  const [message, messageHolder] = Modal.useModal();
  const [transactionType, setTransactionType] = useState("cashin");
  const [transactionStatus, setTransactionStatus] = useState("pending");
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [transactionTotals, setTransactionTotals] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  
  useEffect(() => {
      const fetchData = async () => {
        const response = await getUserInfo(token);
      
        if (response.user_id && response.role_id) {
          const requests = await getTransactionRequests(response.user_id);

          if (requests && requests.transaction_requests && requests.transaction_requests.length > 0) {
            requests.transaction_requests.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTransactionRecords(requests.transaction_requests);
            setTransactionTotals(requests.type_totals);
          }

        }else if(response.timed_out || response.request_failed){
          floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
        }else if(response.error){
          showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
          async () => {
            dispatch(logout());
          });  
        }

        setTableLoading(false);
      };

      fetchData();

      const interval = setInterval(fetchData, 10000);
    
      return () => {
        clearInterval(interval);
      };

  },[dispatch, message, token]);

  const options = [
    { value: 'cashin', label: 'Manual Cash-In' },
    { value: 'cashout', label: 'Manual Cash-Out' } ,
    { value: 'bonus', label: 'Bonus' },
  ].filter(Boolean);

  const getTotalsByTypeAndStatus = (type, status) => {
    if (transactionTotals[type] && transactionTotals[type][status]) {
      return transactionTotals[type][status];
    }
    return { total_amount: 0, transaction_count: 0 }; // Default values if not found
  };

  const totals = getTotalsByTypeAndStatus(transactionType, transactionStatus);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length > 0) {
      setDateRange(dates); // Set the dates array directly
    } else {
      setDateRange([]);
    }
  };
  
  const filteredTransactionRecords = transactionRecords
    .filter((item) => {
      if (dateRange.length === 0) {
        return item.type === transactionType && item.status === transactionStatus;
      }
      const itemDate = moment(item.created_at, "YYYY-MM-DD");

      const startDate = dateRange[0].format("YYYY-MM-DD");
      const endDate = dateRange[1].format("YYYY-MM-DD");
      const isDateInRange = itemDate.isBetween(startDate, endDate, 'day', '[]');
      return isDateInRange && item.type === transactionType && item.status === transactionStatus;

  });

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 260,
      align: 'center',
      render: (text) => {
        return <span>{formatDate(text)}</span>
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Direct Link",
      dataIndex: "direct_link_username",
      key: "direct_link_username",
      render: (record) => (record == null ? "-" : record),
    },
    {
      title: "Action By",
      dataIndex: "modified_by_username",
      key: "modified_by_username",
      render: (record) => (record == null ? "-" : record),
    },
    {
      title: "Reference Number",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // render: (type) => {
      //   return type.charAt(0).toUpperCase() + type.slice(1);
      // },
      render: (type, record) => {
        let statusColor;
        if(record.status === "pending"){
          statusColor = 'text-yellow-300';
        }
        if(record.status === "completed"){
          statusColor = 'text-green-500';
        }
        if(record.status === "failed"){
          statusColor = 'text-red-500';
        }
        if(record.status === "cancelled"){
          statusColor = 'text-gray-400';
        }
        if(record.status === "declined"){
          statusColor = 'text-gray-400';
        }
        return (
          <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
        )
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <Card title={(<div className="flex justify-between items-center"><span>Transaction Records</span></div>)} >
        <div className="flex flex-col lg:flex-row gap-4 mb-4 lg:items-center justify-between">
          <div className="flex flex-col md:flex-row gap-3">
            <DatePicker.RangePicker
                className="lg:min-w-[200px] h-[40px]"
                format="YYYY-MM-DD"
                placeholder={['Date From', 'Date To']}
                onChange={handleDateRangeChange}
            />
              <Select
                size="large"
                className="lg:min-w-[150px]"
                defaultValue={transactionType}
                options={options}
                onChange={(selectedValue) => {
                  setTransactionType(selectedValue);
                  handleDateRangeChange(dateRange);
                }}
              />
            <Select
              size="large"
              defaultValue={transactionStatus}
              className="lg:min-w-[150px]"
              options={[
                { value: 'pending', label: (<span className="text-yellow-300">Pending</span>) },
                { value: 'completed', label: (<span className="text-green-500">Completed</span>) },
                { value: 'failed', label: (<span className="text-red-500">Failed</span>) },
                { value: 'cancelled', label: (<span className="text-gray-400">Cancelled</span>) },
                { value: 'declined', label: (<span className="text-gray-400">Declined</span>) },
              ]}
              onChange={(selectedValue) => {
                setTransactionStatus(selectedValue);
                handleDateRangeChange(dateRange);
              }}
            />
          </div>
          <span className="hidden md:flex">Total {transactionStatus.charAt(0).toUpperCase() + transactionStatus.slice(1)}: <span className="ml-1 font-bold">{formatAsMoney(totals.total_amount)}</span></span>
        </div>
        <Table 
          bordered
          scroll={{
            x: 1200,
            y: 700,
          }}
          dataSource={filteredTransactionRecords} 
          columns={columns} 
          loading={tableLoading}
          rowKey={"key"} 
          className={`min-h-[230px] shadow-md rounded-md`}
        />
      </Card>
    </div>
  );
};

export default TransactionRecords;
import React, { } from 'react';
import CryptoJS from 'crypto-js';
import { userWelcomeMessages, playerWelcomeMessages, playerGoodbyeMessages, userLogoutMessages } from '../../static/constants';

export const showTimedMessage = (modal, title, message, button, seconds, isSuccess, onOk) => {
    let secondsToGo = seconds;
    let instance;
    
    const options = {
      title: title,
      content: `${message}`,
      okText: button,
      onOk: onOk !== undefined ? onOk : undefined, // Use onOk if provided, otherwise undefined
    };
    
    if (isSuccess) {
      instance = modal.success(options);
    } else {
      instance = modal.error(options);
    }
    
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        okText: `${button} (${secondsToGo})`, // Add the countdown to the default button text.
      });
    }, 1000);
  
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
};

export const showTimedMessageWithAction = (modal, title, message, button, seconds, force, mode, onOk) => {
  let secondsToGo = seconds;
  let instance;
  
  const options = {
    title: title,
    content: `${message}`,
    okText: button,
    onOk: handleOk, // Use onOk if provided, otherwise undefined
  };
  
  if (mode === "success") {
    instance = modal.success(options);
  } else if (mode === "error") {
    instance = modal.error(options);
  } else if (mode === "warning"){
    instance = modal.warning(options);
  }

  function handleOk() {
    if (onOk) {
      onOk();
    }
    instance.destroy();
  }
  
  const timer = setInterval(() => {
    secondsToGo -= 1;
    instance.update({
      okText: `${button} (${secondsToGo})`, // Add the countdown to the default button text.
    });
  }, 1000);

  setTimeout(() => {
    clearInterval(timer);
    if(force){
      handleOk();
    }
    instance.destroy();
  }, secondsToGo * 1000);
};

export const showTimedBulletMessage = (modal, title, message, button, seconds, isSuccess, onOk) => {
  let secondsToGo = seconds;
  let instance;

  const messageWithLineBreaks = `${message}`;

  const options = {
    title: title,
    content: messageWithLineBreaks, // Only the message without the countdown.
    okText: button, // Include the countdown in the OK button.
    onOk: onOk !== undefined ? onOk : undefined, // Use onOk if provided, otherwise undefined.
  };

  if (isSuccess) {
    instance = modal.success(options);
  } else {
    instance = modal.error(options);
  }

  const timer = setInterval(() => {
    secondsToGo -= 1;
    instance.update({
      okText: `${button} (${secondsToGo})`, // Add the countdown to the default button text.
    });
  }, 1000);

  setTimeout(() => {
    clearInterval(timer);
    instance.destroy();
  }, secondsToGo * 1000);
};

export const showConstrainedMessages = (modal, title, messagesArray, button, time, onOk) => {
    const messagesList = messagesArray.map((message, index) => (
      <li key={index}>{message}</li>
    ));
  
    let isButtonEnabled = false;
  
    const options = {
      title: title,
      content: (
        <>
          {messagesList}
        </>
      ),
      okText: time > 0 ? `${button} (${time})` : button, // Show timer only if time > 0
      onOk: handleOk,
      okButtonProps: {
        disabled: !isButtonEnabled, // Initially, the button is disabled
      },
    };
  
    const instance = modal.confirm(options);
  
    const timer = setInterval(() => {
      if (time > 0) {
        time -= 1;
        options.okText = time > 0 ? `${button} (${time})` : button; // Update button label
      } else {
        isButtonEnabled = true; // Enable the OK button after the specified time
        options.okButtonProps.disabled = false;
        clearInterval(timer);
      }
  
      instance.update(options);
  
    }, 1000);
  
    function handleOk() {
      if (isButtonEnabled) {
        if (onOk) {
          onOk();
        }
        instance.destroy();
      }
    }
};

export const showConstrainedActionMessage = (modal, title, message, button, time, emphasizedWords, onOk) => {
  let isButtonEnabled = false;

  // Helper function to bold specified words within the message
  const boldEmphasizedWords = (text, words) => {
    const regex = new RegExp(`\\b(?:${words.join('|')})\\b`, 'gi');
    return text.replace(regex, '<strong>$&</strong>');
  };

  const emphasizedMessage = boldEmphasizedWords(message, emphasizedWords);

  const options = {
    title: title,
    content: <div dangerouslySetInnerHTML={{ __html: emphasizedMessage }} />,
    okText: time > 0 ? `${button} (${time})` : button, // Show timer only if time > 0
    onOk: handleOk,
    okButtonProps: {
      disabled: !isButtonEnabled, // Initially, the button is disabled
    },
  };

  const instance = modal.confirm(options);

  const timer = setInterval(() => {
    if (time > 0) {
      time -= 1;
      options.okText = time > 0 ? `${button} (${time})` : button; // Update button label
    } else {
      isButtonEnabled = true; // Enable the OK button after the specified time
      options.okButtonProps.disabled = false;
      clearInterval(timer);
    }

    instance.update(options);
  }, 1000);

  function handleOk() {
    if (isButtonEnabled) {
      if (onOk) {
        onOk();
      }
      instance.destroy();
    }
  }
};

export const isValidName = (name) => {
    const namePattern = /^[A-Za-z\s]+$/;
    return namePattern.test(name);
}

export const isValidMoneyAmount = (amount) => {
    const moneyPattern = /^\d+(\.\d{2})?$/;
    const numericAmount = parseFloat(amount);
    
    return moneyPattern.test(amount) && numericAmount >= 0 && numericAmount <= 999999999;
}

export const isValidPhMobileNumber = (number) => {
  // Regular expression to match valid Philippine mobile numbers
  const mobileNumberPattern = /^(09\d{9})|(639\d{9})$/;

  return mobileNumberPattern.test(number) && number.length === 11;
}

export const isNotEmptyOrNull = (value) => {
    return value !== null && value.trim() !== '';
};

export const formatAccountName = (firstName, lastName) => {

    if (firstName === null || lastName === null) {
        return ''; // or any other default value you prefer
      }

    const capitalizeWords = (input) => {
      return input.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
  
    const formattedFirstName = capitalizeWords(firstName);
    const formattedLastName = capitalizeWords(lastName);
    return `${formattedFirstName} ${formattedLastName}`;
}

export const paycoolsNameFormat = (firstName, lastName) => {
  if (firstName === null || lastName === null) {
    return ''; // or any other default value you prefer
  }

  const sanitizedFirstName = firstName.replace(/[^\w\s.-]/g, '');
  const sanitizedLastName = lastName.replace(/[^\w\s.-]/g, '');

  const concatenatedName = sanitizedFirstName + ' ' + sanitizedLastName;
  const formattedName = concatenatedName.replace(/\s+/g, ' ');

  return formattedName;
};

export const getGameIcon = (gameId, provider) => {
  try {

    let imagePath;

    switch (provider) {
      case 'jili':
        imagePath = require(`../../assets/resources/icons/gameIcons/jili-icons/250x250_EN_GAMEID_${gameId}.webp`);
        break;
      case 'sa':
        imagePath = require(`../../assets/resources/icons/gameIcons/sa-icons/sa_default.webp`);
        break;
      case 'sp':
        const game = gameId.replace(/-/g, '_');
        imagePath = require(`../../assets/resources/icons/gameIcons/sp-icons/${game}_500x500.webp`);
        break;
      default:
        imagePath = require('../../assets/resources/icons/gameIcons/default.webp');
        break;
    }
    return imagePath;


  } catch (error) {
    return require('../../assets/resources/icons/gameIcons/default.webp');
  }
};

export const desEncrypt = (inString) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_MW_ENCRYPT_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_MW_ENCRYPT_IV);
  const encrypted = CryptoJS.DES.encrypt(inString, key, { iv: iv }).toString();
  return encrypted;
};

export const desDecrypt = (encryptedString) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_MW_ENCRYPT_KEY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_MW_ENCRYPT_IV);

  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(encryptedString),
    },
    key,
    { iv: iv }
  ).toString(CryptoJS.enc.Utf8);

  return decrypted;
};

export const formatAsMoney = (amount) => {
  if (isNaN(amount)) {
    return '0.00';
  }
  return parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatDate = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const formattedDate = inputDate.toLocaleString(undefined, options);

  // Remove 'at' from the formatted date
  const withoutAt = formattedDate.replace(' at', '');

  return withoutAt;
};

export const capitalizeFirstLetter = (str) => {
  return str.replace(/\b\w/g, match => match.toUpperCase());
}

export const randomWelcomeMessage = (user) => {
  if(user === "player"){
    const randomIndex = Math.floor(Math.random() * playerWelcomeMessages.length);
    return playerWelcomeMessages[randomIndex]
  }else{
    const randomIndex = Math.floor(Math.random() * userWelcomeMessages.length);
    return userWelcomeMessages[randomIndex]
  }
}

export const randomLogoutMessage = (user) => {
  if(user === "player"){
    const randomIndex = Math.floor(Math.random() * playerGoodbyeMessages.length);
    return playerGoodbyeMessages[randomIndex]
  }else{
    const randomIndex = Math.floor(Math.random() * userLogoutMessages.length);
    return userLogoutMessages[randomIndex]
  }
}
import "antd/dist/reset.css";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { Collapse, initTE } from "tw-elements";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import Login from "./components/Unauthenticated/Login/Login";
import LayoutWithRoute from "./components/layout";
import Faq from "./components/Unauthenticated/Faq/Faq";
import SignUp from "./components/Unauthenticated/Login/SignUp";
import Terms from "./components/Unauthenticated/Terms/Terms";
import PrivacyPolicy from "./components/Unauthenticated/Privacy/PrivacyPolicy";
import BackOffice from "./components/Authenticated/Backoffice/BackOffice";
import TeamManagement from "./components/Authenticated/Backoffice/TeamManagement";
import TeamBetting from "./components/Authenticated/Backoffice/TeamBetting";
import Cashin from "./components/Authenticated/UserPanel/Cashin";
import Cashout from "./components/Authenticated/UserPanel/Cashout";
import BettingHistory from "./components/Authenticated/UserPanel/BettingHistory";
import TransactionRecords from "./components/Authenticated/Backoffice/TransactionRecords";
import CashoutRequest from "./components/Authenticated/Backoffice/CashoutRequest";
import CashinRequest from "./components/Authenticated/Backoffice/CashinRequest";
import Contact from "./components/Authenticated/Contact/Contact";
import Profile from "./components/Authenticated/UserPanel/Profile";
import PaymentChannel from "./components/Authenticated/UserPanel/PaymentChannel";
import Security from "./components/Authenticated/UserPanel/Security";
import Gameview from "./components/Authenticated/GameView/Gameview";
import ForgetPassword from "./components/Unauthenticated/Login/ForgetPassword";
import MobileVerification from "./components/Unauthenticated/Login/MobileVerification";
import ResetPassword from "./components/Unauthenticated/Login/ResetPassword";
import GamesDescription from "./components/Unauthenticated/Landing/GamesDescription";
// import CommissionReport from "./components/Authenticated/Backoffice/CommissionReport";
import MWCash from "./components/Authenticated/UserPanel/MWCash";
import TransactionPolicy from "./components/Unauthenticated/TransactionPolicy/TransactionPolicy";
import Settings from "./components/Authenticated/UserPanel/Settings";
import { useMediaQuery } from 'react-responsive';


const App = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    initTE({ Collapse });
  }, []);
  
  const NotAuthenticated = () => {
    return (
      <Routes>
        <Route path={"/"} element={<LayoutWithRoute />} />
        {isMobile ? 
            <>
              <Route path={"/login"} element={<Login navigate={navigate} />}/>
              <Route path={"/signup"} element={<SignUp navigate={navigate}/>} />
            </>
        : null}
        <Route path={"/recover"} element={<ForgetPassword navigate={navigate}/>} />
        <Route path={"/games"} element={<GamesDescription />} />
        <Route path={"/reset-password"} element={<ResetPassword navigate={navigate}/>} />
        <Route path={"/verification/:mobile_number"} element={<MobileVerification navigate={navigate}/>} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/faq"} element={<Faq />} />
        <Route path={"/terms-and-conditions"} element={<Terms />} />
        <Route path={"/transaction-policy"} element={<TransactionPolicy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  const Authenticated = () => {
    return (
      <Routes>
        <Route path={"/faq"} element={<Faq />} />
        <Route path={"/games"} element={<GamesDescription />} />
        <Route path={"/terms-and-conditions"} element={<Terms />} />
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"/transaction-policy"} element={<TransactionPolicy />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/userpanel/profile"} element={<Profile />} />
        <Route path={"/userpanel/security"} element={<Security />} />

        {currentUser?.roleId < 5 ? (
          <>
            <Route path={"/backoffice"} element={<BackOffice />} />
            <Route path={"/backoffice/teammanagement"} element={<TeamManagement />} />
            <Route path={"/backoffice/teambetting"} element={<TeamBetting />} />
            <Route path={"/backoffice/cashout-request"} element={<CashoutRequest />} />
            <Route path={"/backoffice/cashin-request"} element={<CashinRequest />} />
            <Route path={"/backoffice/transaction-records"} element={<TransactionRecords />} />
            <Route path={"/userpanel/payment-channel"} element={<PaymentChannel />} />
            {/* <Route path={"/backoffice/commission-report"} element={<CommissionReport/>}/> */}
            <Route path="*" element={<Navigate to="/backoffice" />} />

            {currentUser?.roleId < 3 ? (
              <>
                <Route path={"/userpanel/mw-cash"} element={<MWCash/>} />
                <Route path={"/userpanel/settings"} element={<Settings />} />
              </>
            ) : null}
          </>
        ) : (
          <>
            <Route path={"/"} element={<LayoutWithRoute/>} />
            <Route path={"/game"} element={<Gameview />} />
            <Route path={"/userpanel/cashin"} element={<Cashin />} />
            <Route path={"/userpanel/cashout"} element={<Cashout />} />
            <Route path={"/userpanel/betting-history"} element={<BettingHistory />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    );
  };

  return (
    <>
      <LayoutWithRoute>
        {isAuthenticated ? <Authenticated /> : <NotAuthenticated />}
      </LayoutWithRoute>
    </>
  );
};

export default App;

import { Card, Table, Modal, DatePicker, Tooltip, message as floatingMessage } from "antd";
import moment from 'moment';
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect , useState, useMemo} from "react";
import { getManagedUsersBetting, getUserInfo } from "../../../services/moneywin/moneywin-api";
import { showTimedMessageWithAction, formatAsMoney } from "../../../services/util/utilities";
import { LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const TeamBetting = () => {

  const location = useLocation();
  const [role_id, set_role_id] = useState(location.state?.role_id);
  const user_id = location.state?.user_id;
  const [message, messageHolder] = Modal.useModal();
  const [managedUsers, setManagedUsers] = useState([]);
  const [managedUsers2, setManagedUsers2] = useState([]);
  const [managedUsers3, setManagedUsers3] = useState([]);
  const [managedPlayers, setManagedPlayers] = useState([]);
  const [managedPlayers2, setManagedPlayers2] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
  const [cardTitle1, setCardTitle1] = useState(null);
  const [cardTitle2, setCardTitle2] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [buttonFromDate, setButtonFromdate] = useState(null);
  const [buttonToDate, setButtonTodate] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [isClickDisabled, setIsClickDisabled] = useState(false);
  const [totalBetLost, setTotalBetLost] = useState('');
  const isMobile = window.innerWidth <= 768;
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if(expandedRowKeys.length === 0 && expandedRowKeys2.length === 0){
        if(role_id && user_id){
          let usersData
          if((fromDate && toDate) || (buttonFromDate && buttonToDate)){
            usersData = await getManagedUsersBetting(role_id, user_id, fromDate ? fromDate : buttonFromDate, toDate ? toDate : buttonToDate);
          }else{
            usersData = await getManagedUsersBetting(role_id, user_id);
          }

         
          
          if (usersData && (usersData.users.length > 0 || usersData.players.length > 0)) {
              setManagedUsers(usersData.users);
              setManagedPlayers(usersData.players);
              const totalBetLost = usersData.players.reduce((sum, player) => sum + parseFloat(player.total_lost.replace(/[^0-9.-]/g, '')), 0);
              setTotalBetLost(totalBetLost);
          }
        }else{
          const response = await getUserInfo(token);
          
          if (response.user_id && response.role_id) {
            set_role_id(response.role_id);
            let usersData
            if((fromDate && toDate) || (buttonFromDate && buttonToDate)){
              
              usersData = await getManagedUsersBetting(response.role_id, response.user_id, fromDate ? fromDate : buttonFromDate, toDate ? toDate : buttonToDate);
            }else{
              usersData = await getManagedUsersBetting(response.role_id, response.user_id);
            }
            
            if (usersData && (usersData.users.length > 0 || usersData.players.length)) {
                setManagedUsers(usersData.users);
                setManagedPlayers(usersData.players);
                const totalBetLost = usersData.players.reduce((sum, player) => sum + parseFloat(player.total_lost.replace(/[^0-9.-]/g, '')), 0);
                setTotalBetLost(totalBetLost);
            }
          }else if(response.timed_out || response.request_failed){
            floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
          }else if(response.error){
            showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
            async () => {
              dispatch(logout());
            });  
          }
        }

        setTableLoading(false);
      }
    };

    fetchData();
    
    const interval = setInterval(fetchData, 10000);
  
    return () => {
      clearInterval(interval);
    };
}, [fromDate, toDate, buttonFromDate, buttonToDate, dispatch]);

  const handleRowExpand = async (expanded, record) => {
    if (parseInt(record.role_id) === 5) {
      return;
    }
    
    let newExpandedRowKeys = [];
    let newExpandedRowKeys2 = [];
    if (expanded) {
      newExpandedRowKeys = [record.user_id];
      setExpandedRowKeys([]); 
      setExpandedRowKeys2([]); 
    }
  
    setExpandedRowKeys(newExpandedRowKeys);
    setExpandedRowKeys2(newExpandedRowKeys2);
  
    if (expanded) {
      await getManagedUsersBettingUnder1(record.role_id, record.user_id);
    }
  
    let cardTitle = "";
    if (record.role_id === 3) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle1(cardTitle);
  };

  const handleSecondRowExpand = async (expanded, record) => {
    if (record.role_id === 5) {
      setExpandedRowKeys2([]);
      return;
    }
  
    let newExpandedRowKeys2 = [];
    if (expanded) {
      newExpandedRowKeys2 = [record.user_id];
      setExpandedRowKeys2([]);
    }
  
    setExpandedRowKeys2(newExpandedRowKeys2);
  
    if (expanded) {
      await getManagedUsersBettingUnder2(record.role_id, record.user_id);
    }
  
    let cardTitle = "";
    if (record.role_id === 3) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle2(cardTitle);
  };


  const getManagedUsersBettingUnder1 = async (role_id, user_id) => {
    if(role_id && user_id){
      setManagedUsers2([]); 
      setManagedPlayers2([])
      let usersData
          
      if((fromDate && toDate) || (buttonFromDate && buttonToDate)){
        usersData = await getManagedUsersBetting(role_id, user_id, fromDate ? fromDate : buttonToDate, toDate ? toDate : buttonToDate);
      }else{
        usersData = await getManagedUsersBetting(role_id, user_id);
      }

      if (usersData && (usersData.users.length > 0 || usersData.players.length > 0)) {
          setManagedUsers2(usersData.users);
          setManagedPlayers2(usersData.players)
      }
    }
  }

  const getManagedUsersBettingUnder2 = async (role_id, user_id) => {
    if(role_id && user_id){
      setManagedUsers3([]); 
      let usersData
          
      if((fromDate && toDate) || (buttonFromDate && buttonToDate)){
        usersData = await getManagedUsersBetting(role_id, user_id, fromDate ? fromDate : buttonFromDate, toDate ? toDate : buttonToDate);
      }else{
        usersData = await getManagedUsersBetting(role_id, user_id);
      }

      if (usersData && usersData.players.length > 0) {
        setManagedUsers3(usersData.players);
      }
    }
  }

  const handleDateRangeChange = (dates) => {
    setTableLoading(true);
    setSelectedDuration(null);
    setButtonFromdate(null);
    setButtonTodate(null);
    setExpandedRowKeys2([]);
    setExpandedRowKeys([]);
    
    if (dates && dates.length > 0) {
      const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');

      setFromDate(startDate);
      setToDate(endDate);
    }else{
      setFromDate(null);
      setToDate(null);
    }
  };
  const handleClearDateRange = () => {
    setSelectedDuration(null);
    setButtonFromdate(null);
    setButtonTodate(null);
    setFromDate(null);
    setToDate(null);
  };
  const datePickerValue = useMemo(() => {
    return [fromDate ? moment(fromDate) : null, toDate ? moment(toDate) : null];
  }, [fromDate, toDate]);

  const handleDateClick = (date) => {
    if (isClickDisabled) return; // If click is disabled, return
    let reclick = false;
    setExpandedRowKeys2([]);
    setExpandedRowKeys([]);
    setFromDate(null);
    setToDate(null);

    if(selectedDuration === date){
      setSelectedDuration(null)
      reclick = true;
    }else{
      setSelectedDuration(date);
    }

    if (reclick) {
      setButtonTodate(null);
      setButtonFromdate(null);
    }else{
      setIsClickDisabled(true);
      setTableLoading(true);

      let startDate = new Date(); // Current date and time
      let endDate = new Date(); // Current date and time

      // Set start date to 00:00 of today
      startDate.setHours(0, 0, 0, 0);

      switch(date){
          case "7D":
              startDate.setDate(startDate.getDate() - 6); // Start date 7 days ago
              break;
          case "30D":
              startDate.setDate(startDate.getDate() - 29); // Start date 30 days ago
              break;
          default:
              endDate.setHours(1, 1, 5, 9);
      }

      // Formatting the dates in 'YYYY-MM-DD HH:mm:ss' format
      const formatDate = (date) => {
          return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
      };
      setButtonFromdate(formatDate(startDate));
      setButtonTodate(formatDate(endDate));
    }

    setTimeout(() => {
      setIsClickDisabled(false); // Enable clicks after setting the dates
    }, 1000); 
  };

  const playerCols = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Total Cashed-In",
      dataIndex: "total_cashin",
      key: "total_cashin",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Cashed-Out",
      dataIndex: "total_cashout",
      key: "total_cashout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Placed",
      dataIndex: "total_stake",
      key: "total_stake",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Won",
      dataIndex: "total_payout",
      key: "total_payout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Lost", 
      dataIndex: "total_lost",
      key: "total_lost",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Running Balance", 
      dataIndex: "running_balance",
      key: "running_balance",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
  ];

  const userCols = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      // title: "Commission Percentage", 
      title: "%", 
      width: 90,
      dataIndex: "commission_percentage",
      key: "commission_percentage",
      align: 'center',
    },
    {
      title: "Total Cashed In",
      dataIndex: "total_cashin",
      key: "total_cashin",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Cashed Out",
      dataIndex: "total_cashout",
      key: "total_cashout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Placed",
      dataIndex: "total_stake",
      key: "total_stake",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Won",
      dataIndex: "total_payout",
      key: "total_payout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Lost", 
      dataIndex: "total_lost",
      key: "total_lost",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Running Balance", 
      dataIndex: "running_balance",
      key: "running_balance",
      fixed: isMobile ? undefined : 'right',
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatAsMoney(text)}
          </span>
        );
      }
    },
  ];

  /*
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalItems = managedUsers.length + managedUsers2.length + managedUsers3.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages, managedUsers, managedUsers2, managedUsers3]);
  */
  return (
    <div className="bg-primary h-full w-full p-5 flex flex-col gap-5">
      {messageHolder}
      <Card 
        className="mb-0"
        title={(
          <div className="flex flex-col gap-5 md:flex-row md:flex-wrap md:items-center">
            <span>Team Betting</span>
          </div>
        )}
      >
        <div className="flex flex-col md:flex-wrap md:flex-row gap-3 mb-3">
          <div className="flex gap-3">
            <span 
                style={{
                textAlign: "center",
                backgroundColor: selectedDuration === "1D" ? "#3F3F3F" : "white",
                color: selectedDuration === "1D" ? "white" : "black",
                cursor: isClickDisabled ? "not-allowed" : "pointer",
              }}
              className={`font-semibold py-2 px-4 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
              onClick={() => handleDateClick('1D')}
            >
                {isClickDisabled && selectedDuration === '1D' ? <LoadingOutlined /> : '1D'}
            </span>
            <span 
              style={{
                textAlign: "center",
                backgroundColor: selectedDuration === "7D" ? "#3F3F3F" : "white",
                color: selectedDuration === "7D" ? "white" : "black",
                cursor: isClickDisabled ? "not-allowed" : "pointer",
              }}
              className={`font-semibold py-2 px-4 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
              onClick={() => handleDateClick('7D')}
            >
              {isClickDisabled && selectedDuration === '7D' ? <LoadingOutlined /> : '7D'}
            </span>
            <span 
              style={{
                textAlign: "center",
                backgroundColor: selectedDuration === "30D" ? "#3F3F3F" : "white",
                color: selectedDuration === "30D" ? "white" : "black",
                cursor: isClickDisabled ? "not-allowed" : "pointer",
              }}
              className={`font-semibold py-2 px-3 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
              onClick={() => handleDateClick('30D')}
            >
              {isClickDisabled && selectedDuration === '30D' ? <LoadingOutlined /> : '30D'}
            </span>
          </div>
          <div className="flex gap-3">
            <DatePicker.RangePicker
              className="lg:min-w-[250px] md:w-[230px] xl:w-[300px] 2xl:w-[420px] w-full h-[40px]"
              format="YYYY-MM-DD HH:mm:ss a"
              showTime
              use12Hours
              placeholder={['Date From', 'Date To']}
              onChange={handleDateRangeChange}
              onClear={handleClearDateRange}
              // value={[fromDate ? moment(fromDate) : null, toDate ? moment(toDate) : null]}
              value={datePickerValue}
            />
            <Tooltip title={(<span className="">Clear sort</span>)} placement="bottom" >
              <CloseCircleFilled className="text-[25px] cursor-pointer" onClick={handleClearDateRange}/>
            </Tooltip>
          </div>
        </div>
        <Table 
          scroll={{
            x: 1800,
            y: "100%",
          }}
          bordered 
          loading={tableLoading}
          dataSource={role_id === 4 ? managedPlayers : managedUsers}
          columns={userCols} 
          rowKey="user_id"
          className="min-h-[230px] shadow-md rounded-md" 
          expandable={role_id === 4 ? null : {
            expandedRowKeys,
            onExpand: handleRowExpand,
            expandedRowRender: (record1) => ( 
              <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#305EDB] rounded-lg">
                <span className="text-xl font-semibold text-[#305EDB]">{record1.username}</span>
                <Card title={cardTitle1}>
                  <Table
                    dataSource={record1.role_id === 4 ? managedPlayers2 : managedUsers2}
                    columns={userCols}
                    rowKey="user_id"
                    expandable={record1.role_id === 4 ? null : {
                      expandedRowKeys: expandedRowKeys2,
                      onExpand: handleSecondRowExpand,
                      expandedRowRender: (record2) => (
                        <div className="flex flex-col gap-4 p-5 border-solid border-[#9C2552] border-2 rounded-lg">
                          <span className="text-xl font-semibold text-[#9C2552]">{record2.username}</span>
                          <Card title={cardTitle2}>
                            <Table
                              dataSource={managedUsers3}
                              columns={cardTitle2 === "Players" ? playerCols : userCols}
                              rowKey="user_id"
                              expandable={null} 
                            />
                          </Card>
                        </div>
                      ),
                    }}
                  />
                </Card>
                {record1.role_id !== 4 && managedPlayers2?.length > 0 ? (
                  <Card title="Players">
                    <Table
                      bordered
                      // bordered
                      className={`min-h-[230px] shadow-md rounded-md`}
                      dataSource={managedPlayers2}
                      columns={playerCols}
                      rowKey="user_id"
                    />
                  </Card>
                ) : null}
              </div>
            ),
          }}
        />
      </Card>
      {role_id < 4 && managedPlayers?.length > 0 ? (
        // <Card title="Players" className="mt-5">
        <Card title={
          <div className="flex justify-between items-center">
            <span>Players</span>
            <span>Total Bet Lost: {formatAsMoney(totalBetLost)}</span>
          </div>
        }>
          <Table
            bordered
            scroll={{
              x: 1200,
            y: 620,
            }}
            // bordered
            loading={tableLoading}
            className={`min-h-[230px] shadow-md rounded-md`}
            dataSource={managedPlayers}
            columns={playerCols}
            rowKey="user_id"
          />
        </Card>
      ) : null}
    </div>
  );
};

export default TeamBetting;
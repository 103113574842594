import React, { useState } from 'react'
import {Modal, Form, Image, Input, Spin, message, Button } from "antd";
import { useNavigate} from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { getUserInfo, sendEmail } from '../../../services/moneywin/moneywin-api';
import mwLogo from "../../../assets/resources/logos/mw-logo-signup.png";
import { desEncrypt, showTimedMessageWithAction } from '../../../services/util/utilities';
const ForgetPassword = ({ reset = false }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalLoading, setModalLoading] = useState(false);
    const maskedEmail = email.replace(/^(.)(.*)(.@.*)$/, (_, first, middle, last) => `${first}${'*'.repeat(middle.length)}${last}`);
    const [errorMessage, setErrorMessage] = useState('');
    const [encryptText, setEncryptText] = useState('');
    const [messageHolder, setMessageHolder] = Modal.useModal();
    const showModal = () => {

        if(!username)
        {
            return;
        }
        setIsModalOpen(true);
    };
  
    const handleOk = async () => {
        setModalLoading(true);
        setIsLoading(true);
        setIsModalOpen(false);
        if (!errorMessage) {
            const result = await sendEmail(email, encryptText, fname);

            if(result.status === "success"){
                showTimedMessageWithAction(messageHolder, "Email Sent", `We have successfully sent you an email to reset your password. `, "Close", 5, true,"success", async () => {
                    navigate('/');
                })
            }else if(result.status === "error"){
                message.success({ content: result.message, key: 'custom_message' });
            }else{
                message.error({ content: "An error has occured. Please try again later.", key: 'custom_message' });
            }
        }
        setModalLoading(false);
        setIsLoading(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const formatDate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    };
    const handleCheck = async () => {
        if (!username) {
            return;
        }
        try 
        {
            setIsLoading(true);
            const result = await getUserInfo(null, username);
            let encrypted;
            if (result && result.email) {
                setEmail(result.email);
                setFname(result.first_name.charAt(0).toUpperCase() + result.first_name.slice(1));
                const user_id = result.user_id.toString();
                const date = new Date();
                // console.log(formatDate(date));
                const data = `[${user_id}][${formatDate(date)}]`;
                // console.log('id with date:',data);
                // const encrypted = encodeURIComponent(desEncrypt(user_id));
                encrypted = encodeURIComponent(desEncrypt(data));

                // console.log('Encrypted: ',encrypted);
                // const decrypted =  desDecrypt(decodeURIComponent(encrypted));
                // console.log("Decrypted: ",decrypted);

                setEncryptText(encrypted);
                showModal();
                setErrorMessage('');
            } else {
                setErrorMessage("Email not available or does not exist.");
                showModal();
            }
            // const decrypted =  decodeURIComponent(desDecrypt(encrypted));
            
            setIsLoading(false);
        } catch (error) {
            console.error("An error occurred:", error);
            setIsLoading(false);
            setErrorMessage("An error occurred. Please try again later.");
            showModal();
        }
    };
    
  return (
    <div className="w-full h-full bg-primary md:bg-pro-bg md:bg-cover md:bg-center flex items-center justify-center md:py-[210px] p-6">
        <div className="flex flex-col justify-between h-full w-full py-5 max-w-[500px] md:justify-center">
            <div className="bg-primary md:bg-white rounded-[30px] border-0 text-white relative md:p-10 h-auto">
                <div className="flex flex-col items-center gap-4 w-full md:pt-12 h-full">
                    {/* <div className="w-[198px] md:w-[341px] md:absolute md:top-[-190px]">
                        <Image width={"100%"} preview={false} src={loginLogo} />
                    </div> */}
                    {setMessageHolder}
                    <div className="md:w-[341px] md:absolute mx-auto flex items-center justify-center md:text-center md:top-[-90px]">
                        <Image width={"100%"} preview={false} src={mwLogo} style={{ objectFit: 'fill', height: 165, width: 250,}}/>
                    </div>
                    <span className="font-semibold text-[20px] text-white md:text-[28px] md:text-textDarkGray pt-6">
                    Forgot Password?
                    </span>
                    <span className='text-white md:text-textDarkGray'>
                        Please enter your email.
                    </span>
                    <Form
                        className={`w-full ${reset ? 'mt-[10px]' : 'mt-[50px]'}`}
                        layout="vertical"
                        name="forget-password"
                        initialValues={{
                            remember: true,
                        }}
                        autoComplete="off"
                    >
                        {reset && (
                            <div className="flex flex-col gap-2 mb-5 text-justify">
                                <span className="text-[16px] font-semibold">Expired Link</span>
                                <span>The link you are trying to access is no longer available. Please enter your email address below to request a new password link.</span>
                            </div>
                        )}
                        <Form.Item
                            className="login-input"
                            label={
                            <span className="label-style text-[13px] md:text-black">Please enter the email used when registering your account.</span>
                            }
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: "Please enter your email!",
                            },
                            ]}
                            validateStatus=""
                        >
                            <Input
                                placeholder="Email"
                                size="large"
                                style={{
                                    height: "56px",
                                    padding: "10px",
                                    borderRadius: "12px",
                                    fontSize: "16px",
                                }}
                                value={username}
                                onChange={(e) => 
                                    setUsername(e.target.value)
                                }
                            />
                        </Form.Item>

                        <div className='flex flex-col md:flex-row'>
                            <div className="w-full flex items-center justify-center">
                                <button
                                    type="primary"
                                    htmltype="submit"
                                    className="w-full h-[55px] text-[22px] font-semibold bg-gradient-to-t from-[#92680C] to-[#FCDB9C] duration-150 mx-1 mt-[50px] cursor-pointer text-white border-0 rounded-lg md:max-w-[285px] hover:scale-[1.05] transition-all ease-in"
                                    disabled={isLoading}
                                    onClick={handleCheck}
                                >
                                    {isLoading ? (
                                        <Spin
                                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                                        />
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>

                    <Modal 
                        className='md:min-w-[500px]'
                        title={errorMessage ? "Invalid Email" : "We'll send you a link to reset your password"}
                        open={isModalOpen} 
                        onOk={handleOk} 
                        onCancel={errorMessage ? undefined : handleCancel}
                        confirmLoading={isLoading}
                        footer={isModalLoading || errorMessage ? null : undefined}
                    >
                        {isModalLoading ? (
                            <div className="flex items-center justify-center p-4 h-300">
                                <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000', strokeWidth: 2 }} spin />}
                                />
                            </div>
                        ) : (
                            <>
                                {errorMessage ? (
                                    <div className="text-red-500 text-center mb-4 m-[2vw]">
                                        {errorMessage}
                                    </div>
                                ) : (
                                    <div className='flex flex-col justify-center items-center my-[30px]'>
                                        <span>Registered Email:</span>
                                        <span className='text-[16px] font-semibold mt-[20px]'>{maskedEmail}</span>
                                    </div>
                                )}
                            </>
                        )}

                        {errorMessage && (
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <Button onClick={handleOk} type="primary">
                                    OK
                                </Button>
                            </div>
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgetPassword
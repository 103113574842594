import React, { useEffect, useState, useRef } from "react";
import fire from "../../../../assets/resources/icons/mwIcons/MONEYWIN ICONS-POPULAR-GAMES.png";
import { Image, Skeleton, Spin, message, Modal } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../../services/moneywin/moneywin-api";
import { getGameIcon, showTimedMessageWithAction } from "../../../../services/util/utilities";
import defaultIconUrl from '../../../../assets/resources/logos/icon/MONEYWIN LOGO-01.png';
import '../../../layout/index.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../../store/slices/auth-slice';

const PopularGames = ({ toggleRegModal, allGames, navigateToSignUp}) => {
  const navigate = useNavigate();
  const [popularGames, setPopularGames] = useState([]);
  const [loadingGames, setLoadingGames] = useState({});
  const [loadedIcons, setLoadedIcons] = useState({});
  const loadedIconsRef = useRef(loadedIcons);
  const [gameLoading, setGameLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const token = useSelector((state) => state.auth.token);
  const [messageBox, messageHolder] = Modal.useModal();
  const dispatch = useDispatch();
  
  useEffect(() => {
    loadedIconsRef.current = loadedIcons;
  }, [loadedIcons])

  useEffect(() => {
    const loadInitialGames = async () => {
      setPopularGames([]);
      const gameNameList = ["Mines", "TeenPatti", "Color Game"];
      let successfullyRetrievedGames = 0;

      const findGameByName = (gameName) => {
        for (const gamesInCategory of Object.values(allGames)) {
          const matchingGame = gamesInCategory.find(
            (game) => game.name["en-US"] === gameName
          );
          if (matchingGame) {
            return matchingGame;
          }
        }
        return null;
      };
      
      for (const gameName of gameNameList) {
        const matchingGame = findGameByName(gameName);
        if (matchingGame) {
          setPopularGames((prevPopularGames) => [...prevPopularGames, matchingGame]);
          loadGameIcon(matchingGame.GameId, matchingGame?.provider);
          successfullyRetrievedGames++;
        }
      }
    
      // Function to get random games, excluding specific game names
      const getRandomGamesExcluding = (count, games, excludeNames) => {
        let randomGames = [];
        for (const gamesInCategory of Object.values(games)) {
          for (const game of gamesInCategory) {
            if (!excludeNames.includes(game.name["en-US"])) {
              randomGames.push(game);
            }
          }
        }
        randomGames = shuffleArray(randomGames);
        return randomGames.slice(0, count);
      };
    
      const additionalGamesNeeded = 5 - successfullyRetrievedGames;

      const remainingGames = getRandomGamesExcluding(additionalGamesNeeded, allGames, gameNameList);
      for (const game of remainingGames) {
        setPopularGames((prevPopularGames) => [...prevPopularGames, game]);
        loadGameIcon(game.GameId, game?.provider);
      }
    };
  
    const shuffleArray = (array) => {
      const shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    };

    const loadGameIcon = async (gameId, provider) => {
      if (!loadedIconsRef.current[gameId]) {
        try {
          let iconUrl;
    
          if (provider === "SA") {
            iconUrl = await getGameIcon(gameId, 'sa');
          } else if (provider === "SimplePlay") {
            iconUrl = await getGameIcon(gameId, 'sp');
          } else {
            iconUrl = await getGameIcon(gameId, 'jili');
          }

          setLoadedIcons((prevLoadedIcons) => ({
            ...prevLoadedIcons,
            [gameId]: iconUrl,
          }));
        } catch (error) {
          console.error("Error fetching game icon:", error);
          setLoadedIcons((prevLoadedIcons) => ({
            ...prevLoadedIcons,
            [gameId]: defaultIconUrl, // Use default icon URL in case of an error
          }));
        }
      }
    };

    loadInitialGames();
  }, [allGames]); 

  const renderGameIcon = (gameId) => {
    const iconUrl = loadedIcons[gameId];
    if (loadingGames[gameId]) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" indicator={<Loading3QuartersOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    } else if (iconUrl) {
      return (
        <Image
          alt={gameId}
          preview={false}
          src={iconUrl}
          maxWidth={isMobile ? 200 : 250}
          width={isMobile ? undefined : 250}
          style={{ height: '100%' }}
          loading="lazy"
        />
      );
    } else {
      return (
        <Skeleton.Image active className="w-[150px] md:w-[250px] bg-secondary rounded-md md:min-w-[250px] sm:h-auto min-h-[100px] md:min-h-[250.4px]"/>
      );
    }
  };

  const handleGameClick = async (gameId, gameName, provider) => {
    if(!gameLoading){
      setLoadingGames((prevLoadingGames) => ({ ...prevLoadingGames, [gameId]: true }));
      setGameLoading(true);
      try {
        const response = await getUserInfo(token);
        if(response.role_id && response.username){
          if(response.role_id === 5){
            if(gameName === "Evolution Gaming"){
              navigate("/game", { state: { gameId, username: response.username, gameProvider: "EVO" } });
            }else if(provider && provider === "SimplePlay"){
              navigate("/game", { state: { gameId, gameName, username: response.username, gameProvider: "SIMPLE_PLAY" } });
            }else if(provider && provider === "SA"){
              navigate("/game", { state: { gameId, gameName, username: response.username, gameProvider: "SA" } });
            }else{
              navigate("/game", { state: { gameId, gameName, username: response.username, gameProvider: "JILI" } });
            }
            window.scrollTo(0, 0); // Scroll to the top after the redirect
          }else{
            window.location.reload();
          }
        }else if(response.timed_out || response.request_failed){
          message.error({ content: "Unable to start game. Please check your internet connection or try again later." , key: 'custom_message' });
        }else if(response.error){
          if(token){
            showTimedMessageWithAction(messageBox, "Session Expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
            async () => {
              dispatch(logout());
            });  
          }else{
            if (window.innerWidth <= 768) {
              navigateToSignUp(); // Navigate to /signup on mobile
            } else {
              toggleRegModal(true); // Toggle registration modal on non-mobile
            }
          }
        }else{
          message.error({ content: "Unable to start game. Please check your internet connection or try again later." , key: 'custom_message' });
        }
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGames((prevLoadingGames) => ({ ...prevLoadingGames, [gameId]: false }));
        setGameLoading(false);
      }

    }
  };

  const [hoverStates, setHoverStates] = useState(Array(popularGames.length).fill(false));

  // const handleMouseEnter = (index) => {
  //   const updatedHoverStates = [...hoverStates];
  //   updatedHoverStates[index] = true;
  //   setHoverStates(updatedHoverStates);
  // };

  // const handleMouseLeave = (index) => {
  //   const updatedHoverStates = [...hoverStates];
  //   updatedHoverStates[index] = false;
  //   setHoverStates(updatedHoverStates);
  // };
  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) => {
      const updatedHoverStates = [...prevStates];
      updatedHoverStates[index] = true;
      return updatedHoverStates;
    });
  };
  
  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) => {
      const updatedHoverStates = [...prevStates];
      updatedHoverStates[index] = false;
      return updatedHoverStates;
    });
  };

  const SkeletonLoader = ({ count = 5 }) => {
    const renderSkeletons = () => {
      const skeletons = [];
      for (let i = 0; i < count; i++) {
        skeletons.push(
          <Skeleton.Image
            key={i}
            active
            className="w-[150px] md:w-[250px] bg-secondary rounded-md md:min-w-[250px] sm:h-auto min-h-[100px] md:min-h-[250.4px]"
          />
        );
      }
      return skeletons;
    };
  
    return (
      <div className="flex flex-wrap justify-center items-center gap-2 mb-10 w-full md:gap-10">
        {renderSkeletons()}
      </div>
    );
  };
  return (
    <div className="w-full h-full relative bg-transparent mx-auto">
      {messageHolder}
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="flex items-center gap-3">
          <Image src={fire} preview={false} style={{ width: 45, height: 45 }} />
          <span className="text-textYellow flex justify-start w-full font-semibold text-[22px] py-10">
            Popular Games
          </span>
        </div>
        {/* <div className="md:flex md:flex-wrap grid grid-cols-2 justify-items-center gap-2 w-full bg-gray-800 md:gap-10"> */}
        { popularGames.length < 5 ? (
          <>
            <SkeletonLoader />
          </>
        ) : (
          <div className="flex flex-wrap justify-center items-center gap-2 w-full md:gap-10">
            {popularGames.map((game, index) => (
              // <div
              //   key={game.GameId}
              //   className={`relative z-20 ${index === 0 && "absolute top-0"} ${
              //     index === 1 && "col-start-2 absolute top-0 col-span-3 md:col-span-2"
              //   } ${index === 2 && "col-start-1"}`}
              // >
              <div
                key={game.GameId}
                // className={`z-20 ${index === 2 ? 'col-span-1 row-span-2': 'col-span-1' }`}
                className={`col-span-${index === 2 ? '1 row-span-2' : '1'} z-20`}
              >
                <div
                  key={game.GameId}
                  className={`${
                    index <= 1 ? "w-[150px]  " : "w-[100px]"
                  } ${index === 1 && "relative md:ml-0"} md:w-[250px] sm:h-auto min-h-[135.95px] md:min-h-[296.4px] flex flex-col items-center justify-center p-0 md:p-0 gap-3 md:gap-5 rounded-[10px] transition-all ease-in duration-150 z-[10]`}
                  // } ${index === 1 && "ml-[65px] relative md:ml-0"} md:w-[250px] sm:h-auto min-h-[135.95px] md:min-h-[296.4px] flex flex-col items-center justify-center p-0 md:p-0 gap-3 md:gap-5 rounded-[10px] transition-all ease-in duration-100 z-[10]`}
                  // onClick={() => handleGameClick(game.GameId, game.name["en-US"])}
                >
                  {loadingGames[game.GameId] ? (
                    <Spin
                      size="large"
                      indicator={<Loading3QuartersOutlined style={{ fontSize: 24 }} spin />}
                    />
                  ) : (
                    <>
                      <div className="relative block justify-center items-center group">
                        {renderGameIcon(game.GameId)}
                        <div
                          className={`w-full h-full absolute top-0 ${
                            hoverStates[index] ? 'opacity-70' : 'opacity-0'
                          } bg-black transition-opacity duration-150`}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                        ></div>
                        <div
                          className="absolute flex justify-center items-center top-0 right-0 bottom-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                        >
                          <span 
                            className="gradient-button outlined-text px-8 py-4 rounded-full text-[15px] tracking-wide cursor-pointer font-semibold hover:scale-[1.10] transition-all ease-in"
                            onClick={() => handleGameClick(game.GameId, game.name["en-US"], game?.provider)}
                          >
                            Play Now
                          </span>
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-2 flex-none text-center items-center text-[11px] md:text-[16px]">
                        <Skeleton paragraph={{ rows: 2 }} active={false} loading={false}>
                          <span className={`text-textYellow font-normal ${index < 2 ? 'text-[13px]' : 'text-[9px]'} md:text-[16px] md:font-semibold`}>{game.name["en-US"]}</span>
                        </Skeleton>
                      </div>
                    </>
                  )}
                </div>
                {/* <div className="h-auto md:min-h-[256px] absolute top-0 w-full flex justify-center items-center opacity-0 hover:opacity-100 duration-300">
                  <div key={game.GameId} className="absolute w-full h-full top-0 lg:bg-[#1F232E] flex justify-center items-center rounded-md opacity-95 duration-300" onClick={() => handleGameClick(game.GameId, game.name["en-US"], game.provider)}></div>
                  <span
                    className="absolute px-10 py-4 hidden lg:block bg-[#FFCE29] font-semibold text-[16px] hover:bg-yellow-600 duration-300 rounded-[30px] cursor-pointer opacity-100"
                    onClick={() => handleGameClick(game.GameId, game.name["en-US"])}
                  >
                    Play Now
                  </span>
                </div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


export default PopularGames;

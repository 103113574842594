import { Form, Input, Table, Spin, Card, Select, Modal, Tooltip} from "antd";
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect ,useState} from "react";
// import coinlogo from "../../../assets/resources/images/coin-logo.png"
import coinlogo from '../../../assets/resources/icons/mwIcons/mw-cash-big.webp';
import React from "react";
import { getTransactionRecord, cashoutRequest, getUserBalance, getUserInfo, getTopLevel, modeOfPayment, getValidBets } from "../../../services/moneywin/moneywin-api";
import { bankOptions, createPayout, ewalletOptions, payoutStatus } from "../../../services/paycools/paycools-api";
import { formatAccountName, formatAsMoney, isNotEmptyOrNull, isValidMoneyAmount, isValidName, isValidPhMobileNumber, paycoolsNameFormat, showConstrainedActionMessage, showTimedBulletMessage, showTimedMessage } from "../../../services/util/utilities";
import { formatDate } from '../../../services/util/utilities';
import '../../layout/index.css';
import { cashOutRules } from "../../../static/constants";
import { useSelector } from 'react-redux';

const Cashout = () => {
  const [message, messageHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [balance, setBalance] = useState('0.00');
  const [validBets, setValidBets] = useState('0.00');
  const [cashinRecord, setCashinRecord] = useState([]);
  const [amount, setAmount] = useState('');
  const [cashoutMethodOptions, setcashoutMethodOptions] = useState([]);
  const [manualOptions] = useState([
    { value: 'GCASH MANUAL', label: 'Gcash' },
    { value: 'PAYMAYA MANUAL', label: 'Paymaya' },
  ]);
  const [cashoutMethod, setCashoutMethod] = useState('');
  const [cashoutMethodError, setCashoutMethodError] = useState('');
  const [payoutChannel, setPayoutChannel] = useState('');
  const [payoutChannelOptions, setPayoutChannelOptions] = useState([]);
  const [payoutChannelType, setPayoutChannelType] = useState(null);
  const [payoutChannelError, setPayoutChannelError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [remarks, setRemarks] = useState('');
  const [amountError, setAmountError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [remarksError, setRemarksError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const fetchData = async () => {

      const response = await getUserInfo(token);
      if (response && response.user_id) {

        const requests = await getTransactionRecord(response.user_id, "cashout");
        if (requests && requests.length > 0) {
          setCashinRecord(requests);
          checkPendingTransactions(requests);
        }
        
      }else if(response.timed_out || response.request_failed){
        message.error({ content: "Unable to retrieve cashout history. Please check your internet connection or try again later." , key: 'custom_message' });
      }

      setPageLoading(false);
    }

    const checkPendingTransactions = async (requests) => {
      if (requests && requests.length > 0) {
        const lastRecord = requests[0];
        if (lastRecord.status === "pending payment" || lastRecord.status === "pending") {
          await payoutStatus(lastRecord.transaction_reference, lastRecord.status);
        }
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);
    
    return () => {
      clearInterval(interval);
    };
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      const getBalance = await getUserBalance(currentUser.username, 'PHP')
      if(getBalance){
        const amount = getBalance?.amount || 0.00;
        setBalance(formatAsMoney(amount));
      }
    }

    fetchData();

  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getValidBets(currentUser.userId);
      if(response){
        setValidBets(formatAsMoney(response?.valid_bets));
      }
    }

    fetchData();

  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      const topLevel = await getTopLevel(currentUser.userId);
        if(topLevel && topLevel.role === 2 && topLevel.user_id){
          const response = await modeOfPayment('get_active', topLevel.user_id);
          
          if (response && response.success) {
            if(response.automated_cashout === 1 ){
              setcashoutMethodOptions([
                { value: 'EWALLET', label: 'E-Wallet' },
                { value: 'BANK', label: 'Bank Transfer' },
                { value: 'MANUAL', label: 'Agent Assisted Cash-Out' }
              ]);
            }else{
              setcashoutMethodOptions([
                { value: 'MANUAL', label: 'Agent Assisted Cash-Out' }
              ]);
            }
          }
        }else if (topLevel && topLevel.role === 1){
          setcashoutMethodOptions([
            { value: 'EWALLET', label: 'E-Wallet' },
            { value: 'BANK', label: 'Bank Transfer' },
          ]);
        
        }
    }

    fetchData();
  }, [currentUser]);


  
  const handleSumbitClick = async () => {
    
    const accountName = formatAccountName(firstName, lastName);
    
    if (!cashoutMethod) {
      setCashoutMethodError('Please select a cash-out method!');
      return;
    } else {
      setCashoutMethodError('');
    }

    if (!payoutChannel) {
      setPayoutChannelError('Please select a payment channel!');
      return;
    } else {
      setPayoutChannelError('');
    }

    if (!isNotEmptyOrNull(firstName) || !isValidName(firstName)) {
      setAccountNameError('Please enter a valid name!');
      return;
    } else {
      setAccountNameError('');
    }

    if (!isNotEmptyOrNull(lastName) || !isValidName(lastName)) {
      setAccountNameError('Please enter a valid name!');
      return;
    } else {
      setAccountNameError('');
    }

    if (!accountNumber) {
      setAccountNumberError('Please enter a valid account number!');
      return;
    } else {
      setAccountNumberError('');
    }

    if(cashoutMethod === "EWALLET" && !isValidPhMobileNumber(accountNumber)){
      setAccountNumberError('Please enter a valid account number!');
      return;
    } else {
      setAccountNumberError('');
    }

    if (!isValidMoneyAmount(amount)) {
      setAmountError('Invalid amount!');
      return;
    } else {
      setAmountError('');
    }

    if (amount < 200) {
      setAmountError("Value must be between 200 and 50,000!");
      return;
    } else {
      setAmountError('');
    }
    if (amount > 50000) {
      setAmountError("Value must be between 200 and 50,000!");
      return;
    } else {
      setAmountError('');
    }

    setIsLoading(true);

    try{
      const userInfo = await getUserInfo(token);
      if (userInfo.user_id && amount) {
        if(cashoutMethod === "MANUAL"){
          const response = await cashoutRequest(
            userInfo.user_id, 
            accountName,
            accountNumber,
            payoutChannel,
            amount,
            remarks
          );
          if (response?.success) {
            showTimedMessage(message, "Cash-out requested successfully!", "Your cash-out request was successful and is currently pending", "OK", 5, true);
            resetFields();
          }else if(response?.error){
            showTimedMessage(message, "Cash-out request failed!", response.message, "OK", 5, false);
          }else if(response?.errors){
            const formattedErrors = response.errors.map((error, index) => `(${index + 1}) ${error}`).join('\n');
            showTimedBulletMessage(message, "Cash-out request failed!", formattedErrors, "OK", 10, false);
          }else if(response?.service_charge){
            showConstrainedActionMessage(message, "Cash-out confirmation", `Oops! It seems you haven't reached the required amount of valid bets to cash-out without charge. Confirming this cashout will deduct a 5% charge of ${formatAsMoney(response.service_charge * 0.05)} from your total cashout amount of ${formatAsMoney(response.service_charge)} leaving you with ${formatAsMoney(response.service_charge - (response.service_charge * 0.05))}. Would you like to proceed with this charge, or you can continue playing to reach the required valid bets for a charge-free cash-out.`, "CONFIRM", 3,["5% charge",formatAsMoney(response.service_charge) ,formatAsMoney(response.service_charge - (response.service_charge * 0.05)), "charge-free", formatAsMoney(response.service_charge * 0.05)], async () => {
              const response = await cashoutRequest(
                userInfo.user_id, 
                accountName,
                accountNumber,
                payoutChannel,
                amount,
                remarks,
                true
              );

              if (response.success) {
                showTimedMessage(message, "Cash-out requested successfully!", "Your cash-out request was successful and is currently pending.", "OK", 5, true);
                resetFields();
              }else if(response.error){
                showTimedMessage(message, "Cash-out request failed!", response.message, "OK", 5, false);
              }else if(response.errors){
                const formattedErrors = response.errors.map((error, index) => `(${index + 1}) ${error}`).join('\n');
                showTimedBulletMessage(message, "Cash-Out request failed", formattedErrors, "OK", 10, false);
              }else{
                showTimedMessage(message, "Cash-out request failed!", "Internal error, please try again later.", "OK", 5, false);
              }
            });
          }else{
            showTimedMessage(message, "Cash-out request failed!", "Internal error, please try again later.", "OK", 5, false);
          }
        }else{
          const response = await createPayout(
            userInfo.user_id, 
            paycoolsNameFormat(firstName, lastName),
            accountNumber,
            amount,
            cashoutMethod,
            payoutChannel,
            remarks
            );
          if (response.message && response.message === "success") {
            showTimedMessage(message, "Cash-out requested successfully!", "Your cash-out request was successful and is currently pending.", "OK", 5, true);
            resetFields();
          } else if(response.errors){
            const formattedErrors = response.errors.map((error, index) => `(${index + 1}) ${error}`).join('\n');
            showTimedBulletMessage(message, "Cash-out request failed!", formattedErrors, "OK", 10, false);
          } else {
            showTimedMessage(message, "Cash-out request failed!", response.message, "OK", 5, false);
          }
        }
      }
    } catch (error) {
      showTimedMessage(message, "Cash-out request failed!", error, "OK", 5, false);
    }finally{
      setIsLoading(false);
    }
    
    
  };

  const handleCashoutMethodChange = (selectedOption) => {
      setPayoutChannel("");
      form.resetFields(['payout-channel']);
      if(selectedOption === "MANUAL"){
        setPayoutChannelType("Payout Channel")
        setPayoutChannelOptions(manualOptions);
      }else{
        if(selectedOption === "EWALLET"){
          setPayoutChannelType("E-Wallet")
          setPayoutChannelOptions(ewalletOptions);
        }else{
          setPayoutChannelType("Bank")
          setPayoutChannelOptions(bankOptions);
        }
      }
    
      setCashoutMethod(selectedOption);
  }

  const handlePayoutChannelChange = (selectedOption) => {
    setPayoutChannel(selectedOption);
  }

  const handleAccountNumberChange = (e) => {
    const inputValue = e.target.value;
    // Remove any non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, '');

    setAccountNumber(numericValue);
    setAccountNumberError("");
  };

  const resetFields = () => {
      setAmount(null);
      setCashoutMethod(null);
      setPayoutChannel(null);
      setFirstName(null);
      setLastName(null);
      setAccountNumber(null);
      form.resetFields(['amount', 'cashout-method', 'payout-channel', 'account-name', 'account-number', 'remarks']);
  }
  const uniqueNames = [...new Set(cashinRecord.map(item => item.status))];
  const filters = uniqueNames.map(status => ({
    text: status.charAt(0).toUpperCase() + status.slice(1),
    value: status,
  }));
  const columns = [
    {
      title: "Date Requested",
      dataIndex: "created_at",
      key: "created_at",
      width: 260,
      align: "center",
      render: (text) => {
        return <span>{formatDate(text)}</span>
      },
    },
    {
      title: "Reference",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
      align: "center",
    },
    {
      title: "Account No.",
      dataIndex: "account_no",
      key: "account_no",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, record) => {
        return (
          <span>
            {formatAsMoney(amount-record.service_charge)}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters,
      render: (type, record) => {
        let statusColor;
        if(record.status === "pending"){
          statusColor = 'text-yellow-300';
        }
        if(record.status === "completed"){
          statusColor = 'text-green-500';
        }
        if(record.status === "failed"){
          statusColor = 'text-red-500';
        }
        if(record.status === "cancelled"){
          statusColor = 'text-gray-400';
        }
        if(record.status === "declined"){
          statusColor = 'text-gray-400';
        }
        return (
          <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
        )
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      render: (remarks) => (remarks !== null ? remarks : "-"),
    },
  ];
  const [openCashOutRulesInfo, setOpenCashOutRulesInfo] = useState(false);
  return (
    // New - xlx
    <div className="w-full h-full relative bg-primary py-12 px-3 gap-10">
      {/* <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[0]"></div> */}
      <div className="w-full h-full flex relative flex-col xl:p-7 gap-10">
        <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[0]"></div>
        <span className="text-white text-2xl text-center z-[10]">Cash-Out</span> 
        <span className="text-white text-center z-[10]">Retrieve the balance from your wallet using any of the following cash-out methods below.</span>
          <div className="flex flex-col max-w-[800px] mx-auto w-full bg-gray-800 justify-center z-[10] items-center rounded-lg pt-5 md:p-5 xl:p-10"> 
            <div className="items-center w-full gap-2">
              <div className="md:flex md:flex-col w-full gap-5 p-4">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      src={coinlogo}
                      alt="Coin Logo"
                      className="md:w-20 md:h-20 w-[45px] h-[45px] mr-4"
                    />
                    <div className="flex flex-col">
                      <span className="text-white text-sm"> Current Balance:</span>
                      <span className="text-white md:text-[35px] text-[25px] font-semibold">{balance}</span>
                      <span className="text-yellow-500 text-[13px]">Valid Bets: {validBets}</span>
                    </div>
                  </div>
                  <div>
                    {/* <Tooltip 
                      title={(
                        <div className="flex flex-col gap-2">
                          <span>Cash-Out Rules</span>
                          {cashOutRules.map((item, index) => (
                            <div key={index}>
                              <span>{item.title}</span>
                            </div>
                          ))}
                        </div>
                      )}
                      placement="left"
                    >
                      <QuestionCircleOutlined className="text-yellow-600 text-[20px]"/>
                    </Tooltip> */}
                    <Tooltip title="Click to view Cash-Out rules">
                      <QuestionCircleOutlined className="text-yellow-600 text-[20px]" onClick={() => setOpenCashOutRulesInfo(true)}/>
                    </Tooltip>
                    
                    <Modal
                      title="Cash-Out Rules"
                      open={openCashOutRulesInfo}
                      onCancel={() => setOpenCashOutRulesInfo(false)}
                      footer={null}
                    >
                      <div className="flex flex-col bg-opacity-30 gap-5">
                        {cashOutRules.map((item, index) => (
                          <div key={index}>
                            <span>{item.title}</span>
                          </div>
                        ))}
                      </div>
                    </Modal>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-5">
                  <Form
                    layout="vertical"
                    className="w-full"
                    form={form}
                  >
                    <div className="flex flex-col items-start gap-5 w-full">
                      {messageHolder}
                      <div className="flex flex-col w-full gap-5">
                        <Form.Item
                          name="cashout-method"
                          className="w-full"
                          label={
                            <span className="text-white">
                              Cash-Out Method
                            </span> 
                          }
                          value={cashoutMethod}
                          validateStatus={cashoutMethodError ? 'error' : ''}
                          help={cashoutMethodError}
                        >
                          <Select
                            className="gap-4 w-full"
                            size="large"
                            defaultValue="Select Cash-Out Method"
                            onChange={(selectedOption) => {
                              handleCashoutMethodChange(selectedOption);
                            }}
                            options={cashoutMethodOptions}
                          />
                        </Form.Item>

                        {isNotEmptyOrNull(cashoutMethod) ? (
                          <Form.Item
                            name="payout-channel"
                            label={
                              <span className="text-white">
                                {payoutChannelType}
                              </span> 
                            }
                            validateStatus={payoutChannelError ? 'error' : ''}
                            help={payoutChannelError}
                          >
                            <Select
                              size="large"
                              placeholder="Select Payout Channel"
                              onChange={(selectedOption) => {
                                handlePayoutChannelChange(selectedOption);
                              }}
                              options={payoutChannelOptions}
                            />
                          </Form.Item>
                        ) : null}

                      </div>

                      <div className="flex flex-col w-full gap-5">

                        {isNotEmptyOrNull(payoutChannel) ? (
                          <Form.Item
                            className="flex-col-2 w-full"
                            name="account-name"
                            label={<span className="text-white">Account Name</span>}
                            validateStatus={accountNameError ? 'error' : ''}
                            help={accountNameError}
                          >
                            <Input.Group compact style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              <Input
                                placeholder="First Name"
                                size="large"
                                style={{ flex: 1 }}
                                type="text"
                                // onChange={(e) => {
                                //   setFirstName(e.target.value);
                                //   setAccountNameError('');
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z]+$/.test(value) || value === '') {
                                    setFirstName(value);
                                    setAccountNameError('');
                                  }
                                }}
                                value={firstName}
                              />

                              <Input
                                placeholder="Last Name"
                                size="large"
                                style={{ flex: 1 }}
                                type="text"
                                // onChange={(e) => {
                                //   setLastName(e.target.value);
                                //   setAccountNameError('');
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z]+$/.test(value) || value === '') {
                                    setLastName(value);
                                    setAccountNameError('');
                                  }
                                }}
                                value={lastName}
                              />
                            </Input.Group>
                          </Form.Item>
                        ) : null}

                        {isNotEmptyOrNull(payoutChannel) ? (
                          <Form.Item
                            name="account-number"
                            label={<span className="text-white">Account Number</span>}
                            validateStatus={accountNumberError ? 'error' : ''}
                            help={accountNumberError}
                          >
                            <Input
                              size="large"
                              style={{
                                width: "100%",
                              }}
                              type="number"
                              value={accountNumber}
                              onChange={handleAccountNumberChange}
                              
                            />
                          </Form.Item>
                        ) : null}
                      </div>

                      <div className="flex flex-column w-full gap-5">
                        {isNotEmptyOrNull(payoutChannel) ? (
                          <Form.Item
                            className="flex-grow w-full"
                            name="amount"
                            label={<span className="text-white">Amount</span>}
                            validateStatus={amountError ? 'error' : ''}
                            help={amountError}
                          >
                            <Input
                              min={200}
                              max={50000}
                              type="number"
                              placeholder="200 Min. - 50,000 Max"
                              size="large"
                              style={{
                                width: "100%",
                                
                              }}
                              // onChange={(e) => {
                              //   setAmount(e.target.value)
                              //   setAmountError("");
                              // }}
                              onChange={(e) => {
                                // Get the entered value
                                const inputValue = e.target.value;
                            
                                // Validate if it's a number
                                if (!isNaN(inputValue)) {
                                  // Convert the input value to a number
                                  const numericValue = parseFloat(inputValue);
                            
                                  // Check if it's within the desired range
                                  if (numericValue >= 200 && numericValue <= 50000) {
                                    setAmount(numericValue);
                                    setAmountError("");
                                  } else {
                                    // Handle the case where the entered value is outside the range
                                    setAmountError("Value must be between 200 and 50,000!");
                                    setAmount('');
                                  }
                                } else {
                                  // Handle the case where the entered value is not a valid number
                                  setAmountError("Please enter a valid number!");
                                }
                              }}
                              value={amount}
                            />
                          </Form.Item>
                        ) : null}
                      </div>

                      <div className="flex flex-column w-full gap-5">
                        {isNotEmptyOrNull(payoutChannel) ? (
                          <Form.Item
                            className="flex-grow w-full"
                            name="remarks"
                            label={<span className="text-white">Remarks</span>}
                            validateStatus={remarksError ? 'error' : ''}
                            help={remarksError}
                          >
                            <Input
                              placeholder="Optional"
                              size="large"
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setRemarks(e.target.value)
                                setRemarksError("");
                              }}
                              value={remarks}
                            />
                          </Form.Item>
                        ) : null}
                      </div>
                    </div>
                  </Form>

                  {isNotEmptyOrNull(payoutChannel) ? (
                    <div className="flex flex-col md:flex-row mt-5">
                      <button
                        type="primary"
                        htmltype="submit"
                        disabled={isLoading}
                        onClick={handleSumbitClick}
                        className="w-full cursor-pointer text-[18px] h-[55px] font-semibold bg-[#0274BE] hover:bg-blue-400 text-white border-0 rounded-lg md:max-w-[188px] duration-150"
                      >
                        {isLoading ? (
                          <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                          />
                          ) : (
                            "Withdraw"
                        )}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        {/* xlx */}
        <div>
          <Card > 
            <Table 
              className="min-h-[230px]"
              // bordered 
              dataSource={cashinRecord} 
              loading={pageLoading}
              columns={columns} 
              rowKey={"key"}
              scroll={{
                x: 1000,
                y: '100%',
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Cashout;
import React, {useState, useEffect} from "react";
import "./landing.css";
import { Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import Marquee from "react-fast-marquee";
import banner2 from "../../../assets/resources/images/banner-2.webp";
import banner5 from "../../../assets/resources/images/banner-5.webp";
import banner6 from "../../../assets/resources/images/banner-6.webp";
import announcements from "../../../assets/resources/icons/mwIcons/MONEYWIN ICONS-ANNOUNCEMENT.png";

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulating a loading delay
    setTimeout(() => {
      setIsLoaded(true);
    }, 20); // Adjust the delay time as needed
  }, []);


  const AnnouncementText = () => {
    return (
      <div>
        <p className="text-md flex items-center m-2">
        ~ MoneyWin: Where Every Win Sparks Victory! 🎰 Play Now for Thrilling Wins, Endless Excitement, and Instant Payouts! 💰 Your Winning Journey Begins Here!
        </p>
      </div>
    );
  };
  
  return (
    <div className={`bg-transparent max-w-[1800px] items-center mx-auto md:bg-transparent w-full transition-opacity duration-500 ${isLoaded ? "opacity-100" : "opacity-0"}`}>
      <div className="w-full lg:p-3 z-10">
        <div className="flex flex-col mb-0">
          <div className="w-[98%] order-last z-10 md:order-first h-[60px] mx-auto flex flex-row items-center text-white bg-opacity-5 opacity-90 bg-white mt-5 md:mt-2 mb-5 p-5">
            <Image preview={false} src={announcements} style={{ width: 40, height: 40, marginRight: 6}} />
            <Marquee autoFill>
              <AnnouncementText />
            </Marquee>
          </div>
          <div className="flex flex-row swiper-container items-center z-10 justify-center md:px-5">
            <Swiper
              breakpoints={{
                640: {
                  initialSlide: 1,
                  slidesPerView: 1,
                },
                767: {
                  initialSlide: 2,
                  slidesPerView: 2,
                },
                1025: {
                  // slidesPerView: 2,
                  initialSlide: 4,
                  slidesPerView: 4,
                },
              }}
              // className="rounded-lg relative"
              className="relative"
              // spaceBetween={80}
              spaceBetween={15}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              // loop
              loop={true}
              // initialSlide={3}
              // effect={"coverflow"}
              // slidesPerView={window.innerWidth > 1200 ? 2 : 1}
              slidesPerView={window.innerWidth > 1200 ? 4 : 1}
              navigation={true}
              // modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
              modules={[Autoplay, Pagination]}
              // coverflowEffect={{
              //   rotate: 0,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 2.5,
              // }}
            >
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner3} /> */}
                  <Image 
                    className="img-slide" preview={false} src={banner6} loading="lazy"
                   />
                </SwiperSlide>
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner2} /> */}
                  <Image className="img-slide" preview={false} src={banner2} loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner5} /> */}
                  <Image className="img-slide" preview={false} src={banner5} loading="lazy"/>
                </SwiperSlide>


                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner3} /> */}
                  <Image className="img-slide" preview={false} src={banner6} loading="lazy"/>
                </SwiperSlide>
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner2} /> */}
                  <Image className="img-slide" preview={false} src={banner2} loading="lazy"/>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner5} /> */}
                  <Image className="img-slide" preview={false} src={banner5} loading="lazy"/>
                </SwiperSlide>


                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner3} /> */}
                  <Image className="img-slide" preview={false} src={banner6} loading="lazy"/>
                </SwiperSlide>
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner2} /> */}
                  <Image className="img-slide" preview={false} src={banner2} loading="lazy"/>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner5} /> */}
                  <Image className="img-slide" preview={false} src={banner5} loading="lazy"/>
                </SwiperSlide>
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner3} /> */}
                  <Image className="img-slide" preview={false} src={banner6} loading="lazy"/>
                </SwiperSlide>
                
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner2} /> */}
                  <Image className="img-slide" preview={false} src={banner2} loading="lazy"/>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Image className="img-slide" preview={false} src={banner5} /> */}
                  <Image className="img-slide" preview={false} src={banner5} loading="lazy"/>
                </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/* {!token && (
          <div className="flex flex-col cards-container lg:flex-row mx-auto max-w-[1600px] h-full w-full gap-5 p-10">
            {steps.map((obj, key) => (
              <div
                key={key}
                className="text-white cards h-full min-h-[160px] flex flex-col md:flex-row text-center md:text-left items-center justify-evenly p-4 gap-3"
              >
                <div className="card-circle md:max-w-[96px] md:min-w-[96px] md:min-h-[96px] md:w-full text-textYellow font-bold text-2xl md:text-4xl flex items-center justify-center">
                  {obj.no}
                </div>
                <div className="flex flex-col gap-2 ">
                  <span className="font-black text-xl  md:text-2xl ">
                    {obj.title}
                  </span>
                  <span className=" text-lightGray text-[14px] md:text-[16px] tracking-wider font-regular">
                    {obj.desc}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Home;

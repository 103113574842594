import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { faqItems } from "../../../static/constants";
const Faq = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  return (
    <div id="accordionExample" className="flex flex-col justify-center items-center p-5 bg-primary gap-10 h-full w-full z-[99] text-white">
      <span className="text-white text-center text-[36px] font-semibold mb-5">
        Frequently Asked Questions
      </span>
      <div className="gap-3 flex flex-col bg-primary p-5 w-full max-w-[900px] mx-auto">
        <div className="gap-3 flex flex-col w-full">
          {
            faqItems.map((item, index) => (
              <div key={index}>
                <div
                  className={`flex items-center w-full justify-between ${
                    selectedItem === index ? 'bg-gradient-to-l from-[#92680C] to-[#FCDB9C] text-black' : ''
                  }`}
                  onClick={() => setSelectedItem(selectedItem === index ? null : index)}
                  style={{ 
                    border: "1px solid #FCDB9C",
                  }}
                >
                  <span className="m-5 text-[16px] font-semibold">{item.title}</span>
                  {selectedItem === index ? (
                    <CaretRightOutlined className="mx-5 text-[16px] border border-solid border-[#FCDB9C] text-white p-[6px] rounded-md" rotate={90} />
                  ) : (
                    <CaretRightOutlined className="mx-5 text-[16px] border border-solid border-[#FCDB9C] text-white p-[6px] rounded-md" />
                  )}
                </div>
                <div
                  id="collapseOne"
                  className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                    selectedItem === index ? "max-h-96" : "max-h-0"
                  }`}
                  data-te-collapse-item
                  data-te-collapse-show
                  aria-labelledby="headingOne"
                  data-te-parent="#accordionExample"
                  style={{
                    borderRight: "1px solid #FCDB9C",
                    borderLeft: "1px solid #FCDB9C",
                    borderBottom: "1px solid #FCDB9C",
                  }}
                >
                  <div className="py-8 px-4 text-justify">
                    <span className="text-[13px] leading-relaxed">{item.label}</span>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Faq;

import { useEffect , useState} from "react";
import { Divider, Switch, Tooltip } from 'antd';
import { agentSettings, displayedGames, getUserInfo } from '../../../services/moneywin/moneywin-api';
import { useSelector } from 'react-redux';

const Settings = () => {

  const token = useSelector((state) => state.auth.token);
  const [isAgentSettingsLoading, setIsAgentSettingsLoading] = useState(false);
  const [agentSettingsSwitchStates, setAgentSettingsSwitchStates] = useState([
    { key: 1, title: 'Allow Agents Accept Cash-In', value: 'can_agents_accept_cashin' , checked: false },
    { key: 2, title: 'Allow Agents Accept Cash-Out', value: 'can_agents_accept_cashout' , checked: false },
    { key: 3, title: 'Allow Super Agents Accept Cash-In', value: 'can_super_agents_accept_cashin' , checked: false },
    { key: 4, title: 'Allow Super Agents Accept Cash-Out', value: 'can_super_agents_accept_cashout' , checked: false },
  ]);
  const [isDisplayedGamesLoading, setIsDisplayedGamesLoading] = useState(false);
  const [displayedGamesSwitchStates, setDisplayedGamesSwitchStates] = useState([
    { key: 1, title: 'JILI', value: 'jili' , checked: true },
    { key: 2, title: 'SIMPLE PLAY', value: 'simpleplay' , checked: true },
    { key: 3, title: 'SA GAMING', value: 'sa' , checked: true },
  ]);

  const updateSwitchStates = (settings, switchStates) => {
    return switchStates.map(item => ({
      ...item,
      checked: settings.hasOwnProperty(item.value) ? Boolean(settings[item.value]) : true,
    }));
  };
  
  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getUserInfo(token);
        
      if (response.user_id && response.role_id) {
        const agentSettingsData = await agentSettings('get', response.user_id);
        if (agentSettingsData && agentSettingsData.agent_settings) {
          setAgentSettingsSwitchStates(updateSwitchStates(agentSettingsData.agent_settings, agentSettingsSwitchStates));
        }

        const displayedGamesData = await displayedGames('get', response.user_id);
        if (displayedGamesData && displayedGamesData.displayed_providers) {
          setDisplayedGamesSwitchStates(updateSwitchStates(displayedGamesData.displayed_providers, displayedGamesSwitchStates));
        }
      }
    };
    
    fetchSettings();
  }, [token]);


  const handleSwitchChange = async (index, switchStates, setSwitchStates, isLoading, setIsLoading, api) => {
    if (isLoading) {
      return;
    }
  
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index].checked = !updatedSwitchStates[index].checked;
  
    setSwitchStates(updatedSwitchStates);
  
    const userInfo = await getUserInfo(token);
  
    if (userInfo.user_id) {
      let response;
  
      const method = updatedSwitchStates[index].checked ? `enable_${updatedSwitchStates[index].value}` : `disable_${updatedSwitchStates[index].value}`;
  
      response = await api(userInfo, method);
      if (!response || !response.success) {
        updatedSwitchStates[index].checked = !updatedSwitchStates[index].checked;
        setSwitchStates(updatedSwitchStates);
      }
    }
  
    setIsLoading(false);
  };
  

  const getAgentSettings = async (userInfo, method) => {
    return await agentSettings(method, userInfo.user_id);
  }

  const getDisplayedGames = async (userInfo, method) => {
    return await displayedGames(method, userInfo.user_id);
  }

  return (
    <div className="z-[5] w-full p-5 md:p-0">
      <div className="flex flex-col md:px-10 gap-10 items-center justify-center pt-8 pb-10">
        <div className="flex h-full flex-col md:pb-18 pb-2 text-center items-center justify-center gap-5 z-[10]">
          <span className="text-2xl text-white font-medium">
            Settings
          </span>
        </div>
        <div className="w-full bg-white p-5 relative rounded-md">
          <span className="text-[12px] md:text-[12px] lg:text-[15px] font-medium">AGENT SETTINGS</span>
          {agentSettingsSwitchStates.map((item, index) => (
            <div key={index} className="flex justify-between items-center gap-5 lg:px-10 my-5 w-full">
              <div className="flex-none">
                <span className="text-[11px] md:text-[12px] lg:text-[15px]">{item.title}</span>
              </div>
              <div className="w-full"><Divider /></div>
              <div>
                <Tooltip title={item.checked ? "Disable" : "Enable"}>
                  <Switch checked={item.checked} onChange={() => handleSwitchChange(index, agentSettingsSwitchStates, setAgentSettingsSwitchStates, isAgentSettingsLoading, setIsAgentSettingsLoading, getAgentSettings)} />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full bg-white p-5 relative rounded-md">
          <span className="text-[12px] md:text-[12px] lg:text-[15px] font-medium">DISPLAYED GAMES</span>
          {displayedGamesSwitchStates.map((item, index) => (
            <div key={index} className="flex justify-between items-center gap-5 lg:px-10 my-5 w-full">
              <div className="flex-none">
                <span className="text-[11px] md:text-[12px] lg:text-[15px]">{item.title}</span>
              </div>
              <div className="w-full"><Divider /></div>
              <div>
                <Tooltip title={item.checked ? "Disable" : "Enable"}>
                  <Switch checked={item.checked} onChange={() => handleSwitchChange(index, displayedGamesSwitchStates, setDisplayedGamesSwitchStates, isDisplayedGamesLoading, setIsDisplayedGamesLoading, getDisplayedGames)} />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Settings
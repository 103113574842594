import React, { useState, useEffect } from "react";
import { Form, Input, Table, Spin, Card, Select, Modal, message} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { cancelQrPayment, createTopupQR, payoutStatus, topupWebPayment, webPaymentChannels } from "../../../services/paycools/paycools-api";
import { getTransactionRecord, getUserInfo } from "../../../services/moneywin/moneywin-api";
import { formatAsMoney, formatDate, capitalizeFirstLetter  } from '../../../services/util/utilities';
import { useSelector } from 'react-redux';

const MWCash = () => {

    const [topupLoading, setTopupLoading] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [paymentChannel, setPaymentChannel] = useState(null);
    const [accountName, setAccountName] = useState('');
    const [amount, setAmount] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [paymentLink, setPaymentLink] = useState("");
    const [mwCashRecord, setMWCashRecord] = useState("");
    const [qrModalVisible, setQrModalVisible] = useState(false);
    const [paymentLinkModalVisilbe, setPaymentLinkModalVisible] = useState(false);
    const [pendingQr, setPendingQr] = useState('');
    const [records, setRecords] = useState([]);
    const [form] = Form.useForm();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        const fetchData = async () => {
    
            const response = await getUserInfo(token);
            if (response && response.user_id) {
    
                const requests = await getTransactionRecord(response.user_id, "mw_cash");
                if (requests && requests.length > 0) {
                    setMWCashRecord(requests);
                    setRecords(requests);
                    checkPendingTransactions(requests);
                }
            }else if(response.timed_out || response.request_failed){
                message.error({ content: "Unable to retrieve mw cash top-up history. Please check your internet connection or try again later." , key: 'custom_message' });
            }
        }
    
        const checkPendingTransactions = async (requests) => {
            if (requests && requests.length > 0) {
                const lastRecord = requests[0];
                if (lastRecord.status === "pending payment" || lastRecord.status === "pending") {
                    await payoutStatus(lastRecord.transaction_reference, lastRecord.status);
                }
            }
        };
    
        fetchData();
    
        const interval = setInterval(fetchData, 10000);
        
        return () => {
          clearInterval(interval);
        };
    }, [token]);

    useEffect(() => {
        const fetchWebPaymentChannels = async () => {
            try {
              const response = await webPaymentChannels(); 
              if (response.code === 1000 && response.data) {
                const formattedOptions = response.data.map((option) => ({
                  value: option.channelCode,
                  label: option.channelName.split('-')[0].trim(),
                }));
        
                 formattedOptions.push({
                    value: "qr_ph",
                    label: "Pay via QR",
                  });
        
                setPaymentOptions(formattedOptions);
              }
            } catch (error) {
              console.error("Error fetching options:", error);
            }
        };
        
        fetchWebPaymentChannels();
    }, );

    const handleTopupConfirm = async () => {
        if(!paymentChannel && !accountName && !accountNumber && !amount){
            message.error({ content: "Please enter all required information!", key: 'custom_message' });
            return;
        }
        setTopupLoading(true);
        if (paymentChannel === null) {
            message.error({ content: "Please select a payment channel!", key: 'custom_message' });
            setTopupLoading(false);
            return;
        }
    
        if (!accountName && paymentChannel !== "qr_ph") {
            message.error({ content: "Please enter account name!", key: 'custom_message' });
            setTopupLoading(false);
            return;
        }
    
        if (!accountNumber && paymentChannel !== "qr_ph") {
            message.error({ content: "Please enter account number!", key: 'custom_message' });
            setTopupLoading(false);
            return;
        }
    
        if (!amount) {
            message.error({ content: "Please enter a valid amount!", key: 'custom_message' });
            setTopupLoading(false);
            return;
        } else if (isNaN(amount)) {
            message.error({ content: "Invalid amount!", key: 'custom_message' });
            setTopupLoading(false);
            return;
        }
    
        const userInfo = await getUserInfo(token);
        if (userInfo && userInfo.user_id) {
            try {
        
                if(paymentChannel === "qr_ph"){
                    const qrCode = await createTopupQR(userInfo.user_id, "MoneyWin Agent", amount)
                    if (qrCode && qrCode.message === "success") {
                        setPaymentLink(qrCode.data.qrLink);
                        setPendingQr(qrCode.data.mchOrderId);
                        setQrModalVisible(true);
                        handleClearClick();
                    }
        
                }else{
                    const webPayment = await topupWebPayment(
                        userInfo.user_id,
                        amount,
                        accountName,
                        userInfo.email,
                        accountNumber,
                        paymentChannel
                    );

                    // console.log(webPayment);
        
                    if (webPayment.message === "success") {
                        setPaymentLink(webPayment.data.redirectUrl);
                        window.open(webPayment.data.redirectUrl);
                        // console.log(webPayment);
                        handleClearClick();
                        setPaymentLinkModalVisible(true);
                    } else {
                        // console.log(webPayment.message);
                        let errorMessage = webPayment.message.split(':')[1].trim(); 
                        errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
                        errorMessage += '.';

                        if (webPayment.message.includes("mobile")) {
                            // Capitalize the first letter
                            // Add a dot at the end
                            
                            // Extract error message
                            message.error({ content: errorMessage, key: 'custom_message' });
                        }else{
                            message.error({ content: "Top-Up WinCash request failed: " + errorMessage , key: 'custom_message' });
                        }
                        setTopupLoading(false);
                    }
                }
                
            } catch (error) {
                setTopupLoading(false);
                message.error({ content: "Top-Up WinCash request failed." , key: 'custom_message' });
                console.error("Error creating web payment:", error);
            }
        }
    
        setTopupLoading(false);
    }

    const handlePaymentChannelChange = (selectedOption) => {
        setPaymentChannel(selectedOption);
    }

    const handleCancelQrPayment = async () => {
        const cancel = await cancelQrPayment(pendingQr);
        if(cancel.message === "success"){
          handleQrModalClose();
        }
    }
    
    const handleModalClose = () => {
        // Close the modal and clear any error messages
        setPaymentLinkModalVisible(false);
        setPendingQr('');
        // Add other error states here if needed
    };

    const handleQrModalClose = () => {
        // Close the modal and clear any error messages
        setQrModalVisible(false);
        setPaymentLink("");
        // Add other error states here if needed
    };

    const handleClearClick = () => {
     
        setAccountName('');
        setAccountNumber('');
        setAmount('');
        setPaymentChannel(null);
        form.resetFields(['name']);
        form.resetFields(['amount']);
        form.resetFields(['mobile']);
        form.resetFields(['transaction-reference']);
        form.resetFields(['payment-channel']);
    }
    const uniqueNames = [...new Set(records.map(item => item.status))];
    const filters = uniqueNames.map(status => ({
      text: status.charAt(0).toUpperCase() + status.slice(1),
      value: status,
    }));
    const mwCashColumns = [
        {
            key: 'created_at',
            title: 'Date Request',
            dataIndex: 'created_at',
            align: 'center',
            width: 260,
            render: (text) => {
                return <span>{formatDate(text)}</span>
            },
        },
        {
            key: 'payment_channel',
            title: 'Payment Channel',
            dataIndex: 'payment_channel',
        },
        {   
            key: 'transaction_reference',
            title: 'Reference Number',
            dataIndex: 'transaction_reference',
        },
        {
            key: 'amount',
            title: 'Amount',
            dataIndex: 'amount',
            render: (text) => (<span>{formatAsMoney(text)}</span>)
        },
        {
            key: 'status',
            title: 'Status',
            dataIndex: 'status',
            render: (type, record) => {
                let statusColor;
                if(record.status === "pending" || record.status === "pending payment"){
                  statusColor = 'text-yellow-300';
                }
                if(record.status === "completed"){
                  statusColor = 'text-green-500';
                }
                if(record.status === "failed"){
                  statusColor = 'text-red-500';
                }
                if(record.status === "cancelled"){
                  statusColor = 'text-gray-400';
                }
                if(record.status === "declined"){
                  statusColor = 'text-gray-400';
                }
                return (
                //   <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
                  <span className={`${statusColor} font-semibold`}>{capitalizeFirstLetter(record.status)}</span>
                )
            },
            filters,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            key: 'remarks',
            title: 'Remarks',
            dataIndex: 'remarks',
        },
    ];
  return (
    <div className="flex flex-col w-full h-full bg-primary p-5 md:p-10 gap-5">
        {/* <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[0]"></div> */}
        <div className="max-w-[800px] bg-gray-800 rounded-lg gap-5 w-full text-white text-[15px] z-20 flex flex-col p-5 md:p-10 justify-center mx-auto items-center">

            <Modal
                open={qrModalVisible}
                onClose={handleQrModalClose}
                onCancel={handleCancelQrPayment}
                onOk={handleQrModalClose}
                title="Payment"
            >
                <iframe
                    className="w-full h-full"
                    title="Payment"
                    src={paymentLink}
                    style={{ width: '100%', height: '500px' }} // Adjust dimensions as needed
                ></iframe>
            </Modal>
            <Modal
                title="Payment"
                open={paymentLinkModalVisilbe}
                onCancel={handleModalClose}
                footer={null}
            >
                {/* Display the payment link here */}
                <p>
                If you are not automatically redirected,{' '}
                <a href={paymentLink} target="_blank" rel="noopener noreferrer">
                    click here
                </a>{' '}
                to proceed with the payment.
                </p>
                {/* You can customize the content of the modal as needed */}
            </Modal>

            <span className="text-textYellow text-2xl">Top-Up WinCash</span>
            <div className="flex flex-col w-full gap-2 mt-10">
                <span>Select Payment Channel:</span>
                <Select 
                    name="payment-channel"
                    size="large"
                    placeholder="Select Payment Channel"
                    allowClear    
                    onChange={(selectedOption) => {
                        handlePaymentChannelChange(selectedOption);
                    }}
                    options={paymentOptions}
                />
            </div>
            {paymentChannel !== "qr_ph" ? (
                <>
                    <div className="flex flex-col w-full gap-2">
                        <span>Account Name:</span>
                        <Input 
                            size="large"
                            placeholder="Enter Account Name"
                            allowClear   
                            onChange={(e) => {
                                setAccountName(e.target.value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                        <span>Account Number:</span>
                        <Input 
                            size="large"
                            placeholder="Enter Account Number"
                            allowClear
                            onChange={(e) => {
                                setAccountNumber(e.target.value)
                            }}  
                        />
                    </div>
                    
                </>
            ) : null}
            <div className="flex flex-col w-full gap-2">
                <span>Amount:</span>
                <Input 
                    name="amount"
                    size="large"
                    placeholder="Enter Amount"
                    allowClear  
                    onChange={(e) => {
                        setAmount(e.target.value)
                    }}    
                />
            </div>
            
            <div 
                className="w-full md:w-[250px] bg-blue-500 hover:bg-blue-600 duration-150 cursor-pointer flex justify-center items-center rounded-md h-[50px]"
                onClick={handleTopupConfirm}
            >
                <span>
                    {topupLoading ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />} />
                    ) : (
                        paymentChannel === "qr_ph" ? "Generate QR" : "Confirm"
                    )}
                </span>
            </div>
        </div>
        <div className="rounded-lg bg-white">
            <Card
                title="WinCash"
            >
                <Table
                    dataSource={mwCashRecord}
                    columns={mwCashColumns}
                    scroll={{
                        x:1300,
                        y:600
                    }}
                />
            </Card>
        </div>
    </div>
  )
}

export default MWCash
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  currentUser: [],
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token; // Set the token from the action payload
      localStorage.removeItem("gameData");
    },
    logout(state) {
      state.currentUser = null;
      state.isAuthenticated = false;
      state.token = null;
      localStorage.removeItem("gameData");
    }, 
    checkAuth(state, action) {
      const { isAuthenticated, currentUser } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.currentUser = currentUser;
    },
    loginError(state, action) {
      state.error = action.payload; // Set the error message in the state
      state.isAuthenticated = false; // Set authentication status to false
      state.currentUser = null; // Reset current user data
      state.token = null; // Reset token
    },
  },
});

export const { login, loginError, logout, checkAuth } = authSlice.actions;
export default authSlice.reducer;
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Image, Modal, Button } from "antd";
import { Spin } from "antd";
import { CloseOutlined, Loading3QuartersOutlined, ExpandOutlined} from "@ant-design/icons";
import { loginWithoutRedirect } from "../../../services/jili/jili-api";
import "./game.css";
import jili from "../../../assets/resources/images/jili_icon.png";
import sa from "../../../assets/resources/images/sa_small.png";
import simple from "../../../assets/resources/images/sp_small.png";
import { spLoginRequest } from "../../../services/sp/sp-api";
import { saLoginRequest } from "../../../services/sa/sa-api";
import { getUserInfo } from "../../../services/moneywin/moneywin-api";
import { isMobile as isDeviceMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { showTimedMessage } from "../../../services/util/utilities";


const Gameview = () => {
  const [expanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const gameId = location.state?.gameId;
  const gameProvider = location.state?.gameProvider;
  const gameName = location.state?.gameName;
  const [loading, setLoading] = useState(true);
  const [gameLink, setGameLink] = useState(null);
  const [showExit, setShowExit] = useState(false);
  const [fullscreen, setFullScreen] = useState(false);
  const [suggestFullScreen, setSuggestFullScreen] = useState(false);
  const [topIcon, setTopIcon] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [iframeUrlHistory, setIframeUrlHistory] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [message, messageHolder] = Modal.useModal();
  
  //const isMobileLandscapeBreakPoint = 1280;
  //const isMobileLandscape = window.innerWidth <= isMobileLandscapeBreakPoint;


  // Call the function when the component mounts
  useEffect(() => {
    async function lockScreenOrientation(orientation) {
      if ('screen' in window && 'orientation' in window.screen) {
        try {
          await window.screen.orientation.lock(orientation);
          console.log(`Screen locked to ${orientation} orientation.`);
        } catch (error) {
          console.error('Failed to lock the screen orientation:', error);
        }
      } else {
        console.warn('Screen orientation lock not supported in this browser.');
      }
    }

    if(gameProvider === "JILI"){
      lockScreenOrientation('portrait');
    }
    
  }, [gameProvider]);
  
  // Adding event listener to handle browser back button press
  window.addEventListener('popstate', function(event) {
    handleBackClick();
  });

  // Remember to clean up the event listener on component unmount
  useEffect(() => {
    const handleMobileBackButton = (event) => {
      event.preventDefault();
      handleBackClick();
    };

    window.addEventListener('beforeunload', handleMobileBackButton);

    return () => {
      window.removeEventListener('beforeunload', handleMobileBackButton);
    };
  }, []);

  useEffect(() => {
    const checkIframeFullScreen = () => {
      const iframe = iframeRef.current;
  
      if (
        iframe &&
        (iframe === document.fullscreenElement ||
          iframe === document.webkitFullscreenElement ||
          iframe === document.msFullscreenElement)
      ) {
        // The iframe is in full-screen mode
        return true;
      } else {
        // The iframe is not in full-screen mode
        return false;
      }
    };
  
    if (gameLink && isDeviceMobile && !checkIframeFullScreen()) {
      setSuggestFullScreen(true);
    }
  }, [gameLink]);

  useEffect(() => {
    function isMobileDevice() {
      return window.matchMedia('(max-width: 767px)').matches || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    // Check on initial load
    setIsMobile(isMobileDevice());
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, );

  useEffect(() => {
    const fetchData = () => {
      getUserInfo(token)
        .then(response => {
          if (response.user_id) { 
            if (!gameLink) {
              if (gameProvider === "JILI") {
                setTopIcon(jili);
                return JILIgetGameLink(token, response.username);
              } else if (gameProvider === "SIMPLE_PLAY") {
                setTopIcon(simple);
                return SPGetGameLink(token, response.username);
              } else if (gameProvider === "SA") {
                setTopIcon(sa);
                return SAGetGameLink(token, response.username);
              }
            }
          }else if(response.timed_out || response.request_failed){
            showTimedMessage(
              message,
              "Oops! Your network seems unstable.",
              "Please check your internet connection and refresh the page to continue loading the game.",
              "Ok",
              5,
              false
            );
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const JILIgetGameLink = (token, username) => {
      if (token && gameId) {
        // console.log(gameId);
        return loginWithoutRedirect(token, gameId, 'en-US', username)
          .then(response => {
            setGameLink(response);
          })
          .catch(error => {
            console.log("jili : " + error);
          });
      } else {
        console.log("Invalid token or game ID.");
        return Promise.reject("Invalid token or game ID.");
      }
    };

    const SPGetGameLink = (token, username) => {
      if (gameId) {
        return spLoginRequest(username, 'PHP', gameId, 'en-US', isMobile ? 1 : 0)
          .then(response => {
            setGameLink(response);
          })
          .catch(error => {
            console.log("sp : " + error);
          });
      } else {
        return spLoginRequest(username, 'PHP', null, null, isMobile ? 1 : 0)
          .then(response => {
            setGameLink(response);
          })
          .catch(error => {
            console.log("sp : " + error);
          });
      }
    };
    
    const SAGetGameLink = (token, username) => {
      if (gameId) {
        return saLoginRequest(username, 'PHP', gameId, 'en-US', isMobile ? 1 : 0)
          .then(response => {
            setGameLink(response);
          })
          .catch(error => {
            console.log("sa : " + error);
          });
      } else {
        return saLoginRequest(username, 'PHP', null, null, isMobile ? 1 : 0)
          .then(response => {
            setGameLink(response);
          })
          .catch(error => {
            console.log("sa : " + error);
          });
      }
    };

    fetchData();

  }, );

  const iframeRef = useRef(null);

  const openFullscreen = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe && iframe.webkitRequestFullscreen) { /* Safari */
      iframe.webkitRequestFullscreen();
    } else if (iframe && iframe.msRequestFullscreen) { /* IE11 */
      iframe.msRequestFullscreen();
    }
  };

  const handleBackButton = () => {
    const previousUrl = iframeUrlHistory.pop();
    if (previousUrl) {
      setIframeUrlHistory([...iframeUrlHistory]);
      iframeRef.current.src = previousUrl;
    } else {

    }
  };

  const handleBackClick = () => {
    setShowExit(true);
  }

  const cancelModal = () => {
    setShowExit(false);
    setFullScreen(false);
    setSuggestFullScreen(false);
  };

  const confirmExit = () => {
    navigate('/');
  };

  const confirmFullSreen = () => {
    openFullscreen();
    setFullScreen(false);
    setSuggestFullScreen(false);
  }

  const handleFullScreenClick = () => {
    setFullScreen(true);
  }

  return (
    <div
      className={`md:grid ${
        expanded ? 'grid-cols-1' : 'grid-cols-1'
      } w-full gap-4 h-full bg-primary  ${isMobile ? 'pl-2 pr-2' : 'p-5'}`}
    >
      {messageHolder}
      <div className="col-span-3">
      <Modal
        open={showExit || fullscreen || suggestFullScreen}
        title={
          showExit
            ? "Exit Game?"
            : fullscreen
            ? "Enter full screen mode?"
            : suggestFullScreen
            ? "Our system detected that you are running on a mobile phone. Would you like to switch to full-screen mode for the full experience?"
            : "Reload game?"
        }
        onOk={
          showExit
            ? confirmExit
            : fullscreen || suggestFullScreen
            ? confirmFullSreen
            : null
        }
        onCancel={cancelModal}
        okText={
          showExit
            ? "Yes"
            : fullscreen || suggestFullScreen
            ? "Full Screen Mode"
            : "Yes"
        }
        cancelText={
          showExit
            ? "No"
            : fullscreen
            ? "Cancel"
            : "Decline"
        }
      >
        {/* Modal content */}
      </Modal>
        <Card
          className="game-view border-0 h-full bg-primary"
          title={
          <div className="flex justify-between bg-[#262C3D] rounded-t-md h-[67px] items-center text-white w-full p-5">
            <div className="flex gap-4 items-center">
              <CloseOutlined
                className="text-lg cursor-pointer"
                onClick={() => handleBackClick()}
              />
              <div className="flex gap-2 items-center">
                <div>
                  <Image
                    preview={false}
                    src={topIcon}
                    className="max-w-[105px] h-auto"
                  />
                </div>
                <h1 className="mb-0">{gameName}</h1>
              </div>
            </div>
            {/*  
            <ArrowsAltOutlined
              onClick={() => setExpanded(!expanded)}
              className="text-lg cursor-pointer"
            />
            */}
          </div>}
        >
          {loading ? (
            <div className={`flex justify-center items-center w-full ${isMobile ? 'h-screen' : 'h-[600px]'}`}>
              <div className="flex items-center justify-center h-full w-full">
                <p className="text-white text-xl">Game Initializing...</p>
              </div>
            </div>
          ) : (
            <div class="iframe-container">
              {/* {isGameClicked ? <div className={`md:hidden flex justify-center items-center fixed z-[1000] text-[20px] top-0 right-5 rotate-90 text-white`} onClick={() => handleBackClick()}>X</div> : null} */}

              {iframeLoading && (
                <div className={`flex justify-center items-center w-full ${isMobile ? 'h-screen' : 'h-[600px]'}`}>
                  <Spin  indicator={<Loading3QuartersOutlined style={{ fontSize: 35, color: 'white' }} spin />} />
              </div>
              )}

              <iframe
                ref={iframeRef}
                className={`iframe-without-scrollbar w-full h-full`}
                // className="w-screen relative h-screen md:w-full md:h-full"
                src={gameLink}
                title="Game"
                style={{
                  minHeight: '80vh',
                  border: 'none',
                  display: iframeLoading ? 'none' : 'block',
                }}
                onLoad={() => setIframeLoading(false)}
                onError={(e) => console.log('Frame error', e)}
                // onClick={() => setIsGameClicked(true)}
              />

              {isMobile ? 
              (
                <div>
                  <Button 
                    onClick={() => handleFullScreenClick()} 
                    type="primary" 
                    className="full-screen-button"
                    shape="circle" // Set the shape to circle
                    size="middle" // Adjust the size as needed
                    icon={<ExpandOutlined />} // Use the CloseOutlined icon
                    >

                  </Button>
                </div>
                
              ) 
              : null};
            </div>
          )}
        </Card>
      </div>
       {/*  
        {!expanded && (
          <div className="col-span-1">
            <Profits expanded={expanded} />
          </div>
        )} 
      */}
     
    </div>
  );
};

export default Gameview;
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
// import createSagaMiddleware from "@redux-saga/core";
// import authSaga from "./sagas/auth-saga";
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/auth-slice';

const authPersistConfig = {
  key: 'auth',
  version: 1,
  storage,
  whitelist: ['auth', 'token', 'currentUser', 'isAuthenticated'], // Include the 'auth' reducer in the whitelist
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice),
});

export const authActions = authSlice.actions;

// Create the store using configureStore with the rootReducer
const store = configureStore({
  reducer: rootReducer,
});

// Create the persistor using persistStore with the store
export const persistor = persistStore(store);

export default store;
import React, { useState } from 'react'
import {Modal, Form, Image, Input, Spin, message } from "antd";
import { useLocation, useNavigate} from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { updatePassword } from '../../../services/moneywin/moneywin-api';
import { useEffect } from 'react';
import mwLogo from "../../../assets/resources/logos/mw-logo-signup.png";
import { desDecrypt, showTimedMessage, showTimedMessageWithAction } from '../../../services/util/utilities';
import ForgetPassword from '../../../components/Unauthenticated/Login/ForgetPassword';
const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, messageHolder] = Modal.useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [userID, setUserID] = useState('');
    const mobileBreakpoint = 767;
    const [isResetPassword, setIsResetPassword] = useState(false);
    const isMobile = window.innerWidth <= mobileBreakpoint;
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const myUserID = decodeURIComponent(queryParams.get('id'));
        try {
            if (myUserID) {
                // const decryptedBytes = AES.decrypt(myUserID, process.env.REACT_APP_ENCRYPT_KEY);
                // const decrypted = decryptedBytes.toString(enc.Utf8);
                const decrypted =  desDecrypt(decodeURIComponent(myUserID));
                const idMatch = decrypted.match(/\[(\d+)\]/);
                const id = idMatch ? idMatch[1] : null;

                // Extract date using regular expression
                const dateMatch = decrypted.match(/\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\]/);
                const date = dateMatch ? dateMatch[1] : null;
                if (decrypted) {
                    // console.log(decrypted);
                    setUserID(id);
                    const dateString = new Date(date);
                    const currentDate = new Date();
                    const differenceInMilliseconds = currentDate - dateString;
                    const isDateValid = differenceInMilliseconds <= 300000;
                    if(isDateValid){
                        setIsResetPassword(true);
                    } else {
                        setIsResetPassword(false);
                    }
                } else {
                    navigate('/');
                    message.error({ content: "An error has occured. Please try again later.", key: 'custom_message' });
                    console.error('Decryption failed: Empty result');
                }
            } else {
                navigate('/');
                message.error({ content: "An error has occured. Please try again later.", key: 'custom_message' });
                console.error('Decryption failed: Empty or undefined myUserID');
            }
        } catch (error) {
            navigate('/');
            message.error({ content: "An error has occured. Please try again later.", key: 'custom_message' });
            console.error('Error during decryption:', error);
        }
    }, [location]);

    const handleConfirm = async () => {
        if(!password)
        {
            setPasswordError('Please enter your new password.');
            return;
        }

        if(password.length < 8){
            setPasswordError('Password must be at least 8 characters long.');
            return;
        }

        if(!confirmPassword)
        {
            setConfirmPasswordError('Please confirm your new password.');
            return;
        }
        if(password !== confirmPassword)
        {
            setPasswordError(' ')
            setConfirmPasswordError('The passwords entered do not match.')
            return;
        }
        if(password === confirmPassword)
        {
            setIsLoading(true);
            setPasswordError(null);
            setConfirmPasswordError(null);
            // const decrypted = AES.decrypt(user_id, process.env.REACT_APP_ENCRYPT_KEY).toString(enc.Utf8);
            // console.log('Decrypt:',decrypted);
            // if(decrypted)
            if(userID)
            {
                const update = await updatePassword(userID, null, password);
                if(update){
                    if(update.success){
                        showTimedMessageWithAction(message, "Success!", "Password updated succesfully.", "Login", 5, true, "success", async () => {
                            if(isMobile){
                                navigate('/login');
                            } else {
                                navigate('/');
                            }
                        });
                    }else if(update.count_error){
                        showTimedMessage(message, "Failed", update.count_error, "Close", 5, false);
                    }
                    
                }
            }
            setIsLoading(false);
        }
    };
  return (
    isResetPassword ? (
        <div className="w-full h-full bg-primary md:bg-pro-bg md:bg-cover md:bg-center flex items-center justify-center md:py-[210px] p-6">
            <div className="flex flex-col justify-between h-full w-full py-5 max-w-[508px] md:justify-center">
                <div className="bg-primary md:bg-white rounded-[30px] border-0 relative md:p-10 h-auto">
                    <div className="">

                        {messageHolder}
                        {/* <div className="w-[198px] md:w-[341px] md:absolute md:top-[-190px]">
                            <Image width={"100%"} preview={false} src={loginLogo} />
                        </div> */}
                        <div className="text-white flex flex-col items-center gap-4 w-full md:pt-12 h-full">
                            <div className="md:w-[341px] md:absolute mx-auto flex items-center justify-center md:text-center md:top-[-90px]">
                                <Image width={"100%"} preview={false} src={mwLogo} style={{ objectFit: 'fill', height: 165, width: 250,}}/>
                            </div>
                            <span className="font-semibold text-[20px] text-white md:text-[28px] md:text-textDarkGray pt-6">
                            Reset Password
                            </span>
                            <span className='text-white md:text-textDarkGray'>
                                Please enter your new password.
                            </span>
                            <Form
                                className="w-full mt-[50px]"
                                layout="vertical"
                                name="reset-password"
                                initialValues={{
                                    remember: true,
                                }}
                                autoComplete="off"
                            >
                                <div className="w-full">
                                    <Form.Item
                                        className="login-input "
                                        label={
                                            <span className="label-style md:text-black">
                                            Password
                                            </span>
                                        }
                                        name="password"
                                        rules={[
                                            {
                                            required: true,
                                            message: "Please enter your password!",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  if (!value || value.length >= 8) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject(
                                                    new Error("Password must be at least 8 characters long.")
                                                  );
                                                },
                                              }),
                                        ]}    
                                        hasFeedback
                                        validateStatus={passwordError ? "error" : ""}
                                        help={passwordError}
                                    >
                                        <Input.Password
                                                placeholder="Password"
                                                size="large"
                                                style={{
                                                height: "56px",
                                                padding: "10px",
                                                fontSize: "20px",
                                                borderRadius: "12px",
                                                fontSize: "16px",
                                            }}
                                            tabIndex={6}
                                            onChange={(e) => {
                                            setPassword(e.target.value)
                                            setPasswordError("");
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <div className="w-full">
                                    <Form.Item
                                    className="login-input "
                                    label={
                                        <span className="label-style md:text-black">
                                        Confirm Password
                                        </span>
                                    }
                                    name="confirm-password"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Please enter your password!",
                                        },
                                    ]}   
                                    hasFeedback
                                    validateStatus={confirmPasswordError ? "error" : ""}
                                    help={confirmPasswordError}
                                    >
                                    <Input.Password
                                        placeholder="Confirm Password"
                                        size="large"
                                        style={{
                                        height: "56px",
                                        padding: "10px",
                                        fontSize: "20px",
                                        borderRadius: "12px",
                                        fontSize: "16px",
                                        }}
                                        tabIndex={7}
                                        onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                        setConfirmPasswordError("");
                                        }}
                                    />
                                    </Form.Item>
                                </div>

                                <div className='flex flex-col md:flex-row'>
                                    <div className="w-full flex items-center justify-center">
                                        <button
                                            type="primary"
                                            htmltype="submit"
                                            className="w-full h-[55px] text-[22px] font-semibold bg-gradient-to-t from-[#92680C] to-[#FCDB9C] duration-150 hover:scale-[1.05] transition-all ease-in mx-1 mt-[50px] drop-shadow-lg cursor-pointer text-white border-0 rounded-lg md:max-w-[285px]"
                                            disabled={isLoading}
                                            onClick={handleConfirm}
                                        >
                                            {isLoading ? (
                                                <Spin
                                                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                                                />
                                            ) : (
                                                "Confirm"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <ForgetPassword reset={true}/>
    )
  )
}
export default ResetPassword
import {Checkbox, Divider, Form, Image, Input, Spin, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useState} from "react";
import React from "react";
import "./login.css";
import { authenticateUser} from "../../../services/moneywin/moneywin-api";
import mwLogo from "../../../assets/resources/logos/mw-logo-signup.png";
import { useSelector, useDispatch } from 'react-redux';
import { login } from "../../../store/slices/auth-slice";



const Login = ({navigate}) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  const onFinish = async () => { 
    setAuthError(""); 
    if(!username && !password){
      return;
    }
    setIsLoading(true);

    const userInfo = await authenticateUser(username, password, "PHP");
    
    if (userInfo.username) {
      window.scrollTo(0, 0); 

      const loginPayload = {
        isAuthenticated: true,
        token: userInfo.token,
        currentUser: {
          username: userInfo.username,
          userId: userInfo.user_id,
          roleId: userInfo.role_id
        },
      };
    
      dispatch(login(loginPayload));

      scrollToTop();
      if (currentUser && currentUser.roleId < 5) {
        navigate("/backoffice");
      } else {
        navigate("/"); 
      }

      if(userInfo.role_id === 5){
        navigate("/", { state: { role_id: userInfo.role_id, amount: userInfo.amount , bonus: userInfo.bonus ,username: userInfo.username, user_id: userInfo.user_id} });
      }else{
        navigate("/backoffice", { state: { role_id: userInfo.role_id, user_id: userInfo.user_id } });
      }
    }else if(userInfo.timed_out || userInfo.request_failed){
      message.error({ content: "Please check your internet connection or try again later." , key: 'custom_message' });
    }else{
      setAuthError(' ');
      message.error({ content: userInfo.message, key: 'custom_message' });
    }

    setIsLoading(false); 
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  
  const onFinishFailed = (errorInfo) => {
    message.error({ content: "An error has occured. Please try again later." , key: 'custom_message' });
    setIsLoading(false); 
  };

  return (
    <div className="w-full h-full bg-primary md:bg-pro-bg md:bg-cover md:bg-center flex items-center justify-center md:py-[210px] p-6">
      <div className="flex flex-col justify-between h-full w-full py-5 max-w-[508px] md:justify-center">
        <div className="bg-primary md:bg-white rounded-[30px] border-0 text-white relative md:p-10 h-auto">
          <div className="flex flex-col items-center gap-4 w-full md:pt-12 h-full">
            {/* <div className="w-[198px] md:w-[341px] md:absolute md:top-[-190px]">
              <Image width={"100%"} preview={false} src={loginLogo} />
            </div> */}
            <div className="md:w-[341px] md:absolute mx-auto flex items-center justify-center md:text-center md:top-[-90px]">
              <Image width={"100%"} preview={false} src={mwLogo} style={{ objectFit: 'fill', height: 165, width: 250,}}/>
            </div>
            <span className="font-semibold text-[18px] text-white md:text-[28px] md:text-textDarkGray pt-1">
              Sign In to Your Account
            </span>

            <Form
              className="w-full"
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
                username: '',
                password: '',
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >

              <Form.Item
                className="login-input"
                label={
                  <span className="label-style text-lg md:text-black">Username</span>
                }
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username!",
                  },
                ]}
                hasFeedback
                validateStatus={authError ? "error" : ""}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  style={{
                    height: "46",
                    padding: "10px",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => {
                    setUsername(e.target.value)
                    setAuthError("");
                  }}
                  autoComplete="username" 
                />
              </Form.Item>

              <Form.Item
                className="login-input "
                label={
                  <span className="label-style text-lg md:text-black">Password</span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                hasFeedback
                validateStatus={authError ? "error" : ""}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  style={{
                    height: "46",
                    padding: "10px",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setAuthError("");
                    }}
                    autoComplete="current-password" 
                />
              </Form.Item>

              <div className="flex items-center justify-between ">
                <Form.Item name="remember">
                  <Checkbox 
                    className="text-textGray"
                  >
                      Remember me
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <span 
                    // className="text-textGray hover:text-red-500 duration-200 underline cursor-pointer"
                    className="text-blue-500 duration-200 hover:underline cursor-pointer"
                    onClick={() => navigate("/recover")}
                  >Forgot your password?</span>
                </Form.Item>
              </div>

              <div className="w-full flex items-center justify-center">
                <button
                  type="primary"
                  htmltype="submit"
                  className="w-full h-[55px] text-[22px] font-semibold hover:bg-yellow-600 bg-[#FFCE29] shadowed gradient-button duration-300 cursor-pointer text-white border-0 rounded-lg md:max-w-[285px]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                    />
                  ) : (
                    <span className="outlined-text text-3xl">Sign In</span>
                  )}
                </button>
              </div>
            </Form>
            <div className="md:flex hidden">
              <span className="text-black">
                Don’t have an account?&nbsp;
                <span
                  className="text-buttonYellow cursor-pointer hover:underline"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up!
                </span>
              </span>
            </div>

            <div className="flex justify-evenly md:hidden pt-5">
              <span className="text-textGray tracking-wide">
                Don't have an account?
              </span>
              <Divider type="vertical" className="bg-white mt-1" />
              <span
                className="text-[#FCDB9C] font-semibold cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                Sign Up!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

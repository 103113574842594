import moneyWinConfig from "../moneywin/moneywin-config";

const spConfig = {
    MD5_KEY: process.env.REACT_APP_MD5_KEY,
    ENCRYPT_KEY: process.env.REACT_APP_ENCRYPT_KEY,
    LOBBY_CODE: process.env.REACT_APP_SP_LOBBY_CODE,

    SECRET_KEY: moneyWinConfig.ENVIRONMENT === 'TEST' ? process.env.REACT_APP_SP_TEST_SECRET_KEY  : process.env.REACT_APP_SP_PROD_SECRET_KEY,

  };

  export default spConfig;    
import paycoolsConfig from './paycools-config';
import moneyWinConfig from '../moneywin/moneywin-config';
import { KJUR, KEYUTIL } from 'jsrsasign';
import { Buffer } from 'buffer'

export const ewalletOptions = [
  { value: 'GCASH', label: 'Gcash' },
  { value: 'COINS', label: 'Coins' },
  { value: 'PAYMAYA', label: 'Paymaya' },
  { value: 'GRABPAY', label: 'GrabPay' },
  { value: 'SHOPEE', label: 'Shopee' },
  { value: 'OMNIPAY', label: 'Omnipay' }
];

export const bankOptions = [
  { value: 'BDO', label: 'Banco de Oro' },
  { value: 'AUB', label: 'Asia United Bank' },
  { value: 'BPI', label: 'Bank of the Philippine Islands' },
  { value: 'UBP', label: 'Union Bank Of The Philippines' },
  { value: 'CBC', label: 'China Banking Corporation (China Bank)' },
  { value: 'LBP', label: 'Land Bank of the Philippines' },
  { value: 'BOC', label: 'Bank Of Commerce' },
  { value: 'EQB', label: 'Equicom Savings Bank' },
  { value: 'PNB', label: 'Philippine National Bank' },
  { value: 'PTC', label: 'Philippine Trust Company' },
  { value: 'PVB', label: 'Philippine Veterans Bank' },
  { value: 'UCPB', label: 'United Coconut Planters Bank (UCPB)' },
  { value: 'IBI', label: 'ISLA Bank' },
  { value: 'MET', label: 'Metrobank' },
  { value: 'MSB', label: 'Malayan Bank' },
  { value: 'ONB', label: 'One Network Bank' },
  { value: 'PAR', label: 'Partner Rural Bank (Cotabato) Inc.' },
  { value: 'PBB', label: 'Philippine Business Bank' },
  { value: 'PNS', label: 'PNB Savings Bank' },
  { value: 'PSB', label: 'Philippine Savings Bank' },
  { value: 'QRB', label: 'Quezon Capital Rural Bank' },
  { value: 'SBC', label: 'Security Bank' },
  { value: 'SSB', label: 'Sun Savings Bank' },
  { value: 'BMB', label: 'Bangko Mabuhay' },
  { value: 'CSB', label: 'Citystate Savings Bank' },
  { value: 'DBI', label: 'Dungganon Bank' },
  { value: 'EWR', label: 'East West Rural Bank (Green Bank)' },
  { value: 'WDB', label: 'Wealth Development Bank' },
  { value: 'RCBC', label: 'Rizal Commercial Banking Corp. (RCBC)' },
  { value: 'RSB', label: 'Robinsons Bank' },
  { value: 'CRD', label: 'CARD Bank' },
  { value: 'CTBC', label: 'Ctbc Bank (Philippines) Corp.' },
  { value: 'EWB', label: 'East West Banking Corporation' },
  { value: 'SBA', label: 'Sterling Bank Of Asia' },
  { value: 'CBS', label: 'China Bank Savings, Inc' },
  { value: 'DBP', label: 'Development Bank of the Philippines' },
  { value: 'JPM', label: 'JP Morgan Chase Bank, N.A.' },
  { value: 'BPIDB', label: 'BPI Direct BanKO, Inc., A Savings Bank' },
  { value: 'RSBI', label: 'Rcbc Savings Bank Inc.' },
  { value: 'CEBRUR', label: 'Cebuana Lhuillier Rural Bank, Inc.' },
  { value: 'PRB', label: 'Producers Bank' },
  { value: 'QCB', label: 'Queen City Development Bank, Inc.' },
  { value: 'ING', label: 'ING Bank N.V.' },
  { value: 'SPY', label: 'Starpay Corporation' },
  { value: 'YUANSB', label: 'Yuanta Savings Bank' },
  { value: 'UCBSB', label: 'UCBP Savings bank' },
  { value: 'BRB', label: 'Binangonan Rural Bank (BRBDigital)' },
  { value: 'ABP', label: 'AllBank Inc.' },
  { value: 'RBG', label: 'RURAL BANK OF GUINOBATAN, INC.' },
  { value: 'CMG', label: 'CAMALIG BANK' },
  { value: 'MBP', label: 'Maybank Philippines' },
  { value: 'PBCOM', label: 'Philippine Bank of Communications' },
];

const sign = (content, privateKey) => {
  try {
    const rsaKey = KEYUTIL.getKey(privateKey);

    // Check if rsaKey is a valid RSA private key
    if (!rsaKey || rsaKey.isPublic || rsaKey.type !== 'RSA') {
      throw new Error('Invalid privateKey');
    }
    // Check if rsaKey is 4096 bits
    if (rsaKey.n.bitLength() !== 4096) {
      throw new Error('Invalid privateKey');
    }

    privateKey = privateKey
        .replace('-----BEGIN PRIVATE KEY-----', '')
        .replace('-----END PRIVATE KEY-----', '')
        .replace('-----BEGIN ENCRYPTED PRIVATE KEY-----', '')
        .replace('-----END ENCRYPTED PRIVATE KEY-----', '')
        .replace('\r', '')
        .replace('\n', '')
        .trim();

    const sig = new KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
    sig.init(rsaKey);
    sig.updateString(content);
    const signature = sig.sign();

    const signed = Buffer.from(signature, 'hex').toString('base64');
    return signed;
    // Perform signing using the crypto module
    
  } catch (error) {
    throw new Error('Invalid privateKey:' + error);
  }

};

function generateRandomString(length) {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomString = 'MWB';

  for (let i = 0; i < length - 3; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return randomString;
}

function generateIdempotencyKey() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const length = Math.floor(Math.random() * 21) + 10; // Random length between 10 and 30
  let idempotencyKey = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    idempotencyKey += characters.charAt(randomIndex);
  }

  return idempotencyKey;
}

function convertToDecimalAndRemovePoint(value) {
  const decimalValue = parseFloat(value).toFixed(2); // Convert to decimal with 2 decimal places
  const valueWithoutDecimal = decimalValue.replace('.', ''); // Remove decimal point

  return valueWithoutDecimal;
}

export const checkAccountBalance = async () => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
    }; 

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "account-check-balance";
    requestBody.environment = moneyWinConfig.environment;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const balanceResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await balanceResponse.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const webPaymentChannels = async () => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
    };

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "web-payment-channels";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createWebPayment = async (userId, operatorId, amount, email, channelCode, mobile = '09954245470') => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      appName: paycoolsConfig.appName,
      mchOrderId: generateRandomString(20),
      amount: convertToDecimalAndRemovePoint(amount),
      channelCode: channelCode,
      email: email,
      mobile: mobile,
      callbackUrl: "https://api.moneywin.bet/api/paycools-webpayment-notification.php",
      redirectUrl: moneyWinConfig.ENVIRONMENT === 'TEST' ? "https://dev.moneywin.bet/userpanel/cashin" : "https://moneywin.bet/userpanel/cashin" 
    };
    
    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    
    const signedSignature = await sign(signature, paycoolsConfig.privateKey);
    requestBody.sign = signedSignature;
    requestBody.userId = userId;
    requestBody.operatorId = operatorId;
    requestBody.targetUrl = "create-web-payment";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const balanceResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });
    
    const data = await balanceResponse.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createQR = async (userId, operatorId, accountName, amount) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;
    const requestBody = {
      appId: paycoolsConfig.appId,
      appName: paycoolsConfig.appName,
      mchOrderId: generateRandomString(20),
      channelCode: "QRPH_HYBRID_QR",
      customerName: accountName,
      amount: convertToDecimalAndRemovePoint(amount),
      callbackUrl: "https://api.moneywin.bet/api/paycools-webpayment-notification.php",
    };
 
    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "qrcode";
    requestBody.userId = userId;
    requestBody.operatorId = operatorId;
    requestBody.remarks = "qrcode";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const Response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

   
    const data = await Response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const topupWebPayment = async (userId, amount, customerName, email, mobile, channelCode) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      appName: paycoolsConfig.appName,
      mchOrderId: generateRandomString(20),
      amount: convertToDecimalAndRemovePoint(amount),
      customerName: customerName,
      channelCode: channelCode,
      email: email,
      mobile: mobile,
      callbackUrl: "https://api.moneywin.bet/api/paycools-webpayment-notification.php",
      redirectUrl: moneyWinConfig.ENVIRONMENT === 'TEST' ? "https://dev.moneywin.bet/userpanel/cashin" : "https://moneywin.bet/userpanel/cashin" 
    };
    
    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    
    const signedSignature = await sign(signature, paycoolsConfig.privateKey);
    requestBody.sign = signedSignature;
    requestBody.query = "topup";
    requestBody.userId = userId;
    requestBody.targetUrl = "create-web-payment";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const balanceResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });
    
    const data = await balanceResponse.json();
    
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createTopupQR = async (userId, accountName, amount) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      appName: paycoolsConfig.appName,
      mchOrderId: generateRandomString(20),
      channelCode: "QRPH_HYBRID_QR",
      customerName: accountName,
      amount: convertToDecimalAndRemovePoint(amount),
      callbackUrl: "https://api.moneywin.bet/api/paycools-webpayment-notification.php",
    };
 
    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "qrcode";
    requestBody.query = "topup-qrcode";
    requestBody.userId = userId;
    requestBody.remarks = "topup-qrcode";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const Response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

   
    const data = await Response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const cancelQrPayment = async (mchOrderId) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      mchOrderId: mchOrderId.trim()
    };

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "cancel-qr";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const webPaymentStatus = async (transactionId, status) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      transactionId: transactionId
    };

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.status = status;
    requestBody.targetUrl = "web-payment-status";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const createPayout = async (userId, accountName, accountNumber, amount, cashoutMethod, payoutChannel, remarks ) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      mchOrderId: generateRandomString(20),
      appName: paycoolsConfig.appName,
      idempotencyId: generateIdempotencyKey(),
      amount: convertToDecimalAndRemovePoint(amount),
      accountNo: accountNumber,
      accountName: accountName,
      payMethodCode: cashoutMethod,
      ewalletCode: payoutChannel,
      bankCode: payoutChannel,
    };

    if (cashoutMethod === 'EWALLET') {
      requestBody.ewalletCode = payoutChannel;
    } else if (cashoutMethod === 'BANK') {
      requestBody.bankCode = payoutChannel;
    }
 
    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "create-payout";
    requestBody.production = true;
    requestBody.userId = userId;
    requestBody.remarks = remarks;
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const Response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

   
    const data = await Response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const payoutStatus = async (transactionId, status) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: paycoolsConfig.appId,
      mchOrderId: transactionId
    };

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = await sign(signature, paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.status = status;
    requestBody.targetUrl = "payout-status";
    requestBody.environment = moneyWinConfig.ENVIRONMENT;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};



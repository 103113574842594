import CryptoJS from 'crypto-js';
import moneyWinConfig from '../moneywin/moneywin-config';
import saConfig from './sa-config';

const buildMD5 = (inString) => {
  const currentTime = new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14);
  const preMD5Str = inString + saConfig.MD5_KEY + currentTime + saConfig.SECRET_KEY;
  const md5Hash = CryptoJS.MD5(preMD5Str).toString();
  return md5Hash;
};

const desEncrypt = (inString) => {
  const key = CryptoJS.enc.Utf8.parse(saConfig.ENCRYPT_KEY);
  const iv = key; // Same key as IV for DES
  const encrypted = CryptoJS.DES.encrypt(inString, key, { iv: iv }).toString();
  return encrypted;
};

export const saApiRequest = async (queryParams, method) => {
  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  const encryptedQuery = desEncrypt(queryString);
  const signature = buildMD5(queryString);

  const data = new URLSearchParams();
  data.append('q', encryptedQuery);
  data.append('s', signature);

  try {
    const proxyUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.SA_API)}&requestMethod=POST`; 

    const requestBody = JSON.stringify({ data: data, method , q: encryptedQuery, s: signature, environment: moneyWinConfig.ENVIRONMENT});
    const requestHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    const response = await fetch(proxyUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const saGetGames = async () => {
  const queryParams = {
    method: "GetActiveHostList",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: saConfig.SECRET_KEY,
  };

  const result = await saApiRequest(queryParams, "GetActiveHostList");
  
  return result;
}

export const saVerifyUsername = async (username) => {
  const queryParams = {
    method: "VerifyUsername",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: saConfig.SECRET_KEY,
    Username: username
  };

  const result = await saApiRequest(queryParams, "VerifyUsername");

  return result;
}

export const saRegUserInfo = async (username, currency) => {
  const queryParams = {
    method: "RegUserInfo",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: saConfig.SECRET_KEY,
    Username: username,
    CurrencyType: currency
  };

  const result = await saApiRequest(queryParams, "RegUserInfo");

  return result;
}

export const saGetUserStatusDV = async (username) => {
  const queryParams = {
    method: "GetUserStatusDV",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: saConfig.SECRET_KEY,
    Username: username,
  };

  const result = await saApiRequest(queryParams, "GetUserStatus");

  return result;
}

export const saLoginRequest = async (username, currency, gameCode = null, lang = null, mobile = null) => {

  const status = await saGetUserStatusDV(username);

  if(status.IsSuccess){
    if(status.ErrorMsgId === '116'){
      const register = await saRegUserInfo(username, currency);
      if (register.ErrorMsgId === '0'){
        const queryParams = {
          method: "LoginRequest",
          Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
          Key: saConfig.SECRET_KEY,
          Username: username,
          CurrencyType: currency
        };
      
        const result = await saApiRequest(queryParams, "LoginRequest");

        if(result.ErrorMsgId === '0'){
          const url = `${result.ApiUrl}?username=${username}&token=${result.Token}&lobby=A8368&lang=en_US&options=defaulttable=${gameCode},hidelogo=1`;
          return url;
        }else{
          return result;
        }
    
      }else{
        return register;
      }
    }else if(status.ErrorMsgId === '0'){
      const queryParams = {
        method: "LoginRequest",
        Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
        Key: saConfig.SECRET_KEY,
        Username: username,
        CurrencyType: currency
      };

      const result = await saApiRequest(queryParams, "LoginRequest");

      if(result.ErrorMsgId === '0'){

        const url = `${result.ApiUrl}?username=${username}&token=${result.Token}&lobby=A8368&lang=en_US&options=defaulttable=${gameCode},hidelogo=1`;
        return url;
      }else{
        return result;
      }

    }else{
      return status;
    }
  }
}
  
import CryptoJS from 'crypto-js';
import spConfig from './sp-config.';
import moneyWinConfig from '../moneywin/moneywin-config';

const buildMD5 = (inString) => {
  const currentTime = new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14);
  const preMD5Str = inString + spConfig.MD5_KEY + currentTime + spConfig.SECRET_KEY;
  const md5Hash = CryptoJS.MD5(preMD5Str).toString();
  return md5Hash;
};

const desEncrypt = (inString) => {
  const key = CryptoJS.enc.Utf8.parse(spConfig.ENCRYPT_KEY);
  const iv = key; // Same key as IV for DES
  const encrypted = CryptoJS.DES.encrypt(inString, key, { iv: iv }).toString();
  return encrypted;
};

export const spApiRequest = async (queryParams, method) => {
  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  const encryptedQuery = desEncrypt(queryString);
  const signature = buildMD5(queryString);

  const data = new URLSearchParams();
  data.append('q', encryptedQuery);
  data.append('s', signature);

  try {
    const proxyUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.SP_API)}&requestMethod=POST`; 

    const requestBody = JSON.stringify({ data: data, method , q: encryptedQuery, s: signature, environment: moneyWinConfig.ENVIRONMENT});
    const requestHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    const response = await fetch(proxyUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const spGetGames = async () => {
  const queryParams = {
    method: "GetActiveGameList",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
  };

  const result = await spApiRequest(queryParams, "GetActiveGameList");

  return result;
}

export const spVerifyUsername = async (username) => {
  const queryParams = {
    method: "VerifyUsername",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username
  };

  const result = await spApiRequest(queryParams, "VerifyUsername");

  return result;
}

export const spRegUserInfo = async (username, currency) => {
  const queryParams = {
    method: "RegUserInfo",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    CurrencyType: currency
  };

  const result = await spApiRequest(queryParams, "RegUserInfo");

  return result;
}

export const spGetUserStatus = async (username) => {
  const queryParams = {
    method: "GetUserStatus",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
  };

  const result = await spApiRequest(queryParams, "GetUserStatus");

  return result;
}

export const spLoginRequest = async (username, currency, gameCode = null, lang = null, mobile = null) => {

  const status = await spGetUserStatus(username);

  if(status.IsSuccess){
    if(status.ErrorMsgId === '116'){
      const register = await spRegUserInfo(username, currency);
      if (register.ErrorMsgId === '0'){
        const queryParams = {
          method: "LoginRequest",
          Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
          Key: spConfig.SECRET_KEY,
          Username: username,
          CurrencyType: currency
        };
      
        if (gameCode !== null) {
          queryParams.GameCode = gameCode;
        }
        if (lang !== null) {
          queryParams.Lang = lang;
        }
        if (mobile !== null) {
          queryParams.Mobile = mobile;
        }
      
        const result = await spApiRequest(queryParams, "LoginRequest");

        if(result.ErrorMsgId === '0'){
          return result.GameURL;
        }else{
          return result;
        }
    
      }else{
        return register;
      }
    }else if(status.ErrorMsgId === '0'){
      const queryParams = {
        method: "LoginRequest",
        Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
        Key: spConfig.SECRET_KEY,
        Username: username,
        CurrencyType: currency
      };
    
      if (gameCode !== null) {
        queryParams.GameCode = gameCode;
      }
      if (lang !== null) {
        queryParams.Lang = lang;
      }
      if (mobile !== null) {
        queryParams.Mobile = mobile;
      }
    
      const result = await spApiRequest(queryParams, "LoginRequest");

      if(result.ErrorMsgId === '0'){
        return result.GameURL;
      }else{
        return result;
      }

    }else{
      return status;
    }
  }
}
  
export const spSetUserMaxWinning = async (username, maxWinning) => {
  const queryParams = {
    method: "SetUserMaxWinning",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    MaxWinning: maxWinning
  };

  const result = await spApiRequest(queryParams, "SetUserMaxWinning");

  return result;
}

export const spGetUnfinishedGame = async (username) => {
  const queryParams = {
    method: "GetUnfinishedGame",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
  };

  const result = await spApiRequest(queryParams, "GetUnfinishedGame");

  return result;
}

export const spLoginRequestForFun = async (username, amount, currency, gameCode = null, lang = null, mobile = null) => {
  const queryParams = {
    method: "LoginRequestForFun",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    Amount: amount,
    CurrencyType: currency
  };

  if (gameCode !== null) {
    queryParams.GameCode = gameCode;
  }
  if (lang !== null) {
    queryParams.Lang = lang;
  }
  if (mobile !== null) {
    queryParams.Mobile = mobile;
  }

  const result = await spApiRequest(queryParams, "LoginRequestForFun");

  return result;
}

export const spKickUser = async (username) => {
  const queryParams = {
    method: "KickUser",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
  };

  const result = await spApiRequest(queryParams, "KickUser");

  return result;
}

export const spGetAllBetDetails = async (username, date) => {
  const queryParams = {
    method: "GetAllBetDetails",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    Date: date
  };

  const result = await spApiRequest(queryParams, "GetAllBetDetails");

  return result;
}

export const spGetAllBetDetailsForTimeInterval = async (username, fromTime, toTime) => {
  const queryParams = {
    method: "GetAllBetDetailsForTimeInterval",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    FromTime:fromTime,
    ToTime:toTime
  };

  const result = await spApiRequest(queryParams, "GetAllBetDetailsForTimeInterval");

  return result;
}

export const spGetUserBetAmount = async (username, startDate, timeRange) => {
  const queryParams = {
    method: "GetUserBetAmount",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    StartDate:startDate,
    TimeRange:timeRange
  };

  const result = await spApiRequest(queryParams, "GetUserBetAmount");

  return result;
}

export const spGetUserWinLost = async (username, fromTime, toTime, type = null) => {
  const queryParams = {
    method: "GetUserWinLost",
    Time: new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14),
    Key: spConfig.SECRET_KEY,
    Username: username,
    FromTime: fromTime,
    ToTime: toTime,
  };

  if (type !== null) {
    queryParams.Type = type;
  }

  const result = await spApiRequest(queryParams, "GetUserWinLost");

  return result;
}





  
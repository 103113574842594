import moneyWinConfig from "./moneywin-config";

const createTimeoutController = (timeout) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return { controller, timeoutId };
};

export const getUsers = async () => {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USERS)}&requestMethod=GET`);
    const data = await response.json();
    console.log(data);
};

export const getUserInfo = async (token, email = null) => {
  try {
    const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds

    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USER_INFO)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({ token, email }),
      signal: controller.signal // Pass the AbortController's signal to the fetch request
    });

    clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    if (error.name === 'AbortError') {
      return { timed_out: 1 };
    } else {
      return { request_failed: 1 };
    }
  }
};

export const logoutUser = async (user_id) => {
  const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds

  try {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.LOGOUT_USER)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({user_id}), 
      signal: controller.signal // Pass the AbortController's signal to the fetch request
    });

    clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await response.json();

    return true;
    
  } catch (error) {
    if (error.name === 'AbortError') {
      return { timed_out: 1 };
    } else {
      return { request_failed: 1 };
    }
  }
};

export const getDashboardData = async (user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_DASHBOARD_DATA)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    
    const requestBody = JSON.stringify({ user_id ,from_date, to_date, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDeductionData = async (user_id, role_id, date) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_DEDUCTIONS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    
    const requestBody = JSON.stringify({ user_id, role_id, date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    console.log(data);
    if(data.error){
      return null;
    }
   
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getManagedUsers = async (role_id, user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_MANAGED_USERS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getManagedUsersBetting = async (role_id, user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_MANAGED_USERS_BETTING)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    if(data.error){
      return null;
    }

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTransactionRequests = async (user_id, transaction, status) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_TRANSACTION_REQUESTS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction, status, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    return null;
  }
};

export const getTransactionRecord = async (user_id, transaction) => {

  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_TRANSACTION_RECORD)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const processCashinRequest = async(user_id, transaction_id, action, reference, bonus, remarks) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.PROCESS_CASHIN_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, transaction_id , reference , action, bonus, remarks, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
  }
};


export const generateReferral = async (role_id, user_id, generated_role_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GENERATE_REFERRAL)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, generated_role_id });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const cashinRequest = async(user_id, payment_channel, reference, account_name, account_number, amount) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.CASHIN_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, payment_channel, reference , account_name , account_number , amount, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
  }
};

export const cashoutRequest = async(user_id, account_name, account_number, payout_channel, amount, remarks, cashout_with_fee = false, currency = 'PHP') => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.CASHOUT_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, account_name, account_number, payout_channel, amount , remarks, cashout_with_fee ,currency, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
  }
};

export const authenticateUser = async (username, password, currency) => {
    try {
        const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds
      
        const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.AUTHENTICATE_LOGIN)}&requestMethod=POST`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Key': moneyWinConfig.API_KEY,
          },
          body: JSON.stringify({
            username,
            password,
            currency,
            environment: moneyWinConfig.ENVIRONMENT
          }),
          signal: controller.signal // Pass the AbortController's signal to the fetch request
        });
        
        clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseBody = await response.text();

        const responseData = JSON.parse(responseBody);
        
        return responseData;

      } catch (error) {
        if (error.name === 'AbortError') {
          return { timed_out: 1 };
        } else {
          return { request_failed: 1 };
        }
      }
    
  };

  export const createUser = async (username, password, email, mobile_number, first_name, last_name, refferal) => {
    const requestData = {
      username: username,
      password: password,
      email: email,
      mobile_number: mobile_number,
      first_name: first_name,
      last_name: last_name,
      refferal: refferal,
      environment : moneyWinConfig.ENVIRONMENT
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify(requestData)
    };

    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_USER)}&requestMethod=POST`, requestOptions);

    let responseData;

    try {
      responseData = await response.json();
      return responseData;
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const getUserBalance = async (username, currency) => {
  try {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USER_BALANCE)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({ username, currency, environment : moneyWinConfig.ENVIRONMENT }),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getValidBets = async (user_id) => {
  try {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_VALID_BETS)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({ user_id, environment : moneyWinConfig.ENVIRONMENT }),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getBettingHistory = async (user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_BETTING_HISTORY)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const modifyPercentage = async (username, percentage) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.MODIFY_AGENT_PERCENTAGE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({username, percentage});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateData = async (tableName, columns, whereConditions, message) => {
  try {
    const apiUrl = 'your-api-url'; // Replace with the actual API URL

    const requestBody = {
      table: tableName,
      columns: columns,
      where: whereConditions,
      message: message,
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    console.log(data);
    // Handle the response data as needed

  } catch (error) {
    console.error(error);
    // Handle the error
  }
};

export const getRemainingPercentage = async (username) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_REMAINING_PERCENTAGE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({username});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProfileInfo = async (token) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_PROFILE_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({token});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const updateProfileInfo = async (user_id, first_name, middle_name, last_name, email) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PROFILE_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, first_name, middle_name, last_name, email});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProfilePicture = async (user_id, profile_picture) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PROFILE_PICTURE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json', // Set Content-Type to JSON
      'Key': moneyWinConfig.API_KEY,
    };

    const requestData = {
      user_id: user_id,
      profile_picture: profile_picture, // Assuming profile_picture is a File object
    };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestData), // Convert the data to JSON
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(`Request failed with status ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const signSignature = async (privateKey,originalSignature) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.SIGN_SIGNATURE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ privateKey, originalSignature });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data.signature;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updatePassword = async (user_id, old_password, new_password) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PASSWORD)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, old_password, new_password});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getChipsInfo = async (user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_CHIPS_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const updateCashoutRequest = async (user_id, transaction_reference, status, bonus, receiver_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_CASHOUT_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction_reference, status, bonus, receiver_id, environment : moneyWinConfig.ENVIRONMENT});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTopLevel = async (user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_TOP_LEVEL)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUpline = async (username, user_id, from_date, to_date) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_UPLINE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ username, user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const modeOfPayment = async (method, user_id, id = null, payment_channel = null, account_number = null, remarks = null, account_name = null,  ) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.MODE_OF_PAYMENT)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ method, user_id, id, payment_channel, account_name, account_number, remarks });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendEmail = async ( email, user_id, fname ) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.SEND_EMAIL)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    // const requestBody = JSON.stringify({ email, user_id, environment : moneyWinConfig.ENVIRONMENT });
    const requestBody = JSON.stringify({ email, user_id, fname, environment : moneyWinConfig.ENVIRONMENT });

    // const response = await fetch(apiUrl, {
    //   method: 'POST',
    //   headers: requestHeaders,
    //   body: requestBody,
    // });
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      });
    
      const responseData = await response.json();
      //console.log('Response Data:', responseData);
      
      /*
      if (responseData.status === 'success') {
        //console.log('Email sent!');
        return { status: 'success', message: responseData };
      } else if (responseData.status === 'error') {
        //console.error('Error sending email:', responseData);
        return { status: 'error', message: responseData };
      } else {
        //console.error('Unknown response:', responseData);
        return;
      }
      */
      if(responseData){
        return responseData;
      }else{
        return null;
      }
      
    } catch (error) {
      //console.error('Fetch error:', error);
      return;
    }
    
    
    // const data = await response.json();
    // return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateMobileVerification = async (mobile_number) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_MOBILE_VERIFICATION)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({mobile_number});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addPlayerChips = async (user_id, receiver_user_id, amount, type = "cashin") => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_CHIPS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, receiver_user_id, amount, type, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addPlayerBonusChips = async (user_id, receiver_user_id, amount, type = "bonus") => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_CHIPS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, receiver_user_id, amount, type, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendUserContactMessage = async (email, first_name, last_name, contact_number, message) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(
      moneyWinConfig.SEND_USER_CONTACT_MESSAGE
    )}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ email, first_name, last_name, contact_number, message });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      });

      const responseData = await response.text();
      //console.log('Response Data:', responseData);
      if(responseData){
        return { status: 'success'};
      } else {
        return { status: 'unknown'};
      }
    } catch (error) {
      console.error('Fetch error:', error);
      return { status: 'error', message: `Fetch error: ${error.message}` };
    }
  } catch (error) {
    console.error(error);
    return { status: 'error', message: error.message };
  }
};

export const agentSettings = async (method, user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_AGENT_SETTINGS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ method, user_id, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addChips = async (user_id, receiver_user_id, amount, type) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_CHIPS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, receiver_user_id, amount, type, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const displayedGames = async (method, user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.DISPLAYED_GAMES)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ method, user_id, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

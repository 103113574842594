import React, { useState } from "react";
import "./contact.css";
import { Form, Image, Input, Spin, message} from "antd";
import contactTitle from "../../../assets/resources/images/contact-title.webp";
import TextArea from "antd/es/input/TextArea";
import { LoadingOutlined } from '@ant-design/icons';
import { sendUserContactMessage } from "../../../services/moneywin/moneywin-api";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userContactNumber, setUserContactNumber] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async () => {
    setIsLoading(true);
    try {
      const result = await sendUserContactMessage(userEmail, firstName, lastName, userContactNumber, userMessage);
      console.log(result);
      if (result.status === 'success') {
        message.success({ content: "Success, Message Sent!", key: 'custom_message' });
        setFirstName('');
        setLastName('');
        setUserEmail('');
        setUserContactNumber('');
        setUserMessage('');
        navigate("/");
      } else {
        message.error({ content: "Failed, Please enter a valid email address.", key: 'custom_message' });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const loadingIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: '#fff',
        margin: '2px'
      }}
      spin
    />
  );

  const onFinishFailed = () => {};
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-contact-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10 md:py-16 lg:py-[36] px-4">
        <div className="bg-transparent md:bg-white rounded-[30px] border-0 text-white max-w-[1022px] relative md:p-10 w-full ">
          <div className="flex flex-col items-center gap-4 w-full md:pt-12 h-full">
            <div className="w-[198px] md:block md:w-[341px] md:absolute md:top-[-60px]">
              <Image width={"100%"} preview={false} src={contactTitle} />
            </div>
            <Form
              className="w-full"
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-col md:gap-0 w-full">
                <div className="w-full flex flex-col md:flex-row md:gap-5 justify-between">
                  <Form.Item
                    className="login-input w-full"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        First Name
                      </span>
                    }
                    name="fName"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                        ),
                        message: "Please enter your first name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      // className="w-[400px]"
                      placeholder="Enter first name"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    className="login-input w-full"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Last Name
                      </span>
                    }
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name!",
                        pattern: new RegExp(
                          /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      // className="w-[400px]"
                      placeholder="Enter last name"
                      style={{
                        // width: '100%',
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="w-full flex flex-col md:flex-row md:gap-5">
                  <Form.Item
                    className="login-input w-full"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Email
                      </span>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter email"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    className="login-input w-full"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Contact Number
                      </span>
                    }
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      min={1}
                      placeholder="Enter contact number"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                      value={userContactNumber}
                      onChange={(e) => setUserContactNumber(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                className="login-input"
                label={
                  <span className="label-style md:text-[#6D6D6D]">
                    Your Message
                  </span>
                }
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your message!",
                  },
                ]}
              >
                <TextArea
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#F2F2F2",
                  }}
                  placeholder="Enter message..."
                  rows={8}
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                />
              </Form.Item>
              <div className="w-full flex items-center justify-center">
                <button
                  type="primary"
                  htmltype="submit"
                  className="w-full h-[60px] text-[20px] font-medium gradient-button cursor-pointer text-white border-0 rounded-lg md:max-w-[285px] "
                  disabled={isLoading}
                >
                  {isLoading ? <Spin indicator={loadingIcon}/> : "Submit"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
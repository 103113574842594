import { Card, Table, Button, Modal, Select, Spin, message as floatingMessage  } from "antd";
import React from "react";
import { useEffect , useState} from "react";
import { getBettingHistory, getTransactionRequests, getUserInfo, updateCashoutRequest } from "../../../services/moneywin/moneywin-api";
import { formatAsMoney, showConstrainedMessages, showTimedMessage, showTimedMessageWithAction, formatDate } from "../../../services/util/utilities";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const CashoutRequest = () => {
  const [message, messageHolder] = Modal.useModal();
  const [cashoutRequests, setCashoutRequests] = useState([]);
  const [visible, setVisible] = useState(false);
  const [betsVisible, setBetsVisible] = useState(false);
  const [bonus, setBonus] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [bettingHistory, setBettingHistory] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const isMobileBreakpoint = 768;
  const isMobile = window.innerWidth <= isMobileBreakpoint;
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  
  useEffect(() => {
      const fetchData = async () => {;
        if(!visible){
          const response = await getUserInfo(token);
          if (response.user_id) {
            const requests = await getTransactionRequests(response.user_id, 'cashout', 'pending');
  
            if (requests && requests.transaction_requests && requests.transaction_requests.length > 0) {
              setCashoutRequests(requests.transaction_requests);
            }else{
              setCashoutRequests([]);
            }
            
          }else if(response.timed_out || response.request_failed){
            floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
          }else if(response.error){
            showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
            async () => {
              dispatch(logout());
            });  
          }
          setTableLoading(false);
        }
      };

      fetchData();

      const interval = setInterval(fetchData, 5000);
    
      return () => {
        clearInterval(interval);
      };

  },[dispatch, message, token, visible]);

  const handleAcceptRequest = async (record, e) => {
    try {
      setConfirmLoading(true);
      const messages = ["Channel : " + record.payment_channel , "Account Name : " + record.account_name, "Account No : " + record.account_number, "Amount : " + record.amount];
      showConstrainedMessages(message,"Accept cashout request?", messages, "PROCEED", 5, async () => {
        
        const response = await getUserInfo(token);
        
        if (response.user_id && record.transaction_reference) {
          const accept = await updateCashoutRequest(response.user_id, record.transaction_reference, 'completed');
          if(accept.success){
            showTimedMessage(message, "Success", "Cash-out accepted succesfully.", "OK", 5, true);
          }else if(accept.message){
            showTimedMessage(message, "Failed", accept.message, "OK", 5, false);
          }else{
            showTimedMessage(message, "Failed", "An has error occured, please try again later.", "OK", 5, false);
          }
        }else{
          showTimedMessage(message, "Failed", "An has error occured, please try again later.", "OK", 5, false);
        }
      });
    }catch (error) {
      showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
    }finally{
      setConfirmLoading(false);
    }
  };

  const handleDecline = async (record) => {
    try {
      setConfirmLoading(true);
      const messages = ["Channel : " + record.payment_channel , "Account Name : " + record.account_name, "Account No : " + record.account_number, "Amount : " + record.amount];
      showConstrainedMessages(message,"Decline cash-out request?", messages, "PROCEED", 5, async () => {
        const response = await getUserInfo(token);
        if (response.user_id && record.transaction_reference) {
          const decline = await updateCashoutRequest(response.user_id, record.transaction_reference, 'declined');
          if(decline.success){
            showTimedMessage(message, "Success", "Cash-out declined succesfully.", "OK", 5, true);
          }else if(decline.message){
            showTimedMessage(message, "Failed", decline.message, "OK", 5, false);
          }else{
            showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
          }
        }else{
          showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
        }
      });
    }catch (error) {
      showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
    }finally{
      setConfirmLoading(false);
    }
  };

  const handleConfirm = async (transaction_reference, bonus, receiver_id) => {
    try {
      setConfirmLoading(true);
      const response = await getUserInfo(token);
      if (response.user_id && transaction_reference && bonus && receiver_id) {
        const decline = await updateCashoutRequest(response.user_id, transaction_reference, 'declined', bonus, receiver_id);
        if(decline.success){
          showTimedMessage(message, "Success", `Cash-out declined with ${bonus} bonus.`, "OK", 5, true);
          setVisible(false);
         }else if(decline.message){
          showTimedMessage(message, "Failed", decline.message, "OK", 5, false);
         }else{
          showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
         }
      }
    }catch (error) {
      showTimedMessage(message, "Failed", "An error has occured, please try again later.", "OK", 5, false);
    }finally{
      setConfirmLoading(false);
    }
  };

  const handleOpenDialog = async (record) => {
    setSelectedRecord(record);
    setVisible(true);
    setSelectedPercentage(null);
    setBonus(null);
  };
  
  const handleCloseDialog = () => {
    setVisible(false);
    setBetsVisible(false);
  };
  
  const handlePercentageChange = (value) => {
    setSelectedPercentage(parseFloat(value));
    setBonus(parseFloat(value));
  };

  const handleViewBets = async (record) => {
    setSelectedRecord(record);
    setBettingHistory([]);
    const bettingData = await getBettingHistory(record.user_id);
    if (bettingData && bettingData.length > 0) {
      setBettingHistory(bettingData);
    }
    setBetsVisible(true);
  };

  const columns = [
    {
      title: "Date Request",
      dataIndex: "created_at",
      key: "created_at",
      width: 260,
      align: 'center',
      render: (text) => {
        return <span>{formatDate(text)}</span>
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 250,
      render: (text, record) => {
        return(<>
          <div className="flex justify-between items-center">
            <span>{record.username}</span>
            <Button onClick={() => handleViewBets(record)}>Bets</Button>  
          </div>
        </>)
        
      }
    },
    {
      title: "Direct Link",
      dataIndex: "direct_link_username",
      key: "direct_link_username",
      render: (record) => (record == null ? "-" : record),
    },
    {
      title: "Reference",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
    },
    {
      title: "Channel",
      dataIndex: "payment_channel",
      key: "payment_channel",
    },
    {
      title: "Account No.",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, record) => {
        return (
          <span>
            {formatAsMoney(amount)} {record.service_charge > 0 ? `(${formatAsMoney(amount - record.service_charge)})` : ""}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (type, record) => {
        let statusColor;
        if(record.status === "pending"){
          statusColor = 'text-yellow-300';
        }
        if(record.status === "completed"){
          statusColor = 'text-green-500';
        }
        if(record.status === "failed"){
          statusColor = 'text-red-500';
        }
        if(record.status === "cancelled"){
          statusColor = 'text-gray-400';
        }
        if(record.status === "declined"){
          statusColor = 'text-gray-400';
        }
        return (
          <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
        )
      },
    },
    {
      title: "Running Balance",
      dataIndex: "running_balance",
      key: "running_balance",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "actions",
      align: "center",
      fixed: isMobile ? undefined : 'right',
      width: "200px",
      render: (text, record) => {
        if (record && record.username) {
          return (
            <div className="flex flex-col items-center gap-2">
              <Button
                type="primary"
                className="w-full"
                onClick={() => handleAcceptRequest(record)}
              >
                Accept
              </Button>
              <Button
                className="w-full"
                onClick={() => handleOpenDialog(record)}
              >
                Decline with Bonus
              </Button>
              <Button
                className="w-full"
                onClick={() => handleDecline(record)}
              >
                Decline
              </Button>
            </div>
          );
        }
        return null; // Return null if 'username' or any other required field is not present in the record
      },
    },
  ];

  const betColumns = [
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Game",
      dataIndex: "game_id",
      key: "game_id",
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      key: "dateTime", 
      render: (text, record) => {
        const combinedDateTime = `${record.date} ${record.time}`;
        return <span>{combinedDateTime}</span>;
      },
    },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Payout",
      dataIndex: "payout",
      key: "payout",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatAsMoney(text)}
          </span>
        );
      }
    },
    {
      title: "Running Balance",
      dataIndex: "balance",
      key: "balance",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatAsMoney(record)}</span>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <Modal
          title="Bonus Amount"
          open={visible}
          onCancel={handleCloseDialog}
          footer={null}
        >
          {confirmLoading ? (
            <div className="flex justify-center items-center h-32">
              {/* Loading indicator */}
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="flex flex-col">
                <Select id="percentage" placeholder="Select Bonus Amount" defaultValue="Select Value" value={selectedPercentage} onChange={handlePercentageChange}>
                  <option value={selectedRecord?.amount.replace(/,/g, '') * 0.10}>10% ({selectedRecord?.amount.replace(/,/g, '') * 0.10})</option>
                  <option value={selectedRecord?.amount.replace(/,/g, '') * 0.20}>20% ({selectedRecord?.amount.replace(/,/g, '') * 0.20})</option>
                  <option value={selectedRecord?.amount.replace(/,/g, '') * 0.30}>30% ({selectedRecord?.amount.replace(/,/g, '') * 0.30})</option>
                  <option value={selectedRecord?.amount.replace(/,/g, '') * 0.40}>40% ({selectedRecord?.amount.replace(/,/g, '') * 0.40})</option>
                  <option value={selectedRecord?.amount.replace(/,/g, '') * 0.50}>50% ({selectedRecord?.amount.replace(/,/g, '') * 0.50})</option>
                </Select>
                <div className="flex justify-end mt-3">
                  <Button 
                    type="primary" 
                    style={{ backgroundColor: "black" }} 
                    onClick={() => handleConfirm(selectedRecord && selectedRecord.transaction_reference, bonus, selectedRecord.user_id)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          )}
          
      </Modal>
      <Modal
        title={`Betting History of ${selectedRecord?.username}`}
        open={betsVisible}
        width={1000}
        onCancel={handleCloseDialog}
        footer={null}
      >
        <Table
          dataSource={bettingHistory}
          scroll={{
            x: 900,
            y: '100%',
          }}
          columns={betColumns}
          bordered
        />
      </Modal>
      <Card title="Cash-Out Request (Manual)" >
      <Table 
        bordered 
        dataSource={cashoutRequests} 
        columns={columns} 
        rowKey={"key"} 
        loading={tableLoading}
        className={`min-h-[230px] shadow-md rounded-md`}
        scroll={{
          x: 1900,
          y: '100%',
        }}
      />
      </Card>
    </div>
  );
};

export default CashoutRequest;
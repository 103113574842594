import React, {useState, useEffect, useRef} from 'react'
import { Card, Table, Modal, Input, Form, Button, Switch, message } from "antd";
import { modeOfPayment, getUserInfo } from '../../../services/moneywin/moneywin-api';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { TextArea } = Input;

const PaymentChannel = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [roleId, setRoleId] = useState(null);
    const [accountNumber, setAccountNumber] = useState('');
    const [remarks, setRemarks] = useState('');
    const [isModalLoading, setModalLoading] = useState(false);
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [channelNameError, setChannelNameError] = useState('');
    const [accountNumberError, setAccountNumberError] = useState('');
    const [isSwitching, setIsSwitching] = useState(false);
    const [cashinSwitching, setCashinSwitching] = useState(false);
    const [cashoutSwitching, setCashoutSwitching] = useState(false);
    const [cashinSwitchChecked, setCashinSwitchChecked] = useState(false);
    const [cashoutSwitchChecked, setCashoutSwitchChecked] = useState(false);
    const [paymentId, setPaymentId] = useState(null);
    const [isUpdate, setUpdate] = useState(false);
    const formRef = useRef(null);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        const fetchData = async () => {

            const response = await getUserInfo(token);

            if (response.user_id && response.role_id) {
                setRoleId(response.role_id);
                getPaymentChannels(response.user_id);
            }else if(response.timed_out || response.request_failed){
                message.error({ content: "Unable to retrieve payment channels. Please check your internet connection or try again later." , key: 'custom_message' });
            }
        };
        
        fetchData();
    }, [token]);

    const getPaymentChannels = async (user_id) => {
        const response = await modeOfPayment("get", user_id);
        if (response && response.success) {
           setCashinSwitchChecked(response.automated_cashin)
           setCashoutSwitchChecked(response.automated_cashout)
           setPaymentChannels(response.mode_of_payments)
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setModalLoading(true);
    
        if (!channelName || !channelName.trim()) { // Check for null or spaces
            setChannelNameError('Please enter a valid payment channel name.');
            setModalLoading(false);
            return;
        } else {
            setChannelNameError('');
        }
    
        if (!accountNumber) {
            setAccountNumberError('Please enter an account number.');
            setModalLoading(false);
            return;
        } else {
            setAccountNumberError('');
        }

        const userInfo = await getUserInfo(token);

        if (userInfo.user_id) {
            let response;

            if(isUpdate && paymentId){
                response = await modeOfPayment('update', userInfo.user_id, paymentId, channelName, accountNumber, remarks);
            }else if(!isUpdate){
                response = await modeOfPayment('add', userInfo.user_id, null, channelName, accountNumber, remarks);
            }

            if (response && response.success) {
                getPaymentChannels(userInfo.user_id);
                setUpdate(false);
                setPaymentId(null);
                setAccountNumber(null);
                setChannelName(null);
                setRemarks(null);
            }
        }
        
        setModalLoading(false);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setRemarks(null);
        setAccountNumber(null);
        setChannelName(null);
        setAccountNumberError(false);
        setChannelNameError(false);
        setUpdate(false);
        setPaymentId(null)
    };

    const handleUpdate = (record) => {

        setPaymentId(record.id);
        setChannelName(record.payment_channel);
        setAccountNumber(record.account_number);
        setRemarks(record.remarks);
    
        setUpdate(true);
        showModal();

        if (formRef.current) {
            formRef.current.setFieldsValue({
                'channel-name': record.payment_channel,
                'phone-number': record.account_number,
                'remarks': record.remarks,
            });
        }
    };

    const handleDelete = (record) => {
        setPaymentId(record.id);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async (payment_id) => {
        // Close the delete confirmation modal
        
        setDeleteModalOpen(false);

        if (paymentId) {
            const userInfo = await getUserInfo(token);

            if (userInfo.user_id) {
                const response = await modeOfPayment('delete', userInfo.user_id, paymentId);

                if (response && response.success) {
                    getPaymentChannels(userInfo.user_id);
                    setPaymentId(null);
                    message.success({ content: "Payment channel deleted succesfully." , key: 'custom_message' });
                }else{
                    message.success({ content: "Apologies, there was an error while deleting your payment channel. " , key: 'custom_message' });
                }
            }
        }
    };

    const cancelDelete = () => {
        // Close the delete confirmation modal and reset the recordToDelete
        setPaymentId(null);
        setDeleteModalOpen(false);
    };

    const gatewayCashinSwitchChange = async () => {
        if (cashinSwitching) {
            return; // Ignore if switch is already being processed
        }
    
        setCashinSwitching(true);
    
        let newCashinSwitchChecked = cashinSwitchChecked;
        newCashinSwitchChecked = !cashinSwitchChecked;
    
        // Update the local state immediately
        setCashinSwitchChecked(newCashinSwitchChecked);
    
        const userInfo = await getUserInfo(token);
    
        if (userInfo.user_id) {
            let response;
    
            response = await modeOfPayment(newCashinSwitchChecked ? "enable_gateway_cashin" : "disable_gateway_cashin", userInfo.user_id);
    
            if (!response || !response.success) {
                // Revert the local state if API call fails
                setCashinSwitchChecked(cashinSwitchChecked);
            }
        }
    
        setCashinSwitching(false);
    };

    const gatewayCashoutSwitchChange = async () => {
        if (cashoutSwitching) {
            return; // Ignore if switch is already being processed
        }
    
        setCashoutSwitching(true);
    
        let newCashoutSwitchChecked = cashoutSwitchChecked;
        newCashoutSwitchChecked = !cashoutSwitchChecked;
    
        // Update the local state immediately
        setCashoutSwitchChecked(newCashoutSwitchChecked);
    
        const userInfo = await getUserInfo(token);
    
        if (userInfo.user_id) {
            let response;
    
            response = await modeOfPayment(newCashoutSwitchChecked ? "enable_gateway_cashout" : "disable_gateway_cashout", userInfo.user_id);
    
            if (!response || !response.success) {
                // Revert the local state if API call fails
                setCashoutSwitchChecked(cashoutSwitchChecked);
            }
        }
    
        setCashoutSwitching(false);
    };


    const handleSwitchChange = async (record, checked) => {
        if (isSwitching) {
            return; // Ignore if switch is already being processed
        }
    
        setIsSwitching(true);
    
        // Update the active status locally
        const updatedPaymentChannels = paymentChannels.map(channel =>
            channel.id === record.id ? { ...channel, active: checked ? 1 : 0 } : channel
        );

        setPaymentChannels(updatedPaymentChannels);
    
        const userInfo = await getUserInfo(token);
    
        if (userInfo.user_id) {
            let response;
    
            if (!checked) {
                response = await modeOfPayment('disable', userInfo.user_id, record.id);
            } else {
                response = await modeOfPayment('enable', userInfo.user_id, record.id);
            }
    
            if (!response || !response.success) {
                // Rollback the active status locally in case of error
                const rollbackPaymentChannels = paymentChannels.map(channel =>
                    channel.id === record.id ? { ...channel, active: checked ? 0 : 1 } : channel
                );
                setPaymentChannels(rollbackPaymentChannels);
            }
        }
    
        setIsSwitching(false);
    };

    const columns = [
        {
            title: "Channel",
            dataIndex: "payment_channel",
            key: "payment_channel",
            align: "center",
            width: "30%",
        },
        {
            title: "Account Number",
            dataIndex: "account_number",
            key: "account_number",
            align: "center",
            width: "20%",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            align: "center",
            width: "20%",
            render: (remarks) => (remarks !== null ? remarks : "-"),
        },
        {
            title: "Active",
            dataIndex: "active",
            key: "active",
            align: "center",
            width: "10%",
            render: (active, record) => (
                <Switch
                    checked={active === 1}
                    onChange={(checked) => handleSwitchChange(record, checked)}
                    style={{ backgroundColor: active === 1 ? "#364671" : "#f3f4f6" }}
                />
            ),
        },
        {
            title: "Actions",
            key: "actions",
            align: "center",
            width: "20%",
            render: (record) => (
                <>
                    <Button
                        type="primary"
                        className="bg-[#364671] hover:bg-blue-400 min-w-[90px] m-1"
                        onClick={() => {
                            handleUpdate(record);
                        }}
                    >
                        Update
                    </Button>
                    <Button type="danger" className="bg-red-400 text-white hover:bg-red-200 min-w-[90px] m-1" onClick={() => handleDelete(record)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="bg-primary h-full w-full p-5">
            <div className="gap-3 flex flex-col">
                <div className='flex flex-col items-center md:pb-10 pb-2 text-center py-5 justify-center text-white'>
                    <span className="md:text-2xl text-xl text-white pb-5 font-medium">
                        Payment Channels
                    </span>
                    <span className="text-[#ABABAB]">
                        Add an additional payment channel information here to be shown on the cash-in screen of your player.
                    </span>
                </div>
                <div>
                    <Card className="min-h-[756px]" 
                        title={
                            <div className='py-2 w-full flex flex-col xl:flex-row gap-3 justify-between items-center'>
                                
                                {roleId  && roleId < 3 && (
                                    <div className="flex flex-col xl:flex-row gap-5 w-full justify-center mt-3 items-center xl:w-auto">
                                        <div className="flex flex-col xl:flex-row xl:gap-2 w-full xl:w-auto">
                                            <p className="">Cash-In via Payment Gateway</p>
                                            <Switch
                                                checked={cashinSwitchChecked}
                                                checkedChildren="Enabled" 
                                                unCheckedChildren="Disabled"
                                                onChange={() => gatewayCashinSwitchChange("cashin")} />
                                        </div>
                                        <div className="flex flex-col xl:flex-row xl:gap-2 w-full xl:w-auto">
                                            <p className="">Cash-Out via Payment Gateway</p>
                                            <Switch
                                                checked={cashoutSwitchChecked}
                                                checkedChildren="Enabled" 
                                                unCheckedChildren="Disabled" 
                                                onChange={() => gatewayCashoutSwitchChange("cashout")} /> 
                                        </div>
                                    </div>
                                )}
                                
                                
                                <div className="flex justify-center items-center w-full xl:w-auto mt-2">
                                    <button
                                        type="primary"
                                        htmltype="submit"
                                        onClick={showModal}
                                        // className="h-[50px] cursor-pointer pr-4 pl-4 text-lg font-medium bg-[#364671] hover:bg-blue-400 duration-300 text-white border-0 rounded-lg w-full md:w-[205px] "
                                        className="cursor-pointer px-4 py-2 text-lg font-medium bg-[#364671] hover:bg-blue-400 duration-300 text-white border-0 rounded-lg w-full md:w-[205px] "
                                    >
                                        Add New 
                                    </button>
                                </div>
                            </div>
                        }>
                        <Table
                            scroll={{
                                x: 1000,
                                y: 756,
                            }}
                            bordered
                            columns={columns}
                            dataSource={paymentChannels}
                            rowKey={"key"}
                        />
                    </Card>
                </div>
                {/* Handle Open Modal  */}
                <Modal 
                    title="Please provide the information needed." 
                    open={isModalOpen} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    footer={isModalLoading ? null : undefined} 
                >
                    {isModalLoading ? ( // Show loading indicator when isModalLoading is true
                        <div className="flex items-center justify-center p-4 h-300">
                            <LoadingOutlined />
                        </div>
                    ) : (
                        <>
                            <Form
                                ref={formRef}
                            >
                                <Form.Item
                                    name="channel-name"
                                    label={
                                        <span className="pr-2">
                                            Channel Name <span className="text-red-600">*</span>
                                        </span>
                                    }
                                    validateStatus={channelNameError ? "error" : ""}
                                    help={channelNameError}
                                    labelCol={{ span: 24 }} 
                                >
                                    <Input
                                        className="py-2"
                                        required
                                        placeholder='Channel Name'
                                        value={channelName}  
                                        onChange={(e) => {
                                            setChannelNameError('');
                                            setChannelName(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="phone-number"
                                    label={
                                        <span className="pr-2">
                                            Phone Number / Bank Account Number <span className="text-red-600">*</span>
                                        </span>
                                    }
                                    validateStatus={accountNumberError ? "error" : ""}
                                    help={accountNumberError}
                                    labelCol={{ span: 24 }} 
                                >
                                    <Input
                                        className="py-2"
                                        required
                                        placeholder='Phone Number / Bank Account Number'
                                        value={accountNumber} 
                                        onChange={(e) => {
                                            setAccountNumberError('');
                                            setAccountNumber(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="remarks"
                                    label='Remarks'
                                    labelCol={{ span: 24 }} 
                                >
                                    <TextArea 
                                        rows={4} 
                                        value={remarks}  
                                        onChange={(e) => {
                                            setRemarks(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                
                            </Form>
                        </>
                    )}
                </Modal>
                <Modal
                    title="Confirm Delete"
                    open={isDeleteModalOpen}
                    onCancel={cancelDelete}
                    onOk={confirmDelete}
                >
                    Are you sure you want to delete this payment channel?
                </Modal>
            </div>
        </div>
    )
}

export default PaymentChannel
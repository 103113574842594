import CryptoJS from 'crypto-js';
import jiliConfig from './jili-config';

const API_URLS = {
    GetGameList: `${jiliConfig.webUrl}/api1/GetGameList?AgentId=${jiliConfig.agid}&Key=`,
    GetGameUrl: `https://uat-wb-api4.jlfafafa3.com/api1/GetGameUrl?AgentId=${jiliConfig.agid}&Key=`,
    LoginWithoutRedirect: `${jiliConfig.webUrl}/api1/singleWallet/LoginWithoutRedirect?AgentId=${jiliConfig.agid}&Key=`,
};

const CATEGORY_NAMES = { 
    "1": "Slot",
    "2": "Poker",
    "3": "Lobby",
    "5": "Fishing",
    "8": "Casino", 
};

const getHash = (agid, agentKey) => {
    const d = new Date();
    const localTime = d.getTime();
    const localOffset = d.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    const offset = -4;
    const date_4 = utc + (3600000 * offset);
    const nd = new Date(date_4);
    let month = nd.getMonth() + 1;
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    const dateStr = (nd.getFullYear()).toString().substring(2,4) + month + nd.getDate();
    const KeyG = CryptoJS.MD5(dateStr + agid + agentKey).toString();
    const str = 'AgentId='  + agid;
    const key = CryptoJS.MD5(str + KeyG).toString();
    const hash = '000000' + key + '000000';
    return hash;
}

const loginToGameHash = (agid, agentKey, Token, GameId, Lang) => {
    const d = new Date();
    const localTime = d.getTime();
    const localOffset = d.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    const offset = -4;
    const date_4 = utc + 3600000 * offset;
    const nd = new Date(date_4);
    let month = nd.getMonth() + 1;
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    const dateStr =
      nd.getFullYear().toString().substring(2, 4) +
      month +
      nd.getDate();
    const KeyG = CryptoJS.MD5(dateStr + agid + agentKey).toString();
    const str =
      "Token=" +
      Token +
      "&GameId=" +
      GameId +
      "&Lang=" +
      Lang +
      "&AgentId=" +
      agid;
    const key = CryptoJS.MD5(str + KeyG).toString();
    const hash = "000000" + key + "000000";
    return hash;
  };

  
  export const loginWithoutRedirect = async (token, gameId, lang, account) => {
    const hash = loginToGameHash(jiliConfig.agid, jiliConfig.agentKey, token, gameId, lang);
    const url = `${API_URLS['LoginWithoutRedirect']}${hash}&GameId=${gameId}&Account=${account}&Token=${token}&Lang=${lang}`;
    const proxyUrl = `https://api.moneywin.bet/jili-proxy.php?url=${encodeURIComponent(url)}`; 
  
    try {
      const response = await fetch(proxyUrl);
      const responseBody = await response.text();
    
      if (response.ok) {
        try {
          const responseJson = JSON.parse(responseBody);
          const gameLink = responseJson.Data;
          return gameLink;

        } catch (error) {
          console.error('Failed to parse JSON:', error);
        }
      } else {
        console.error('Failed to login without redirect:', response.status);
      }
    } catch (error) {
      console.error('Failed to login without redirect:', error);
    }
  };

  export const getJiliGames = async (language, excludeLobby, gameId = null) => {
    const hash = getHash(jiliConfig.agid, jiliConfig.agentKey);
    const response = await fetch(API_URLS['GetGameList'] + hash);
    const { ErrorCode, Message, Data } = await response.json();
    if (ErrorCode === 0) {
     
      if (gameId !== null) {
        const game = Data.find((game) => game.GameId === 1);
        game.provider = "jili";
        
        return game.name["en-US"];
      }
  
      const categorizedGames = {};
      Data.forEach((game) => {
        if (game.GameId !== 210) {
          if (excludeLobby) {
            if (game.GameCategoryId !== 3) {
              const category = CATEGORY_NAMES[game.GameCategoryId];
              if (!categorizedGames[category]) {
                categorizedGames[category] = [];
              }
              game.provider = "jili";
              categorizedGames[category].push(game);
            }
          } else {
            const category = CATEGORY_NAMES[game.GameCategoryId];
            if (!categorizedGames[category]) {
              categorizedGames[category] = [];
            }

            game.provider = "jili";
            categorizedGames[category].push(game);
          }
        }
      });
      
      return categorizedGames;
    } else {
      console.error(`Failed to retrieve game list: ${Message}`);
    }
  };
